<script lang="ts" setup>
import { getColorByStatus, getFileName, getIconByStatus } from './uploaderUtils';
import { VUploaderFile, VUploaderFileProps } from './_types';

const props = defineProps({
  "file": null
});
const emit = defineEmits(["remove"]);

const fileName = computed(() => getFileName(props.file));

const chipProps = computed(() => {
    return {
        'text-color': getColorByStatus(props.file),
        icon: getIconByStatus(props.file),
    };
});

const onRemove = () => {
    emit('remove', props.file);
};
</script>

<template>
    <q-chip v-bind="chipProps" :outline="true" removable @remove="onRemove">
        <span class="ellipsis non-selectable" style="max-width: 200px">
            {{ fileName }}
            <q-tooltip>
                {{ fileName }}
            </q-tooltip>
        </span>
    </q-chip>
</template>
