import { NavigationGuardWithThis, NavigationHookAfter, RouteLocationNormalized, START_LOCATION } from 'vue-router';
import { setPageTitle } from '~/utils/baseUtils';
import { tryAutoLogin } from '~/modules/core/services/auth';
import { goToAccessDeniedPage, isPublicRoute } from '~/plugins/router/utils';
import { useAuthStore } from '~/plugins/auth';
import localeStorage from '~/plugins/i18n/store';
import { AUTH_2FA_ROUTE, AUTH_ROUTE, HOME_ROUTE } from '~/plugins/router/_constants';
import { getPagePermissions } from './permissions';
import routerInstance from '.';
import { getRouteHooks } from './routeHooks';
import { useAppStore } from '~/modules/core/stores/app';
import { fetchConfig } from '~/modules/core/services/config';

export const beforeEach: NavigationGuardWithThis<undefined> = async (to, from, next) => {
    const { setIsRouting } = useAppStore();
    setIsRouting(true);

    const authStore = useAuthStore();

    if (from === START_LOCATION) {
        localeStorage.init();
        await Promise.allSettled([fetchConfig(), tryAutoLogin()]);
    }

    if (!isPublicRoute(to) && !authStore.loggedIn) {
        await authStore.logout();
        return next({ name: AUTH_ROUTE, query: { to: <string>to.name, params: JSON.stringify(to.params) } });
    } else if (authStore.loggedIn && (to.name === AUTH_ROUTE || to.name === AUTH_2FA_ROUTE)) {
        return next({ name: HOME_ROUTE });
    } else if (to.name === AUTH_2FA_ROUTE && (from === START_LOCATION || authStore.token == null)) {
        return next({ name: AUTH_ROUTE });
    }

    if (to.name === AUTH_ROUTE) {
        authStore.setToken(null);
    }

    if (!(await canAccessPage(to))) {
        // @plachtova: next can only push, but we want to replace the page
        next(false);
        return await goToAccessDeniedPage(routerInstance);
    }

    const hooks = getRouteHooks();
    for (const beforeHook of hooks.beforeHooks) {
        const hookRes = await beforeHook.bind(undefined, to, from, next)();
        if (hookRes) return;
    }

    next();
};

export const afterEach: NavigationHookAfter = async (to, from, failure) => {
    setPageTitle(to);

    const hooks = getRouteHooks();
    for (const afterHook of hooks.afterHooks) {
        await afterHook(to, from, failure);
    }

    const { setIsRouting } = useAppStore();
    setIsRouting(false);
};

const canAccessPage = async (to: RouteLocationNormalized): Promise<boolean> => {
    const pagePermissions = getPagePermissions();
    const canAccessPageFn = pagePermissions[to.name?.toString() || ''];
    return canAccessPageFn == null || (await canAccessPageFn(to));
};
