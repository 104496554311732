import { debug } from '~/utils/debugUtilts';
import { NotificationAction, NotificationManager } from './_types';

export const createNotificationManager = (): NotificationManager => {
    const { getAction, setAction } = createNotificationManagerStore();

    const register: NotificationManager['register'] = (action) => {
        const existingAction = getAction(action.key);
        if (existingAction != null) {
            console.warn(
                `[Plugin NotificationManager] - action with key '${action.key}' is already registered. This action registration will be skipped.`
            );
            return;
        }

        setAction(action);
        debug(`[Plugin NotificationManager] - action with key '${action.key}' was registered`);
    };

    const registerBatch: NotificationManager['registerBatch'] = (actions) => {
        actions.forEach((x) => register(x));
    };

    const execute: NotificationManager['execute'] = async (info) => {
        if (info.RouteName == null) return false;

        const action = getAction(info.RouteName);
        if (action == null) return false;

        return await action.actionFn(info.RouteId);
    };

    const canExecute: NotificationManager['canExecute'] = (info) => {
        if (info.RouteName == null) return false;

        const action = getAction(info.RouteName);
        if (action == null) return false;

        return action.canExecuteFn ? action.canExecuteFn(info.RouteId) : true;
    };

    return {
        register,
        registerBatch,
        execute,
        canExecute,
    };
};

const createNotificationManagerStore = () => {
    const registeredActions: NotificationAction[] = [];

    const getAction = (key: string): NotificationAction | null => {
        return registeredActions.find((x) => x.key === key) || null;
    };

    const setAction = (action: NotificationAction) => {
        registeredActions.push(action);
    };

    return {
        registeredActions,
        getAction,
        setAction,
    };
};
