{
    "app": {
        "title": "ISKL",
        "title-long": "Informační Systém Krizové Logistiky",
        "subjects": "Subjekty",
        "projects": "Projekty",
        "modules": "Moduly",
        "labels": "Štítky",
        "service": "SaaS"
    },
    "api": {
        "errors": {
            "title": "Nastala chyba"
        }
    },
    "header": {
        "changeLanguage": "Změna jazyka",
        "notifications": "Notifikace",
        "accountSettings": "Nastavení účtu",
        "logout": "Odhlásit se"
    },
    "footer": {
        "copyright": "A-WebSys Copyright ©2023"
    },
    "modules": {
        "dispatching": "Dispečink",
        "human-resources": "Správa lidských zdrojů",
        "inventory": "Správa skladových zásob",
        "humanitarian-care": "Správa humanitární pomoci",
        "reporting": "Hlášení",
        "statistics": "Statistiky",
        "planning": "Kapacitní plánování"
    },
    "systemFeatures": {
        "crisiscommunication": "Krizová komunikace",
        "crisisdocuments": "Krizové dokumenty"
    },
    "navigation": {
        "backToOverview": "Zpět na přehled",
        "groups": {
            "modules": "Moduly",
            "labels": "Štítky"
        }
    },
    "generic": {
        "from": "z",
        "boolean": {
            "true": "Ano",
            "false": "Ne"
        },
        "edit": "Upravit",
        "delete": "Smazat",
        "restore": "Obnovit",
        "download": "Stáhnout",
        "add": "Přidat"
    },
    "modals": {
        "notes": "Poznámky",
        "checklists": "Seznam úkolů"
    },
    "pages": {
        "all": "Stránka Nenalezena",
        "access-denied": "Přístup odepřen",
        "index": "Domovská stránka",
        "auth-login": "Přihlášení",
        "auth-login2fa": "Dvoufázové přihlášení",
        "auth-forgot-password": "Zapomenutí hesla",
        "auth-change-password-token-email": "Změna hesla",
        "auth-user-confirmation-confirmation-token": "Potvrzení změny emailu",
        "auth-set-password-token-email": "Nastavení hesla",
        "teams-id": "Team detail",
        "search": "Výsledky vyhledávání",
        "profile": "Nastavení účtu",
        "settings": "Nastavení",
        "settings-subjects": "Správa subjektů",
        "settings-projects": "Správa projektů",
        "settings-users": "Správa uživatelů",
        "settings-users-id": "Detail uživatele",
        "settings-users-id-section": "Detail uživatele",
        "settings-users-new": "Nový uživatel",
        "settings-documents": "Správa dokumentů",
        "settings-enums": "Správa číselníků",
        "settings-labels": "Správa štítků",
        "settings-app-settings": "Nastavení aplikace",
        "settings-subjects-new": "Založení subjektu",
        "settings-projects-new": "Založení projektu",
        "settings-subjects-id": "Detail subjektu",
        "settings-subjects-id-new-subject": "Založení podsubjektu",
        "settings-subjects-id-new-project": "Založení projektu",
        "settings-subjects-id-settings": "Nastavení subjektu",
        "settings-subjects-id-settings-section": "Nastavení subjektu",
        "projects": "Moje projekty",
        "projects-new": "Založení projektu",
        "documents": "Krizové dokumenty",
        "communication": "Krizová komunikace",
        "communication-send": "Krizová komunikace - zpráva",
        "notifications": "Přehled notifikací"
    },
    "components": {
        "appForm": {
            "actions": {
                "create": "Vytvořit",
                "submit": "Uložit",
                "cancel": "Zahodit"
            }
        },
        "uploader": {
            "dndText": "Přetáhněte soubory zde",
            "addFile": "Přidat soubor",
            "maxFileSize": "Max. velikost souboru"
        },
        "select": {
            "no-result": "Žádné data k dispozici"
        },
        "dialog": {
            "confirm": "OK",
            "cancel": "Zrušit"
        },
        "datePickerModal": {
            "reset": "reset"
        },
        "labelsInput": {
            "noData": "Žádné výsledky"
        },
        "filterBar": {
            "hints": {
                "string": {
                    "Like": "obsahuje"
                },
                "boolean": {
                    "Eq": "je",
                    "Neq": "není"
                },
                "enum": {
                    "Eq": "je",
                    "Neq": "není"
                },
                "date": {
                    "Eq": "je",
                    "Neq": "není",
                    "Lte": "do",
                    "Gte": "od"
                },
                "number": {
                    "Eq": "rovná se",
                    "Neq": "nerovná se",
                    "Lte": "menší nebo rovno",
                    "Gte": "větší nebo rovno",
                    "Lt": "menší",
                    "Gt": "větší"
                }
            },
            "options": {
                "boolean": {
                    "true": "Ano",
                    "false": "Ne"
                }
            },
            "noData": "Žádné výsledky"
        },
        "userRoleAccesslevelPicker": {
            "roles": "Role",
            "markers": {
                "first": "Nízká",
                "last": "Vysoká"
            }
        },
        "passwordRequirements": {
            "title": "Heslo musí mít:",
            "labels": {
                "MinLength": "Minimální délka: {0} znaků",
                "MixedCase": "Alespoň jedno velké a jedno malé písmeno",
                "Numbers": "Alespoň jedno číslo",
                "Symbols": "Alespoň jeden znak"
            }
        }
    },
    "auth": {
        "form": {
            "login": {
                "inputs": {
                    "login": "Uživatelské jméno",
                    "password": "Heslo"
                },
                "submit": "Přihlásit se",
                "errors": {
                    "invalidCredentials": "Zadané přihlašovací údaje nejsou správné."
                }
            },
            "sms2fa": {
                "code": "∗∗∗∗∗",
                "sendNewCode": "Zaslat nový kód",
                "submit": "Ověřit"
            },
            "changePasswordStart": {
                "inputs": {
                    "Email": "Email"
                },
                "submit": "Poslat"
            },
            "changePasswordFinish": {
                "inputs": {
                    "Password": "Heslo",
                    "PasswordConfirm": "Heslo znovu",
                    "Login": "Přihlašovací jméno"
                },
                "submit": "Změnit heslo"
            }
        },
        "prompts": {
            "tokenInvalidPrompt": "Odkaz pro změnu hesla už vypršel",
            "enterSmsCodePrompt": "zadejte prosím potvrzovací kód, který jsme vám poslali v sms zprávě",
            "enterLoginPrompt": "zadejte prosím přihlašovací údaje",
            "forgotPasswordStartPrompt": "Zadejte email k účtu, pro který chcete resetovat heslo. Na tento email vám přijde odkaz k resetu hesla.",
            "changePasswordFinishPrompt": "pro dokončení změny hesla zadejte nové heslo",
            "setPasswordFinishPrompt": "pro dokončení registrace zadejte nové heslo"
        },
        "actions": {
            "sendForgottenPassword": "Zaslat zapomenuté heslo?",
            "backToLogin": "zpět na přihlášení",
            "backToHomePage": "zpět na domovskou stránku"
        },
        "notify": {
            "loggedIn": "Přihlášeno",
            "loggedOut": "Odhlášeno",
            "smsHasBeenSent": "Nová sms byla odeslána.",
            "forgotPasswordEmailSent": "Email s odkazem pro reset hesla byl úspěšně odeslán"
        },
        "confirmEmailChange": {
            "success": "Změna emailu byla potvrzena."
        }
    },
    "notifications": {
        "showAll": "Zobrazit všechny notifikace",
        "sections": {
            "read": {
                "title": "Přečtené notifikace",
                "noData": "Žádné přečtené notifikace"
            },
            "unread": {
                "title": "Nepřečtené notifikace",
                "noData": "Žádné nepřečtené notifikace"
            }
        },
        "actions": {
            "InventoryProduct": {
                "actionTitle": "Zobrazit produkt"
            },
            "SubjectMessage": {
                "actionTitle": "Zobrazit krizovou komunikaci"
            },
            "DispatchingProject": {
                "actionTitle": "Zobrazit projekt"
            },
            "HumanitarianCareProject": {
                "actionTitle": "Zobrazit projekt"
            },
            "HumanResourcesProject": {
                "actionTitle": "Zobrazit projekt"
            },
            "InventoryProject": {
                "actionTitle": "Zobrazit projekt"
            },
            "PlanningProject": {
                "actionTitle": "Zobrazit projekt"
            },
            "ReportingProject": {
                "actionTitle": "Zobrazit projekt"
            },
            "StatisticsProject": {
                "actionTitle": "Zobrazit projekt"
            },
            "DispatchingIssue": {
                "actionTitle": "Zobrazit požadavek"
            }
        }
    },
    "home": {
        "homepage": "Domovská stránka",
        "welcomeBack": "Vítejte zpět",
        "widgets": {
            "favoriteProjects": {
                "title": "Prioritizované projekty",
                "noData": {
                    "noFavorites": "Žádné prioritizované projekty",
                    "wantToAdd":"Přidejte si vaše projekty do prioritizovaných",
                    "myProjects": "moje projekty"
                }
            },
            "weather": {
                "title": "Počasí",
                "todayIs": "Dnes je",
                "location": "Lokace",
                "noData": "Data aktuálně nejsou k dispozici",
                "daily": {
                    "tab": "Dlouhodobá"
                },
                "hourly": {
                    "tab": "Krátkodobá",
                    "temperature": {
                        "title": "Teplota"
                    },
                    "precipitation": {
                        "title": "Srážky"
                    }
                }
            }
        }
    },
    "search": {
        "groups": {
            "subjects": "Subjekty",
            "projects": "Projekty",
            "labels": "Štítky"
        },
        "searchBar": {
            "whatAreYouSearchingFor": "Co hledáte?",
            "noResults": "Žádné výsledky"
        },
        "searchResults": {
            "resultsFor": "Výsledky pro",
            "noResultsFound": "Nebyly nalezeny žádné výsledky",
            "groups": {
                "subjects": "Nebyly nalezeny žádné subjekty",
                "projects": "Nebyly nalezeny žádné projekty",
                "labels": "Nebyly nalezeny žádné štítky"
            }
        }
    },
    "profile": {
        "title": "Nastavení účtu",
        "detail": {
            "info": {
                "title": "Profil"
            },
            "security": {
                "title": "Zabezpečení",
                "changePassword": "Změna hesla",
                "changeEmail": "Změna emailu",
                "changePhone": "Změna telefonního čísla"

            }
        },
        "form": {
            "submit": "Uložit nastavení",
            "inputs": {
                "FirstName": "Jméno",
                "LastName": "Příjmení",
                "Function": "Funkce",
                "Organization": "Organizace",
                "TitleBefore": "Titul před jménem",
                "TitleAfter": "Titul za jménem",
                "Phone": "Telefon",
                "Avatar": "Avatar"
            }
        },
        "form-pass": {
            "title": "Změna hesla",
            "submit": "Nastavit",
            "inputs": {
                "CurrentPassword": "Současné heslo",
                "NewPassword": "Nové heslo",
                "PasswordConfirm": "Nové heslo znovu"
            }
        },
        "form-phone": {
            "title": "Změna telefonního čísla",
            "submit": "Nastavit",
            "inputs": {
                "CurrentPassword": "Heslo",
                "NewPhone": "Nové telefonní číslo"
            },
            "enterCodePrompt": "zadejte prosím potvrzovací kód, který jsme vám poslali v sms zprávě"
        },
        "form-confirm": {
            "submit": "Ověřit",
            "cancel": "Zpět",
            "inputs": {
                "Code": "Ověřovací kód"
            }
        },
        "form-email": {
            "title": "Změna emailové adresy",
            "submit": "Nastavit",
            "inputs": {
                "CurrentPassword": "Heslo",
                "NewEmail": "Nový email"
            },
            "enterCodePrompt": "zadejte prosím potvrzovací kód, který jsme vám poslali na nový email"
        },
        "notify": {
            "submit": "Změna byla úspěšně uložena"
        }
    },
    "settings": {
        "createSubject": "Založit subjekt",
        "createProject": "Založit projekt",
        "sections": {
            "subjects": {
                "title": "Subjekty",
                "action": "Spravovat subjekty"
            },
            "projects": {
                "title": "Projekty",
                "action": "Spravovat projekty"
            },
            "users": {
                "title": "Uživatelé v systému",
                "action": "Spravovat uživatele"
            },
            "documents": {
                "title": "Dokumenty",
                "action": "Dokumenty v aplikaci"
            },
            "enums": {
                "title": "Číselníky",
                "action": "Nastavení číselníků"
            },
            "labels": {
                "title": "Štítky",
                "action": "Správa štítků"
            },
            "appSettings": {
                "title": "Nastavení aplikace",
                "action": "Nastavit aplikaci"
            }
        }
    },
    "subjects": {
        "new": {
            "title": "Nový subjekt",
            "subjectData": "Údaje o subjektu"
        },
        "edit": {
            "title": "Upravit subjekt"
        },
        "overview": {
            "createSubject": "Založit subjekt",
            "table": {
                "columns": {
                    "name": "Název",
                    "parent": "Nadřazený subjekt"
                },
                "actions": {
                    "detail": "zobrazit"
                }
            }
        },
        "detail": {
            "subtitle": "Organizační subjekt",
            "manageSubject": "Nastavení subjektu",
            "widgets": {
                "children": {
                    "newChildSubject": "Nový podsubjekt",
                    "columns": {
                        "name": "Podsubjekty"
                    },
                    "noData": {
                        "noSubjectChildren": "Žádné podsubjekty?",
                        "wantToCreate": "Chcete založit podsubjekt organizace?",
                        "createSubjectChild": "založit podsubjekt"
                    }
                },
                "contacts": {
                    "title": "Kontakty",
                    "manageContacts": "spravovat kontakty",
                    "columns": {
                        "name": "Jméno kontaktu",
                        "email": "E-mail",
                        "phone": "Telefon"
                    },
                    "noData": {
                        "noContacts": "Žádné důležité kontakty?",
                        "wantToImport": "Naimportujte své kontakty do systému",
                        "importContacts": "importovat kontakty"
                    },
                    "prompts": {
                        "delete": {
                            "title": "Odstranit kontakt",
                            "message": "Opravdu chcete odstranit tento kontakt?",
                            "confirm": "Odstranit",
                            "cancel": "Zrušit"
                        }
                    }
                },
                "projects": {
                    "title": "Projekty",
                    "columns": {
                        "name": "Název",
                        "module": "Modul",
                        "favorite": "Prioritizovaný"
                    },
                    "actions": {
                        "create": "Nový projekt",
                        "detail": "zobrazit"
                    },
                    "noData": {
                        "noProjects": "Ještě nebylo nic vloženo?",
                        "howItHappened": "Vůbec si nedokážeme vysvětlit, jak se to mohlo stát!",
                        "createProject": "založit projekt"
                    }
                },
                "users": {
                    "title": "Uživatelé",
                    "manageUsers": "spravovat uživatele",
                    "columns": {
                        "name": "Jméno uživatele",
                        "organization": "Organizace",
                        "roles": "Role"
                    },
                    "noData": {
                        "noUsers": "Žádní uživatelé?",
                        "wantToAdd": "Přidejte uživatele do vašeho subjektu",
                        "addUsers": "přidat uživatele"
                    },
                    "prompts": {
                        "delete": {
                            "title": "Odstranit uživatele",
                            "message": "Opravdu chcete odstranit tohoto uživatele ze subjektu?",
                            "confirm": "Odstranit",
                            "cancel": "Zrušit"
                        }
                    }
                }
            }
        },
        "form": {
            "subject": {
                "inputs": {
                    "ParentSubject": "Nadřazený subjekt",
                    "Name": "Název",
                    "NameShort": "Krátký název",
                    "Description": "Popis",
                    "Contact": {
                        "Street": "Ulice, č.p",
                        "Town": "Město",
                        "Zip": "PSČ",
                        "Country": "Stát"
                    },
                    "LogoId": "Logo"
                },
                "submit": {
                    "new": "Založit subjekt",
                    "edit": "Upravit subjekt"
                },
                "cancel": "Zahodit"
            },
            "contacts": {
                "title": {
                    "add": "Přidat kontakty",
                    "edit": "Upravit kontakt"
                },
                "tabs": {
                    "add": "Přidat kontakty",
                    "import": "Importovat kontakty"
                },
                "inputs": {
                    "Name": "Jméno",
                    "Function": "Funkce",
                    "Email": "Email",
                    "Phone": "Telefon",
                    "File": "Importovat soubor"
                },
                "submit": {
                    "new": "Přidat kontakty",
                    "import": "Importovat kontakty",
                    "edit": "Upravit kontakt"
                },
                "sampleImport": "stáhnout vzorový import",
                "cancel": "Zahodit"
            },
            "users": {
                "title": {
                    "add": "Přidat uživatele",
                    "edit": "Upravit uživatele"
                },
                "sections": {
                    "subject": "Subjekt",
                    "projects": "Projekty"
                },
                "inputs": {
                    "users": "E-maily uživatelů"
                },
                "submit": {
                    "add": "Přidat uživatele",
                    "edit": "Upravit uživatele"
                }
            }
        },
        "settings": {
            "title": "Nastavení",
            "subtitle": "Organizační subjekt",
            "goBack": "Zpět na přehled",
            "sections": {
                "detail": {
                    "title": "Obecné informace",
                    "action": "Editovat subjekt"
                },
                "users": {
                    "title": "Uživatelé v subjektu",
                    "action": "Spravovat uživatele"
                },
                "contacts": {
                    "title": "Kontakty pro subjekt",
                    "action": "Spravovat kontatky"
                },
                "invitations": {
                    "title": "Pozvánky k projektům",
                    "action": "Spravovat pozvánky"
                }
            },
            "notify": {
                "detail": {
                    "success": "Formulář byl úspěšně uložen"
                }
            },
            "detail": {
                "title": "Údaje o subjektu"
            },
            "contacts": {
                "title": "Kontakty",
                "addContacts": "Přidat kontakty",
                "columns": {
                    "name": "Jméno",
                    "function": "Funkce",
                    "email": "E-mail",
                    "phone": "Telefon"
                },
                "prompts": {
                    "delete": {
                        "title": "Odstranit kontakt",
                        "message": "Opravdu chcete odstranit tento kontakt?",
                        "confirm": "Odstranit",
                        "cancel": "Zrušit"
                    }
                }
            },
            "users": {
                "title": "Uživatelé",
                "addUsers": "Přidat uživatele",
                "columns": {
                    "firstName": "Jméno",
                    "lastName": "Příjmení",
                    "function": "Funkce",
                    "organization": "Organizace",
                    "roles": "Role"
                },
                "prompts": {
                    "delete": {
                        "title": "Odstranit uživatele",
                        "message": "Opravdu chcete odstranit tohoto uživatele ze subjektu?",
                        "confirm": "Odstranit",
                        "cancel": "Zrušit"
                    }
                }
            },
            "invitations": {
                "title": "Pozvánky od projektů",
                "columns": {
                    "project": "Projekt",
                    "expiration": "Expirace",
                    "bindType": "Stav"
                },
                "bindType": {
                    "Owner": "Vlastník",
                    "Participant": "Účastník",
                    "Invited": "Pozvaný",
                    "Declined": "Zamítnutý",
                    "Inactive": "Neaktivní",
                    "Remote": "Vzdálený přístup"
                },
                "prompts": {
                    "decline": {
                        "title": "Odmítnout pozvánku",
                        "message": "Opravdu chcete odmítnout pozvánku od tohoto projektu?",
                        "confirm": "Odmítnout",
                        "cancel": "Zrušit"
                    },
                    "accept": {
                        "title": "Přijmout pozvánku",
                        "message": "Opravdu chcete přijmout pozvánku od tohoto projektu?",
                        "confirm": "Přijmout",
                        "cancel": "Zrušit"
                    }
                }
            }
        }
    },
    "users": {
        "model": {
            "firstName": "Jméno",
            "lastName": "Příjmení",
            "email": "Email",
            "status": "Status",
            "function": "Funkce",
            "organization": "Organizace",
            "login": "Přihlašovací jméno",
            "titleBefore": "Titul před jménem",
            "titleAfter": "Titul za jménem",
            "phone": "Telefon",
            "isAdmin": "Admin",
            "projects": {
                "id": "Id",
                "name": "Projekt",
                "module": {
                    "name": "Modul"
                }
            },
            "subjects": {
                "id": "Id",
                "name": "Subjekt"
            },
            "roles": {
                "id": "Id",
                "name": "Role"
            }
        },
        "enum": {
            "status": {
                "Active": "Aktivní",
                "Inactive": "Neaktivní",
                "Invited": "Pozvaný"
            }
        },
        "overview": {
            "createUser": "Založit uživatele",
            "searchPlaceholder": "Hledání...",
            "table": {
                "columns": {
                    "name": "Název",
                    "parent": "Nadřazený subjekt"
                },
                "actions": {
                    "detail": "zobrazit"
                }
            }
        },
        "settings": {
            "title": "Uživatelé",
            "addUser": "Přidat uživatele"
        },
        "new": {
            "title": "Nový uživatel"
        },
        "detail": {
            "title": "Detail uživatele",
            "editUser": "Upravit informace",
            "resetPassword": "Resetovat heslo",
            "activateUser": "Aktivovat uživatele",
            "deactivateUser": "Deaktivovat uživatele",
            "navigation": {
                "info": "Základní informace",
                "subjects": "Subjekty",
                "projects": "Projekty",
                "roles": "Správa rolí"
            },
            "notify": {
                "resetPasswordSuccess": "Heslo bylo úspěšně resetováno",
                "userActivatedSuccess": "Uživatel byl aktivován",
                "userDeactivatedSuccess": "Uživatel byl deaktivován"
            },
            "prompt": {
                "activate": "Opravdu chcete aktivovat uživatele?",
                "deactivate": "Opravdu chcete deaktivovat uživatele?"
            }
        },
        "form": {
            "globalRoles": {
                "title": "Globální role"
            }
        }
    },
    "projects": {
        "new": {
            "title": "Nový projekt",
            "projectData": "Údaje o projektu"
        },
        "edit": {
            "title": "Upravit projekt"
        },
        "overview": {
            "createProject": "Založit projekt",
            "table": {
                "columns": {
                    "name": "Název",
                    "subject": "Subjekt",
                    "module": "Modul",
                    "labels": "Štítky",
                    "label": "Štítek",
                    "favorite": "Prioritizovaný"
                },
                "actions": {
                    "detail": "zobrazit",
                    "delete": "odstranit"
                },
                "prompts": {
                    "delete": {
                        "title": "Odstranit projekt",
                        "message": "Opravdu chcete odstranit tento projekt?",
                        "cancel": "Zrušit",
                        "confirm": "Odstranit"
                    }
                }
            }
        },
        "detail": {
            "manageProject": "Nastavení projektu"
        },
        "form": {
            "detail": {
                "inputs": {
                    "Name": "Název",
                    "SubjectId": "Vlastník projektu",
                    "ModuleId": "Typ projektu",
                    "Description": "Popis",
                    "Labels": "Štítky",
                    "TemplateId": "Šablona"
                },
                "submit": {
                    "new": "Založit projekt",
                    "edit": "Upravit projekt"
                }
            },
            "users": {
                "title": {
                    "add": "Přidat uživatele",
                    "edit": "Upravit uživatele"
                },
                "inputs": {
                    "users": "Uživatelé",
                    "subject": "Subjekt"
                },
                "submit": {
                    "add": "Přidat uživatele",
                    "edit": "Upravit uživatele"
                },
                "noUnassignedUsers": "Žádní noví nepřiřazení uživatelé už nejsou k dispozici"
            },
            "invitations": {
                "title": {
                    "create": "Vytvořit pozvánku"
                },
                "inputs": {
                    "subjects": "Subjekty",
                    "expiresAt": "Expirace",
                    "accessLevel": "Úroveň přístupnosti"
                },
                "submit": {
                    "create": "Vytvořit pozvánku"
                }
            }
        },
        "settings": {
            "title": "Nastavení",
            "goBack": "Zpět na přehled",
            "sections": {
                "detail": {
                    "title": "Obecné informace",
                    "action": "Editovat projekt"
                },
                "users": {
                    "title": "Uživatelé v projektu",
                    "action": "Spravovat uživatele"
                },
                "invitations": {
                    "title": "Pozvánky k subjektům",
                    "action": "Spravovat pozvánky"
                }
            },
            "notify": {
                "detail": {
                    "success": "Formulář byl úspěšně uložen"
                }
            },
            "detail": {
                "title": "Údaje o projektu"
            },
            "users": {
                "title": "Uživatelé",
                "addUsers": "Přidat uživatele",
                "columns": {
                    "firstName": "Jméno",
                    "lastName": "Příjmení",
                    "function": "Funkce",
                    "organization": "Organizace",
                    "roles": "Role"
                },
                "prompts": {
                    "delete": {
                        "title": "Odstranit uživatele",
                        "message": "Opravdu chcete odstranit tohoto uživatele z projektu?",
                        "confirm": "Odstranit",
                        "cancel": "Zrušit"
                    }
                }
            },
            "invitations": {
                "title": "Pozvánky k subjektům",
                "createInvitation": "Vytvořit pozvánku",
                "columns": {
                    "subject": "Subjekt",
                    "expiration": "Expirace",
                    "bindType": "Stav"
                },
                "bindType": {
                    "Owner": "Vlastník",
                    "Participant": "Účastník",
                    "Invited": "Pozvaný",
                    "Declined": "Zamítnutý",
                    "Inactive": "Neaktivní",
                    "Remote": "Vzdálený přístup"
                },
                "prompts": {
                    "delete": {
                        "title": "Odstranit pozvánku",
                        "message": "Opravdu chcete odstranit pozvánku k tomuto subjektu?",
                        "confirm": "Odstranit",
                        "cancel": "Zrušit"
                    }
                }
            }
        }
    },
    "documents": {
        "overview": {
            "manageDocuments": "Spravovat dokumenty",
            "uploadDocument": "Nahrát dokument",
            "added": "vloženo",
            "noData": "Žádné dokumenty zatím nebyly nahrány",
            "prompts": {
                "delete": {
                    "title": "Odstranit dokument",
                    "message": "Opravdu chcete odstranit tento dokument?",
                    "confirm": "Odstranit",
                    "cancel": "Zrušit"
                }
            }
        },
        "form": {
            "title": "Nový dokument",
            "inputs": {
                "DisplayName": "Název",
                "File": "Soubor"
            },
            "submit": "Nahrát dokument",
            "cancel": "Zahodit"
        }
    },
    "communication": {
        "info": {
            "sender": "Odesílatel"
        },
        "form": {
            "inputs": {
                "Subjects": "Subjekty",
                "Priority": "Typ zprávy",
                "Title": "Předmět zprávy",
                "Body": "Text zprávy",
                "Type": "Druh rozeslání"
            },
            "submit": "Rozeslat zprávu",
            "cancel": "Zahodit"
        },
        "send": {
            "title": "Krizová komunikace - Odeslání zprávy",
            "newMessage": "Nová zpráva",
            "type": {
                "Info": "Informace",
                "Success": "Úspěch",
                "Warning": "Varování",
                "Danger": "Nebezpečí"
            },
            "source": {
                "Sms": "SMS",
                "Email": "E-mail"
            }
        },
        "overview": {
            "title": "Krizová komunikace",
            "createMessage": "Vytvořit zprávu",
            "noData": "Žádné zprávy zatím nebyly poslány"
        }
    },
    "notes": {
        "options": {
            "yes": "Ano",
            "no": "Ne"
        },
        "textNotes": {
            "newNote": "Nová poznámka",
            "listOfNotes": "Moje poznámky",
            "editNote": "Upravit poznámku",
            "createNewNote": "Napsat novou poznámku",
            "deleteNote": "Smazat poznámku",
            "noteTitle": "Název",
            "noteDescription": "Text",
            "cancel": "Zahodit",
            "save": "Uložit poznámku",
            "edit": "Upravit",
            "back": "Zpět",
            "insertFiles": "Vložte soubory",
            "confirm_title": "Smazání poznámky",
            "confirm_text": "Opravdu chcete smazat tuto poznámku?"
        },
        "checklists": {
            "newNote": "Nový úkol",
            "listOfNotes": "Moje úkoly",
            "editNote": "Upravit úkol",
            "createNewNote": "Vytvořit nový úkol",
            "deleteNote": "Smazat úkol",
            "noteTitle": "Název",
            "noteDescription": "Text",
            "cancel": "Zahodit",
            "save": "Uložit úkol",
            "edit": "Upravit",
            "back": "Zpět",
            "resolved": "Vyřešeno",
            "resolve": "Vyřešit",
            "confirm_title": "Smazání úkolu",
            "confirm_text": "Opravdu chcete smazat tento úkol?"
        }
    },
    "labels": {
        "model": {
            "name": "Jméno štítku",
            "isActive": "Aktivní",
            "projectsCount": "Přiřazené projekty"
        },
        "overview": {
            "newLabel": "Nový štítek",
            "searchPlaceholder": "Hledání...",
            "prompts": {
                "delete": {
                    "title": "Odstranit štítek",
                    "message": "Opravdu chcete odstranit tento štítek?",
                    "confirm": "Odstranit",
                    "cancel": "Zrušit"
                }
            }
        },
        "form": {
            "new": {
                "title": "Nový štítek",
                "submit": {
                    "create": "Vytvořit štítek"
                }
            }
        }
    },
    "errors": {
        "title": "Nastala chyba",
        "goHomePage": "Domovská stránka",
        "403": {
            "title": "Ouha.",
            "text": "Je nám to líto. K této stránce nemáte přístup."
        },
        "404": {
            "title": "Ouha.",
            "text": "Je nám to líto. Stále pro Vás nemáme vše připraveno. Stránku, kterou hledáte, zřejmě hledáme i my. Zkuste to prosím později."
        }
    }
}
