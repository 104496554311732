{
    "pages": {
        "dispatching": "Dispatching",
        "dispatching-new": "Dispatching - New Project",
        "dispatching-id": "Dispatching - Issues",
        "dispatching-id-new": "Dispatching - New Issue",
        "dispatching-id-settings": "Dispatching - Settings",
        "dispatching-id-settings-section": "Dispatching - Settings",
        "dispatching-issues-id": "Dispatching - Issue Detail"
    },
    "dispatching": {
        "overview": {
            "manageProject": "Project Settings",
            "manageData": "Data Settings",
            "table": {
                "tab": "Overview",
                "newIssue": "New Issue",
                "columns": {
                    "name": "Name",
                    "id": "ID",
                    "state": "State",
                    "priority": "Priority",
                    "creator": "Author",
                    "assignee": "Assignee",
                    "createdAt": "Created",
                    "finishAt": "Finish at",
                    "project": "Project"
                },
                "actions": {
                    "detail": "detail"
                }
            },
            "map": {
                "tab": "Map",
                "columns": {
                    "name": "Name",
                    "ticket": "Ticket",
                    "assignee": "Assignee",
                    "createdAt": "Created"
                },
                "planRoute": {
                    "start": "Plan Route",
                    "stop": "End Plan Route",
                    "markers": {
                        "start": "Start",
                        "end": "End",
                        "waypoint": "Waypoint %{idx}"
                    },
                    "mapPosition": "Position in map",
                    "chooseMapPosition": "Choose position in map"
                },
                "traffic": {
                    "showTraffic": "Show traffic",
                    "showDiversions": "Diversions",
                    "showTrams": "Trams",
                    "showTrolleybuses": "Trolley Buses",
                    "showBuses": "Buses",
                    "showBusesRegional": "Regional Buses",
                    "showTrains": "Trains",
                    "delay": "Delay",
                    "noDelay": "on time"
                }
            }
        },
        "new": {
            "title": "New Issue",
            "issueData": "Issue Information"
        },
        "detail": {
            "goToOverview": "Back to Overview",
            "issue": {
                "issue": "Issue",
                "assignedIssues": "Assigned Issues",
                "titles": {
                    "Name": "Name",
                    "State": "State",
                    "Priority": "Priority",
                    "Creator": "Author",
                    "CreatedAt": "Created",
                    "BeginAt": "Begin at",
                    "FinishAt": "Finish at",
                    "Assignee": "Assignee",
                    "Body": "Body",
                    "Map": "Map",
                    "ShowOnMap": "Show on map",
                    "Attachments": "Attachments"
                },
                "menu": {
                    "edit": "Edit",
                    "changeState": "Change State",
                    "delete": "Delete"
                },
                "prompts": {
                    "delete": {
                        "title": "Delete Issue",
                        "message": "Are you really sure you want to delete this issue?",
                        "confirm": "Delete",
                        "cancel": "Cancel"
                    }
                }
            },
            "comment": {
                "comments": "Comments",
                "addComment": "Add Comment",
                "type": {
                    "comment": "Comment",
                    "response": "Response"
                },
                "actions": {
                    "response": "Response"
                },
                "menu": {
                    "edit": "Edit",
                    "delete": "Delete"
                },
                "prompts": {
                    "delete": {
                        "title": "Delete Comment",
                        "message": "Are you really sure you want to delete this comment?",
                        "confirm": "Delete",
                        "cancel": "Cancel"
                    }
                }
            }
        },
        "form": {
            "issue": {
                "inputs": {
                    "Name": "Name",
                    "StateId": "State",
                    "PriorityId": "Priority",
                    "BeginAt": "Begin at",
                    "FinishAt": "Finish at",
                    "AssigneeId": "Assignee",
                    "Body": "Body",
                    "Attachments": "Attachments",
                    "Position": "Choose location"
                },
                "submit": {
                    "new": "Create Issue",
                    "edit": "Edit Issue",
                    "response": "Response"
                },
                "cancel": "Cancel"
            },
            "comment": {
                "response": "Response",
                "inputs": {
                    "Body": "Comment",
                    "Attachments": "Attachments"
                },
                "submit": {
                    "new": "Create Comment",
                    "edit": "Edit Comment",
                    "response": "Response"
                },
                "cancel": "Cancel"
            },
            "enum": {
                "title": "Data Settings",
                "tabs": {
                    "priority": "Priority",
                    "state": "State"
                },
                "inputs": {
                    "Name": "Name"
                },
                "submit": {
                    "save": "Save"
                },
                "cancel": "Cancel"
            }
        },
        "settings": {
            "sections": {
                "enums": {
                    "title": "Enums Settings",
                    "action": "Manage enums"
                }
            },
            "notify": {
                "enums": {
                    "success": "Form was successfully saved"
                }
            }
        },
        "widget": {
            "assignedTable": {
                "title": "Assigned Issues",
                "noData": {
                    "noIssues": "No assigned issues",
                    "wantToAdd": "Check if there is anything that needs to be resolved",
                    "dispatchingProjects": "dispatching projects"
                }
            },
            "assignedMap": {
                "title": "Mapa of Assigned Issues"
            }
        }
    }
}