<script setup lang="ts">
import { useQuasar } from 'quasar';
import { useAppStore } from '~/modules/core/stores/app';

const $q = useQuasar();
const route = useRoute();
const appStore = useAppStore();

const { setModalTextNoteOpen } = useTextNotes();
const { totalUncheckedCount, setModalChecklistOpen } = useChecklists();

const canShowSettingsSubitems = computed(() => {
    return route.path.startsWith('/settings');
});
</script>

<template>
    <q-drawer v-model="appStore.isLeftNavigationOpen" :width="300" side="left" class="shadow-5" :breakpoint="1038">
        <div class="column no-wrap justify-between" style="min-height: 100%">
            <q-list class="secondary col-auto q-mt-lg">
                <app-navigation-item
                    v-if="$q.screen.lt.md"
                    icon="las la-sticky-note"
                    :title="$t('modals.notes')"
                    clickable
                    @click="setModalTextNoteOpen(true)"
                />
                <app-navigation-item
                    v-if="$q.screen.lt.md"
                    icon="las la-tasks"
                    :title="$t('modals.checklists')"
                    clickable
                    @click="setModalChecklistOpen(true)"
                >
                    <floating-badge
                        rounded
                        :label="totalUncheckedCount"
                        class="text-bold"
                        text-color="on-surface"
                        color="on-surface-3"
                        :offset="[110, 10]"
                    />
                </app-navigation-item>
                <q-separator v-if="$q.screen.lt.md" class="q-my-sm" inset color="on-surface-3" />
                <app-navigation-settings :show-sub-items="canShowSettingsSubitems" />
                <app-navigation-item
                    icon="las la-project-diagram"
                    :title="$t('pages.projects')"
                    :to="{ name: 'projects' }"
                />
                <app-navigation-modules :show="!canShowSettingsSubitems" />
                <app-navigation-labels />
            </q-list>
            <app-navigation-footer />
        </div>
    </q-drawer>
</template>
