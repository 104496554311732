<script lang="ts" setup>
import { useAppStore } from '~/modules/core/stores/app';
import { PageBaseProps } from './_types';

const props = defineProps({
  "loading": { type: Boolean, default: false },
  "showContentWhenLoading": { type: Boolean, default: false },
  "padding": null
});

const isPageLoading = computed(() => props.loading || isRouting.value);
const { isRouting } = storeToRefs(useAppStore());

const getPageStyle = (offset: number, height: number) => {
    return {
        height: `${height - offset}px`,
    };
};
</script>

<template>
    <q-page :padding="padding" class="relative-position column no-wrap scroll" :style-fn="getPageStyle">
        <div v-if="isPageLoading" class="flex flex-stretch absolute-full all-pointer-events light-dimmed z-top">
            <slot name="loading">
                <q-inner-loading :showing="isPageLoading" color="primary" />
            </slot>
        </div>
        <slot v-if="!(showContentWhenLoading === false) || !isPageLoading" />
    </q-page>
</template>
