import {
    CommunicationMessageType,
    ModuleKey,
    NotificationType,
    ProjectSubjectBindType,
    SystemFeatureKey,
} from '~/modules/core/enums/_types';

const moduleIcons = {
    [ModuleKey.Dispatching]: 'las la-broadcast-tower',
    [ModuleKey.Inventory]: 'las la-box',
    [ModuleKey.HumanResources]: 'las la-universal-access',
    [ModuleKey.HumanitarianCare]: 'las la-people-carry',
    [ModuleKey.Reporting]: 'las la-paste',
    [ModuleKey.Statistics]: 'las la-chart-bar',
    [ModuleKey.Planning]: 'las la-calendar-week',
};

const communicationMessageIcons = {
    [CommunicationMessageType.Info]: 'las la-info',
    [CommunicationMessageType.Success]: 'las la-check',
    [CommunicationMessageType.Warning]: 'las la-exclamation',
    [CommunicationMessageType.Danger]: 'las la-skull-crossbones',
};

const systemFeatureIcons = {
    [SystemFeatureKey.CrisisCommunication]: 'las la-envelope-open-text',
    [SystemFeatureKey.CrisisDocuments]: 'las la-file-alt',
};

const notificationTypeIcons = {
    [NotificationType.Info]: 'las la-info',
    [NotificationType.Success]: 'las la-check',
    [NotificationType.Warning]: 'las la-exclamation',
    [NotificationType.Error]: 'las la-exclamation-triangle',
};

const projectSubjectBindTypeIcons = {
    [ProjectSubjectBindType.Inactive]: 'las la-minus-circle',
    [ProjectSubjectBindType.Invited]: 'las la-envelope-open',
    [ProjectSubjectBindType.Declined]: 'las la-times-circle',
    [ProjectSubjectBindType.Owner]: 'las la-home',
    [ProjectSubjectBindType.Participant]: 'las la-share-alt',
    [ProjectSubjectBindType.Remote]: 'las la-external-link-alt',
};

export const getIconNameByModuleKey = (moduleKey: ModuleKey): string => {
    return moduleIcons[moduleKey];
};

export const getIconNameBySystemFeature = (featureKey: SystemFeatureKey): string => {
    return systemFeatureIcons[featureKey];
};

export const getIconNameByCommunicationMessageType = (messageType: CommunicationMessageType): string => {
    return communicationMessageIcons[messageType];
};

export const getIconNameByNotificationType = (notificationType: NotificationType): string => {
    return notificationTypeIcons[notificationType];
};

export const getIconNameByProjectSubjectBindType = (type: ProjectSubjectBindType): string => {
    return projectSubjectBindTypeIcons[type];
};
