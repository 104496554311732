import { pick } from 'lodash-es';
import { DateTime } from 'luxon';
import { ApiResponseTransformer } from '~/plugins/apiClient/_types';
import { NotificationInfoDetail, NotificationInfo, NotificationInfoList, NotificationStatusInfo } from './_types';

export const rawToNotificationStatusInfo: ApiResponseTransformer<NotificationStatusInfo | null> = (data) => {
    if (data == null) return null;
    return {
        ...pick(data, ['Read', 'Unread']),
    };
};

export const rawToNotificationInfo: ApiResponseTransformer<NotificationInfo | null> = (data) => {
    if (data == null) return null;
    return {
        ...pick(data, ['Id', 'Title', 'Body', 'Type', 'RouteName', 'RouteId']),
        CreatedAt: DateTime.fromISO(data.CreatedAt),
        ReadAt: data.ReadAt ? DateTime.fromISO(data.ReadAt) : null,
    };
};

export const rawToNotificationInfoList: ApiResponseTransformer<NotificationInfoList | null> = (data) => {
    if (data == null) return null;

    const status = rawToNotificationStatusInfo(data.Status);
    if (status == null) {
        console.error(
            "[NNotificationInfoListTransformer] - Property 'Status' is required but [NotificationStatusInfoTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    return {
        Notifications: rawToNotificationInfoArray(data.Notifications) || [],
        Status: status,
    };
};

export const rawToNotificationInfoDetail: ApiResponseTransformer<NotificationInfoDetail | null> = (data) => {
    if (data == null) return null;

    const notification = rawToNotificationInfo(data.Notification);
    if (notification == null) {
        console.error(
            "[NotificationInfoDetailTransformer] - Property 'Notification' is required but [NotificationInfoTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    const status = rawToNotificationStatusInfo(data.Status);
    if (status == null) {
        console.error(
            "[NotificationInfoDetailTransformer] - Property 'Status' is required but [NotificationStatusInfoTransformer] returned null. Cannot transform object from BE."
        );
        return null;
    }

    return {
        Notification: notification,
        Status: status,
    };
};

export const rawToNotificationInfoArray: ApiResponseTransformer<NotificationInfo[] | null> = (data) => {
    if (data == null || !Array.isArray(data)) return null;
    return data.map((x) => rawToNotificationInfo(x)).filter((x): x is NotificationInfo => !!x);
};
