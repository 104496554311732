import { executeRoute } from '~/modules/core/utils/routeUtils';
import { NotificationAction } from '~/plugins/notificationManager/_types';

export enum NotificationActionDispatchingKey {
    Project = 'DispatchingProject',
    Issue = 'DispatchingIssue',
}

export const getActions = (): NotificationAction[] => {
    return [
        {
            key: NotificationActionDispatchingKey.Project,
            canExecuteFn: (id: number | null) => id != null,
            actionFn: async (id: number | null) => {
                if (id == null) return false;

                return await executeRoute({
                    name: 'dispatching-id',
                    params: {
                        id,
                    },
                });
            },
        },
        {
            key: NotificationActionDispatchingKey.Issue,
            canExecuteFn: (id: number | null) => id != null,
            actionFn: async (id: number | null) => {
                if (id == null) return false;

                return await executeRoute({
                    name: 'dispatching-issues-id',
                    params: {
                        id,
                    },
                });
            },
        },
    ];
};
