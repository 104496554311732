import { RouteLocationNormalized } from 'vue-router';
import { RoutePagePemissions } from '~/plugins/router/_types';
import usePermissionProject from '~/modules/core/composables/permissions/usePermissionsProject';
import usePermissionReportingReports from '~/modules/reporting/composables/permissions/usePermissionsReportingReports';
import { useReportingReportDetail } from '~/modules/reporting/composables/useReportingReportDetail';

export const permissions = (): RoutePagePemissions => {
    const { getProjectDetailFromRoute } = usePermissionProject();
    const { reload: reloadReport } = useReportingReportDetail();
    const { reload: reloadProject } = useProjectDetail();
    const { canCreateReport } = usePermissionReportingReports();

    const canGoNewReport = async (to: RouteLocationNormalized) => {
        const project = await getProjectDetailFromRoute(to);
        return canCreateReport(project);
    };

    const canGoRecreateReport = async (to: RouteLocationNormalized) => {
        const id = +to.params.id;

        if (!id) false;

        const { isSuccess, data: report } = await reloadReport(id);

        if (isSuccess && report != null) {
            const { data: project } = await reloadProject(report.Project.Id);
            return canCreateReport(project);
        }

        return false;
    };

    return {
        'reporting-id-new-report': canGoNewReport,
        'reporting-reports-id-new-report': canGoRecreateReport,
    };
};
