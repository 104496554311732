import { pick } from 'lodash-es';
import { DateTime } from 'luxon';

import { Checklist, ChecklistItem } from './_types';
import { ApiResponseTransformer } from '~/plugins/apiClient/_types';

const rawToChecklistItemFunc: ApiResponseTransformer<ChecklistItem> = (item) => {
    return {
        ...pick(item, ['Uid', 'Title', 'Checked']),
    };
};

const rawToChecklistItemArrayFunc: ApiResponseTransformer<ChecklistItem[]> = (items) => {
    return Array.isArray(items) ? items.map(rawToChecklistItemFunc) : [];
};

const rawToChecklistFunc: ApiResponseTransformer<Checklist> = (checklist) => {
    return {
        ...pick(checklist, ['Id', 'Title', 'TotalCheckedCount', 'TotalUncheckedCount']),
        Items: rawToChecklistItemArrayFunc(checklist.Items),
        CreatedAt: DateTime.fromISO(checklist.CreatedAt),
        UpdatedAt: DateTime.fromISO(checklist.UpdatedAt),
    };
};

export const rawToChecklistArray: ApiResponseTransformer<Checklist[] | null> = (data) => {
    if (data == null) return null;

    return Array.isArray(data) ? data.map(rawToChecklistFunc) : null;
};

export const rawToChecklist: ApiResponseTransformer<Checklist | null> = (data) => {
    if (data == null) return null;

    return rawToChecklistFunc(data);
};
