import api from '~/plugins/apiClient';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import { ApiHandlerWithData, ApiResponseContext } from '~/plugins/apiClient/_types';
import { rawToAppSearchResult } from './_transformers';
import { AppSearchResult } from './_types';

// > Endpoint prefix
const apiEndpointPrefix = 'search';

export const fetchAppSearchResult: ApiHandlerWithData<string, ApiResponseContext<AppSearchResult | null>> = async (
    searchPhrase?: string
) => {
    return api.get(formatApiUrl(apiEndpointPrefix), {
        transform: rawToAppSearchResult,
        params: { q: searchPhrase },
    });
};
