import { RouteWatchOptions } from './_types';
import useWatchApiData from './useWatchApiData';

const useRouteWatchApiData = <TData, TOptions>(
    fetchDataCallback: (id: number, opt?: TOptions) => Promise<{ isSuccess: boolean; data: TData | null }>,
    routeLoaderOpt: RouteWatchOptions,
    fetchOpt?: TOptions
) => {
    const { id } = useRouteId(routeLoaderOpt.watchRouteNamePrefix, false, routeLoaderOpt?.paramIdKey);

    const { isLoading, reload } = useWatchApiData(id, fetchDataCallback, null, fetchOpt);

    return {
        isLoading,
        reload,
    };
};

export default useRouteWatchApiData;
