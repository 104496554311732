import { QTableProps } from 'quasar';
import { FetchListFilterOptions, FetchListOptions } from '~/modules/core/api/_types';
import { fetchNotifications } from '~/modules/core/services/notifications';
import { useNotificationsStore } from '~/modules/core/stores/notifications';
import notificationManagerInstance from '~/plugins/notificationManager';
import { NotificationInfo } from '~/modules/core/api/notifications/_types';

const useNotifications = (
    initialPagination?: QTableProps['pagination'] | null,
    implicitFilter?: FetchListFilterOptions[] | null
) => {
    const notificationsStore = useNotificationsStore();
    const { status, needNotificationsRefresh } = storeToRefs(notificationsStore);
    const hasUnreadNotifications = computed(() => !!status.value?.Unread);

    const { data, pagination, userFilter, isLoading, request } = useApiCollectionDataObject(
        async (opt?: FetchListOptions) => {
            return await fetchNotifications(opt);
        },
        initialPagination,
        implicitFilter
    );

    const executeAction = async (info: NotificationInfo): Promise<boolean> => {
        return await notificationManagerInstance.execute(info);
    };

    const canExecuteAction = (info: NotificationInfo): boolean => {
        return notificationManagerInstance.canExecute(info);
    };

    watch(needNotificationsRefresh, async (newValue) => {
        if (newValue) {
            await request();
            notificationsStore.setNotificationsRefresh(false);
        }
    });

    return {
        status,
        hasUnreadNotifications,
        data,
        pagination,
        userFilter,
        isLoading,
        request,
        executeAction,
        canExecuteAction,
    };
};

export default useNotifications;
