<script lang="ts" setup>
import { pick } from 'lodash-es';
import { useForm } from 'vee-validate';
import { createTextNote, updateTextNote, uploadAttachment } from '~/modules/core/services/textNotes';
import { TextNote, TextNoteFormData } from '~/modules/core/api/textNotes/_types';
import { TextNotesFormProps } from '~/modules/core/components/notes/textNotes/form/_types';
import { VUploaderFile, VUploaderFileInfo } from '~/components/inputs/uploader/_types';

const props = defineProps({
  "activeItem": null
});

const emit = defineEmits(["submitFormSuccess", "closeForm"]);

const isUploading = ref<boolean>(false);
const files = ref<VUploaderFile[]>([]);
const initialFiles = ref<VUploaderFileInfo[]>([]);

const initialValues: TextNoteFormData = {
    Title: null,
    Body: null,
    Attachments: [],
};

const validationSchema = {
    Title: 'required',
    Body: 'required',
};

const { handleSubmit, isSubmitting, resetForm, setFieldValue } = useForm<TextNoteFormData>({
    initialValues,
    validationSchema: validationSchema,
});

onMounted(async () => {
    const initValues: TextNoteFormData =
        props.activeItem == null
            ? initialValues
            : {
                  Title: props.activeItem.Title,
                  Body: props.activeItem.Body,
                  Attachments: props.activeItem.Attachments.map((attachment) => attachment.Id),
              };
    initialFiles.value = props.activeItem == null ? [] : props.activeItem.Attachments;
    resetForm({ values: initValues });
});

const onSubmit = handleSubmit(async (...args) => {
    const result =
        props.activeItem == null ? await createTextNote(...args) : await updateTextNote(props.activeItem.Id, ...args);

    if (result.isSuccess && result.data != null) {
        emit('submitFormSuccess', result.data);
    }
});

const onCancel = (): void => {
    emit('closeForm');
};

const onFileUpload = async (file: File) => {
    const { isSuccess, errors, appFile } = await uploadAttachment({ DisplayName: file.name, File: file });

    const info: VUploaderFileInfo | null = !appFile
        ? null
        : { ...pick(appFile, ['Id', 'FileName', 'FileSize', 'FileUrl']) };

    return { errors, isSuccess, info };
};

const onFileChanged = (files: VUploaderFile[]) => {
    const successFiles = files.filter((x) => (x.status === 'uploaded' || x.status === 'exists') && x.info?.Id != null);
    const attachmentsIds = successFiles.map((x) => x.info!.Id);
    setFieldValue('Attachments', attachmentsIds);
};
</script>

<template>
    <app-form
        class="column full-height no-wrap"
        :submitting="isSubmitting"
        :show-cancel="true"
        :can-submit="!isUploading"
        :submit-btn-label="$t('notes.textNotes.save')"
        :cancel-btn-label="$t('notes.textNotes.cancel')"
        @submit="onSubmit"
        @cancel="onCancel"
    >
        <div class="row full-width">
            <v-input name="Title" type="text" class="col-12 q-mb-sm" :placeholder="$t('notes.textNotes.noteTitle')" />
        </div>
        <div class="row full-width col-grow">
            <v-input
                name="Body"
                type="textarea"
                class="col-12 q-pb-lg full-height"
                :placeholder="$t('notes.textNotes.noteDescription')"
            />
        </div>
        <div class="full-width col-grow">
            <v-uploader
                v-model="files"
                v-model:uploading="isUploading"
                name="Attachments"
                :multiple="true"
                :header-title="$t('notes.textNotes.insertFiles')"
                :initial-files="initialFiles"
                :on-file-upload="onFileUpload"
                @update:model-value="onFileChanged"
            />
        </div>
    </app-form>
</template>
