<script lang="ts" setup>
import { ChecklistsDetailProps } from './_types';
import { isChecklist } from '~/modules/core/components/notes/_types';

import { updateChecklistItem } from '~/modules/core/services/checklists';

defineProps({
  "items": null
});

const thumbStyle = {
    width: '5px',
};

const contentStyle = {
    width: '100%',
};

const { activeChecklist, updateChecklistById } = useChecklists();

const onUpdateValue = async () => {
    if (activeChecklist.value && isChecklist(activeChecklist.value)) {
        const newData = {
            Title: activeChecklist.value.Title,
            Items: activeChecklist.value.Items,
        };
        const result = await updateChecklistItem(activeChecklist.value.Id, newData);

        if (result.isSuccess && result.data != null) {
            updateChecklistById(activeChecklist.value.Id, result.data);
        }
    }
};
</script>

<template>
    <q-scroll-area class="col-grow fit" :content-style="contentStyle" :thumb-style="thumbStyle">
        <div class="q-pr-sm">
            <q-list>
                <q-item
                    v-for="(item, index) in items"
                    :key="item.Uid"
                    class="q-pl-none"
                    :class="{ 'text-on-surface-2': item.Checked }"
                >
                    <q-checkbox
                        v-model="item.Checked"
                        :name="`Items[${index}].Checked`"
                        :label="item.Title"
                        @update:modelValue="onUpdateValue"
                    />
                </q-item>
            </q-list>
        </div>
    </q-scroll-area>
</template>
