<script lang="ts" setup>
import { isEmpty } from 'lodash-es';
import { useDialogPluginComponent } from 'quasar';
import { AppDialogProps } from './_types';

defineProps({
  "title": null,
  "message": null,
  "cancel": null,
  "confirm": null,
  "cancelable": { type: Boolean,  }
});

defineEmits([
    // REQUIRED; need to specify some events that your
    // component will emit through useDialogPluginComponent()
    ...useDialogPluginComponent.emits,
]);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent();
</script>

<template>
    <q-dialog ref="dialogRef" @hide="onDialogHide">
        <q-card class="q-dialog-plugin">
            <q-card-section>
                <div class="text-h6 text-bold">{{ title }}</div>
                <div>{{ message }}</div>
            </q-card-section>

            <q-card-actions align="right">
                <flat-btn
                    v-if="cancelable"
                    v-bind="cancel"
                    :label="
                        isEmpty(cancel?.label) && isEmpty(cancel?.icon) ? $t('components.dialog.cancel') : cancel?.label
                    "
                    :text-color="
                        isEmpty(cancel?.textColor) && isEmpty(cancel?.activeColor) ? 'secondary' : cancel?.textColor
                    "
                    :active-color="
                        isEmpty(cancel?.textColor) && isEmpty(cancel?.activeColor) ? 'primary' : cancel?.textColor
                    "
                    @click="onDialogCancel"
                />
                <contained-btn
                    v-bind="confirm"
                    :label="
                        isEmpty(confirm?.label) && isEmpty(confirm?.icon)
                            ? $t('components.dialog.confirm')
                            : confirm?.label
                    "
                    @click="onDialogOK"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>
