

import __layout_0 from '/app/src/layouts/default.vue'
export const layouts = {
'auth': () => import('/app/src/layouts/auth.vue'),
'default': __layout_0,
'error': () => import('/app/src/layouts/error.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'default'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
