import { useAuthStore } from '~/plugins/auth/store';

const useAuthUser = () => {
    const { user } = storeToRefs(useAuthStore());

    return {
        user,
    };
};

export default useAuthUser;
