import { convertToNumber } from '~/utils/baseUtils';

/**
 *
 * @param routeNamePrefix - route name prefix - if current route does not match the prefix, id is always null
 * @param isQuery - if true, it will look in query params, otherwise it will loook in route params
 * @param keyId - key for id
 */
const useRouteId = (routeNamePrefix: string, isQuery = false, keyId = 'id') => {
    const route = useRoute();
    const id = computed(() => {
        if (!route.name?.toString().startsWith(routeNamePrefix)) {
            return null;
        }

        return convertToNumber(isQuery ? route.query[keyId]?.toString() : route.params[keyId]?.toString());
    });
    return {
        id,
    };
};

export default useRouteId;
