{
    "pages": {
        "humanitarian-care": "Humanitarian Care",
        "humanitarian-care-id": "Humanitarian Care - Overview",
        "humanitarian-care-id-settings": "Humanitarian Care - Settings",
        "humanitarian-care-id-settings-section": "Humanitarian Care - Settings",
        "humanitarian-care-id-new": "Humanitarian Care - New transaction",
        "humanitarian-care-new": "Humanitarian Care - New project",
        "humanitarian-care-transactions-id": "Humanitarian Care - Detail of transaction"
    },
    "humanitarianCare": {
        "overview": {
            "title-of-table": "Transactions",
            "actions": {
                "history": "All Changes"
            },
            "transactions": {
                "actions": {
                    "new-transaction": "New transaction",
                    "detail": "Detail"
                },
                "prompts": {
                    "delete": {
                        "title": "Delete transaction",
                        "message": "Do you really want to delete this transaction?",
                        "cancel": "Cancel",
                        "confirm": "Delete"
                    },
                    "restore": {
                        "title": "Restore transaction",
                        "message": "Do you really want to restore this transaction?",
                        "cancel": "Cancel",
                        "confirm": "Restore"
                    }
                }
            },
            "dashboard": {
                "title": {
                    "Total": "Total amount",
                    "ThisMonth": "Actual month",
                    "LastThreeMonths": "Last three months",
                    "BiggestDonor": "The biggest donor"
                },
                "label": {
                    "for-last-month": "For the last month",
                    "same-period-last-year": "Compared to the same period last year"
                },
                "chart": {
                    "transactions-history": "History of donations",
                    "this-year": "This year",
                    "last-year": "Last year",
                    "month": {
                        "january": "Jan",
                        "february": "Feb",
                        "march": "Mar",
                        "april": "Apr",
                        "may": "May",
                        "june": "Jun",
                        "july": "Jul",
                        "august": "Aug",
                        "september": "Spt",
                        "october": "Oct",
                        "november": "Nov",
                        "december": "Dec"
                    }
                }
            },
            "table": {
                "columns": {
                    "donor": "Donor",
                    "amount": "Amount",
                    "purpose": "Purpose",
                    "type": "Type",
                    "accountedAt": "Accounted At"
                }
            }
        },
        "new": {
            "transaction": {
                "title": "New transaction",
                "form": {
                    "inputs": {
                        "IsMailable": "Send confirmation by email",
                        "Name": "Name",
                        "Ico": "ICO",
                        "Address": "Address",
                        "Email": "Email",
                        "Note": "Note",
                        "Amount": "Amount",
                        "Purpose": "Purpose",
                        "Type": "Type",
                        "AccountedAt": "Accounted at"
                    },
                    "submit": {
                        "new": "Add transaction"
                    }
                },
                "notify": {
                    "success": "Transaction was successfully created"
                }
            }
        },
        "detail": {
            "transaction": {
                "title": "Detail of transaction",
                "exportPdf": "Export to PDF",
                "titles": {
                    "amount": "Amount",
                    "donor-email": "Email",
                    "donor-ico": "ICO",
                    "donor-address": "Address",
                    "accounted-at": "Accounted at",
                    "is-mailable": "Send confirmation by email",
                    "note": "Note",
                    "type": "Type",
                    "purpose": "Purpose"
                }
            }
        },
        "settings": {
            "sections": {
                "receiver": {
                    "title": "Receiver Settings",
                    "action": "Manage Receiver"
                }
            },
            "notify": {
                "receiver": {
                    "success": "Form was successfully saved"
                }
            },
            "receiver": {
                "title": "Receiver",
                "form": {
                    "inputs": {
                        "Name": "Name",
                        "Ico": "ICO",
                        "Address": "Address"
                    },
                    "submit": {
                        "edit": "Edit receiver"
                    }
                }
            }
        },
        "enums": {
            "type": {
                "Cash": "Cash",
                "Card": "Card",
                "Transfer": "Transfer"
            }
        }
    }
}