<script lang="ts" setup>
import { TextNotesDetailProps } from './_types';

defineProps({
  "item": null
});

const thumbStyle = {
    width: '5px',
};

const contentStyle = {
    width: '100%',
};
</script>

<template>
    <q-scroll-area class="col-grow fit" :content-style="contentStyle" :thumb-style="thumbStyle" style="flex: 0 1 100%">
        <div class="space-pre-wrap q-pr-sm">
            {{ item.Body }}
        </div>
    </q-scroll-area>

    <template v-if="item.Attachments.length > 0">
        <div class="q-pt-md">
            <q-separator class="q-py-none" />
        </div>

        <div class="q-pt-sm">
            <file-chip v-for="attachment in item.Attachments" :key="attachment.Id" :file="attachment">a</file-chip>
        </div>
    </template>
</template>
