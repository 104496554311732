import { isEmpty } from 'lodash-es';
import {
    AppSearchResult,
    AppSearchInfo,
    isAppSearchSubjectInfo,
    isAppSearchProjectInfo,
    isAppSearchLabelInfo,
} from '~/modules/core/api/search/_types';
import { searchApp } from '~/modules/core/services/search';
import { getModuleDetailRoute } from '~/modules/core/utils/routeUtils';

// how many characters must 'searchPhrase' have, to allow send search request to backend
const SEARCH_PHRASE_CHAR_DEBOUNCE = 2;

const useAppSearch = () => {
    const router = useRouter();

    const searchPhrase = ref('');
    const searchResult = ref<AppSearchResult | null>(null);
    const totalResultsCount = ref(0);
    const searchSuccess = ref(false);
    const noResultsFound = ref(false);
    const searchSkipped = ref(false);

    const canSearch = () => !isEmpty(searchPhrase.value) && searchPhrase.value.length >= SEARCH_PHRASE_CHAR_DEBOUNCE;

    const { isLoading, request } = useApiCollectionDataObject(async () => {
        searchSkipped.value = !canSearch();
        if (searchSkipped.value) {
            clear(false);
            return { isSuccess: false, data: null };
        }

        const { data, isSuccess, totalResultCount: resultsCount } = await searchApp(searchPhrase.value);

        // @plachtova: because search is done with debounce delay after search we check if still valid,
        // if not we ignore the result
        if (!canSearch()) {
            return { isSuccess, data };
        }

        searchSkipped.value = false;
        searchSuccess.value = isSuccess;
        searchResult.value = data;
        totalResultsCount.value = resultsCount;
        noResultsFound.value = isSuccess && !searchSkipped.value && resultsCount === 0;

        return { isSuccess, data };
    });

    const clear = (clearPhrase: boolean): void => {
        if (clearPhrase) {
            searchPhrase.value = '';
        }

        searchSkipped.value = true;
        searchResult.value = null;
        searchSuccess.value = true;
        noResultsFound.value = false;
        totalResultsCount.value = 0;
    };

    const goToSearchPage = (): void => {
        if (canSearch()) {
            router.push({ name: 'search', query: { q: searchPhrase.value } });
        }
    };

    const goToResultPage = (result: AppSearchInfo): void => {
        if (isAppSearchSubjectInfo(result)) {
            router.push({ name: 'settings-subjects-id', params: { id: result.Id } });
        }

        if (isAppSearchProjectInfo(result)) {
            router.push(getModuleDetailRoute(result.ModuleKey, result.Id));
        }

        if (isAppSearchLabelInfo(result)) {
            router.push({ name: 'projects', query: { [`filter[LabelId][eq]`]: result.Id } });
        }
    };

    return {
        searchSuccess,
        searchPhrase,
        searchResult,
        totalResultsCount,
        noResultsFound,
        searchSkipped,
        isLoading,
        search: request,
        clear,
        goToResultPage,
        goToSearchPage,
    };
};

export default useAppSearch;
