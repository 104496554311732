{
    "pages": {
        "inventory": "Inventory",
        "inventory-new": "Inventory - New Project",
        "inventory-id": "Inventory - Overview",
        "inventory-id-settings": "Inventory - Settings",
        "inventory-id-new-product": "Inventory - New Products",
        "inventory-products-id": "Inventory - Product Detail",
        "inventory-id-history": "Inventory - History Overview",
        "inventory-history-id": "Inventory - History Detail",
        "inventory-id-history-new-income": "Inventory - New Income",
        "inventory-id-history-new-outcome": "Inventory - New Outcome"
    },
    "inventory": {
        "overview": {
            "actions": {
                "history": "Income/Outcome"
            },
            "products": {
                "actions": {
                    "detail": "detail",
                    "new-product": "New Product"
                },
                "filter": {
                    "state": "State"
                }
            },
            "history": {
                "actions": {
                    "detail": "detail",
                    "history": "All Changes"
                },
                "columns": {
                    "info": "Last Changes"
                },
                "items": "item | items"
            }
        },
        "new": {
            "newProduct": "New Products"
        },
        "detail": {
            "productDetail": "Product Detail",
            "amountInfo": "Inventory Status",
            "actions": {
                "goBack": "Back to overview",
                "edit": "Edit Product",
                "limits": "Limits Settings"
            },
            "history": {
                "actions": {
                    "detail": "detail"
                },
                "columns": {
                    "info": "Last Changes"
                }
            }
        },
        "history": {
            "type": {
                "Income": "Income",
                "Outcome": "Outcome"
            },
            "actions": {
                "goBackOverview": "Back to Overview",
                "goBackHistory": "Back to History",
                "income": "New Income",
                "outcome": "New Outcome",
                "detail": "detail"
            },
            "table": {
                "columns": {
                    "type": "Type",
                    "user": "User",
                    "itemsCount": "Items Count",
                    "createdAt": "Created"
                }
            },
            "detail": {
                "title": {
                    "Income": "Income Info",
                    "Outcome": "Outcome Info"
                },
                "labels": {
                    "description": "Description",
                    "items": "Items",
                    "name": "Name",
                    "value": {
                        "Income": "Recieved amount",
                        "Outcome": "Issued amount"
                    },
                    "amount": "Inventory amount"
                }
            },
            "new": {
                "title": {
                    "Income": "New Income",
                    "Outcome": "New Outcome"
                }
            }
        },
        "form": {
            "enums": {
                "title": {
                    "new": "New Enum",
                    "edit": "Edit Enum"
                },
                "inputs": {
                    "Name": "Name"
                },
                "submit": {
                    "new": "Create Enum",
                    "edit": "Edit Enum"
                },
                "cancel": "Cancel"
            },
            "enumItems": {
                "title": "Add Items",
                "inputs": {
                    "Values": "New Items",
                    "Value": "Item"
                },
                "submit": {
                    "new": "Create Items",
                    "edit": "Edit Item"
                },
                "cancel": "Cancel"
            },
            "schema": {
                "inputs": {
                    "Name": "Name",
                    "Type": "Type",
                    "EnumId": "Enum",
                    "Precision": "Precision",
                    "Unit": "Unit",
                    "IsActive": "Active",
                    "IsRequired": "Required",
                    "IsListable": "Listable"
                },
                "appendFields": {
                    "Unit": "pcs, kg, l etc."
                },
                "submit": "Save Settings",
                "cancel": "Cancel"
            },
            "product": {
                "title": {
                    "edit": "Edit Product",
                    "limits": "Limits Settings"
                },
                "submit": {
                    "new": "Create Products",
                    "edit": "Edit Product",
                    "limits": "Edit Limits"
                },
                "cancel": "Cancel"
            },
            "history": {
                "inputs": {
                    "Note": "Note",
                    "Product": "Product",
                    "Amount": "Available Amount",
                    "Value": {
                        "Income": "Recieved amount",
                        "Outcome": "Issued amount"
                    }
                },
                "submit": {
                    "Income": "Create Income",
                    "Outcome": "Create Outcome"
                },
                "cancel": "Cancel",
                "prompts": {
                    "confirm": {
                        "title": "Exceeding the permitted limits for the product",
                        "message": {
                            "Income": "The amount received for some products exceeds the permitted limits. Are you sure you want to create a new income?",
                            "Outcome": "The amount issued for some products exceeds the permitted limits. Are you sure you want to create a new outcome?"
                        },
                        "confirm": "Přesto vytvořit",
                        "cancel": "Zrušit"
                    }
                }
            }
        },
        "settings": {
            "sections": {
                "enums": {
                    "title": "Enums Settings",
                    "action": "Manage settings"
                },
                "schema": {
                    "title": "Data Settings",
                    "action": "Manage table data"
                }
            },
            "enums": {
                "title": "Enums",
                "columns": {
                    "name": "Name",
                    "itemsCount": "Items Count"
                },
                "actions": {
                    "create": "New Enum"
                },
                "prompts": {
                    "delete": {
                        "title": "Delete Enum",
                        "message": "Do you really want to delete this enum?",
                        "confirm": "Delete",
                        "cancel": "Cancel"
                    }
                },
                "tooltips": {
                    "cannotDelete": "Cannot delete, because there still exist used enum items."
                }
            },
            "enumItems": {
                "title": "Enum",
                "columns": {
                    "value": "Name",
                    "assigned": "Assigned"
                },
                "actions": {
                    "create": "Add Items",
                    "search": "Search Items"
                },
                "prompts": {
                    "delete": {
                        "title": "Delete Item",
                        "message": "Do you really want to delete this enum item?",
                        "confirm": "Delete",
                        "cancel": "Cancel"
                    }
                },
                "tooltips": {
                    "cannotDelete": "Cannot delete, because this enum item is still used."
                }
            },
            "schema": {
                "title": "Table data",
                "columns": {
                    "predefined": {
                        "Name": "Name",
                        "Amount": "Amount",
                        "AmountUnit": "Unit",
                        "AmountMinimal": "Minimal Amount",
                        "AmountOptimal": "Optimal Amount",
                        "AmountMaximal": "Maximal Amount",
                        "Icon": "Icon"
                    }
                },
                "type": {
                    "items": {
                        "Numeric": "Number",
                        "Boolean": "Choice Yes/No",
                        "Date": "Date",
                        "Datetime": "Date and Time",
                        "Enum": "Enum Selection",
                        "String": "Text"
                    }
                }
            },
            "notify": {
                "schema": {
                    "success": "Form was successfully saved"
                }
            }
        },
        "amountSummary": {
            "amount": "Current Amount",
            "amountMinimal": "Minimal Amount",
            "amountOptimal": "Optimal Amount",
            "amountMaximal": "Maximal Amount"
        },
        "productState": {
            "Unknown": "Unknown",
            "LessThanMinimal": "Less than minimum",
            "LessThanOptimal": "Less than optimal",
            "Normal": "Optimal",
            "MoreThanMaximal": "More than maximum"
        }
    },
    "notifications": {
        "actions": {
            "IsklInventoryModelsInventoryProduct": {
                "actionTitle": "Show product"
            }
        }
    }
}
