import { executeRoute } from '~/modules/core/utils/routeUtils';
import { NotificationAction } from '~/plugins/notificationManager/_types';

export enum NotificationActionStatisticsKey {
    Project = 'StatisticsProject',
}

export const getActions = (): NotificationAction[] => {
    return [
        {
            key: NotificationActionStatisticsKey.Project,
            canExecuteFn: (id: number | null) => id != null,
            actionFn: async (id: number | null) => {
                if (id == null) return false;

                return await executeRoute({
                    name: 'statistics-id',
                    params: {
                        id,
                    },
                });
            },
        },
    ];
};
