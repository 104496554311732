import { useQuasar } from 'quasar';
import AppDialog from '~/components/modals/AppDialog.vue';
import { AppDialogProps } from '~/components/modals/_types';

export const useDialog = () => {
    const q = useQuasar();

    const _showDialog = (opt: AppDialogProps) => {
        return q.dialog({
            persistent: true,
            component: AppDialog,
            componentProps: opt,
        });
    };

    const showAlert = (opt: Omit<AppDialogProps, 'cancelable' | 'cancel'>) => {
        return _showDialog({ ...opt, cancelable: false });
    };

    const showConfirm = (opt: Omit<AppDialogProps, 'cancelable'>) => {
        return _showDialog({ ...opt, cancelable: true });
    };

    return {
        showAlert,
        showConfirm,
    };
};
