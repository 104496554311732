import {
    fetchTextNotesRequest,
    createTextNoteRequest,
    updateTextNoteRequest,
    deleteTextNoteRequest,
    postAttachmentRequest,
} from '~/modules/core/api/textNotes';
import useTextNotesStore from '~/modules/core/stores/textNotes';

import { FormHandler, ApiErrors, FormHandlerWithArgs } from '~/plugins/apiClient/_types';
import { TextNote, TextNoteFormData } from '~/modules/core/api/textNotes/_types';
import { FetchListOptions } from '~/modules/core/api/_types';
import { AppFileDetail, UploadFileFormData } from '~/modules/core/api/files/_types';

const createStoreTextNote = async (
    values: TextNoteFormData
): Promise<{
    isSuccess: boolean;
    isValidationError: boolean;
    errors: ApiErrors<TextNoteFormData>;
    data: TextNote | null;
}> => {
    return await createTextNoteRequest({ values });
};

const updateStoreTextNote = async (
    id: number,
    values: TextNoteFormData
): Promise<{
    isSuccess: boolean;
    isValidationError: boolean;
    errors: ApiErrors<TextNoteFormData>;
    data: TextNote | null;
}> => {
    return await updateTextNoteRequest({ id, values });
};

export const fetchTextNotes = async (
    opt?: FetchListOptions
): Promise<{ isSuccess: boolean; data: TextNote[] | null }> => {
    const result = await fetchTextNotesRequest({ opt });

    if (result.isSuccess && result.data) {
        const { textNotes, totalTextNotes } = storeToRefs(useTextNotesStore());
        totalTextNotes.value = result.meta.Total;
        textNotes.value =
            opt?.pagination?.offset && opt.pagination.offset !== 0 ? textNotes.value.concat(result.data) : result.data;
    }

    return result;
};

export const createTextNote: FormHandler<TextNoteFormData, { isSuccess: boolean; data: TextNote | null }> = async (
    values,
    { setErrors }
) => {
    const result = await createStoreTextNote(values);

    if (result.isValidationError) {
        setErrors(result.errors);
    }

    return result;
};

export const updateTextNote: FormHandlerWithArgs<
    number,
    TextNoteFormData,
    { isSuccess: boolean; data: TextNote | null }
> = async (id, values, { setErrors }) => {
    const result = await updateStoreTextNote(id, values);

    if (result.isValidationError) {
        setErrors(result.errors);
    }

    return result;
};

export const deleteTextNote = async (data: TextNote): Promise<{ isSuccess: boolean }> => {
    const result = await deleteTextNoteRequest({ id: data.Id });

    if (result.isSuccess && data) {
        const store = useTextNotesStore();
        store.deleteTextNoteById(data.Id);
    }

    return result;
};

export const uploadAttachment = async (
    values: UploadFileFormData
): Promise<{
    isSuccess: boolean;
    isValidationError: boolean;
    errors: string | string[];
    appFile: AppFileDetail | null;
}> => {
    const { data, isSuccess, isValidationError, errors } = await postAttachmentRequest({ data: values });
    let err: string | string[] = '';

    if (isValidationError) {
        err = errors.File || '';
    }

    return { isSuccess, isValidationError, errors: err, appFile: data };
};
