<script lang="ts" setup>
import { ChecklistsListItemProps } from './_types';

const props = defineProps({
  "item": null
});
const { item } = toRefs(props);

const numberOfResolvedItems = computed(() => {
    return item.value.Items.filter((item) => item.Checked).length;
});

const numberOfItemsToResolve = computed(() => {
    return item.value.Items.filter((item) => !item.Checked).length;
});
</script>

<template>
    <q-item-section>
        <q-item-label class="notes__list-item-label">{{ item.Title }}</q-item-label>
        <q-item-label class="row justify-between items-center" caption
            ><div class="flex items-center">
                <q-icon name="las la-check" size="1rem" color="dark" />&nbsp;{{
                    $t('notes.checklists.resolved')
                }}&nbsp;<strong>{{ numberOfResolvedItems }}</strong>
            </div>
            <div v-if="numberOfItemsToResolve > 0" class="row items-center text-dark">
                &hellip;&nbsp;{{ $t('notes.checklists.resolve') }}&nbsp;<strong>{{ numberOfItemsToResolve }}</strong>
            </div>
        </q-item-label>
        <div v-if="numberOfItemsToResolve === 0" class="absolute-top-right circle-check text-center">
            <q-icon name="las la-check" />
        </div>
    </q-item-section>
</template>
