import { RoutePagePemissions } from '~/plugins/router/_types';
import usePermissionUser from '~/modules/core/composables/permissions/usePermissionsUser';

export const permissions = (): RoutePagePemissions => {
    const { canCreateAny, canManageAny } = usePermissionUser();
    return {
        'settings-users': canManageAny,
        'settings-users-new': canCreateAny,
    };
};
