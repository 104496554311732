<script lang="ts" setup>
import { Guid } from 'guid-ts';
import { useForm, useFieldArray, useFieldError } from 'vee-validate';
import draggable from 'vuedraggable';
import { DraggableEvent } from '~/components/dragagable/_types';
import { createChecklist, updateChecklist } from '~/modules/core/services/checklists';
import { ChecklistFormData, Checklist, ChecklistItemFormData } from '~/modules/core/api/checklists/_types';
import { ChecklistsFormProps } from '~/modules/core/components/notes/checklists/form/_types';

const props = defineProps({
  "activeItem": null,
  "setActiveItem": { type: Function,  },
  "resetItems": { type: Function,  }
});

const showItemsError = ref<boolean>(false);

const emit = defineEmits(["submitFormSuccess", "closeForm"]);

const createNewItem = (): ChecklistItemFormData => ({
    Title: null,
    Checked: false,
    Uid: Guid.newGuid().toString(),
});

const initialValues: ChecklistFormData = {
    Title: null,
    Items: [createNewItem()],
};

const validationSchema = {
    Title: 'required',
    Items: 'required',
};

const { focusLastRowInput } = useFormRow('.user-checklist-form-row');
const { handleSubmit, isSubmitting, resetForm } = useForm<ChecklistFormData>({
    initialValues,
    validationSchema: validationSchema,
});

const { move, push, remove, fields } = useFieldArray('Items');

const itemsErrorMessage = useFieldError('Items');

onMounted(async () => {
    const initValues: ChecklistFormData =
        props.activeItem == null
            ? initialValues
            : {
                  Title: props.activeItem.Title,
                  Items: props.activeItem.Items,
              };
    resetForm({ values: initValues });
});

const onSubmit = handleSubmit(
    async (...args) => {
        const result =
            props.activeItem == null
                ? await createChecklist(...args)
                : await updateChecklist(props.activeItem.Id, ...args);

        if (result.isSuccess && result.data != null) {
            emit('submitFormSuccess', result.data);
        }

        showItemsError.value = false;
    },
    () => {
        showItemsError.value = true;
    }
);

const onCancel = (): void => {
    showItemsError.value = false;
    emit('closeForm');
};

const onAdd = (focus: boolean) => {
    push(createNewItem());
    if (focus) focusLastRowInput();
};

const endDrag = (event: DraggableEvent) => {
    move(event.oldIndex, event.newIndex);
};
</script>

<template>
    <app-form
        class="column full-height no-wrap"
        :can-submit="!!fields.length"
        :submitting="isSubmitting"
        :show-cancel="true"
        :submit-btn-label="$t('notes.checklists.save')"
        :cancel-btn-label="$t('notes.checklists.cancel')"
        @submit="onSubmit"
        @cancel="onCancel"
    >
        <div class="row full-width">
            <v-input name="Title" type="text" class="col-12 q-mb-sm" :placeholder="$t('notes.checklists.noteTitle')" />
        </div>

        <div class="row full-width col-grow q-pb-lg">
            <q-scroll-area class="col-grow fit" :content-style="{ width: '100%' }" :thumb-style="{ width: '5px' }">
                <q-list class="col-12">
                    <draggable :model-value="fields" item-key="key" handle=".handle" @end="endDrag">
                        <template #item="{ index }">
                            <q-item class="user-checklist-form-row row items-center full-width q-px-none">
                                <flat-btn text-color="negative" icon="las la-times-circle" @click="remove(index)">
                                    <q-tooltip>
                                        {{ $t('generic.delete') }}
                                    </q-tooltip>
                                </flat-btn>
                                <v-input
                                    :name="`Items[${index}].Title`"
                                    type="text"
                                    class="col-grow q-pb-none"
                                    @keydown.prevent.enter="() => onAdd(true)"
                                />
                                <div class="handle column q-pa-md">
                                    <q-icon name="las la-sort" size="sm" color="on-surface-1" />
                                </div>
                            </q-item>
                        </template>
                    </draggable>

                    <div class="row items-center full-width q-pt-sm">
                        <flat-btn
                            class="q-pl-md"
                            icon="las la-plus-circle"
                            :label="$t('generic.add')"
                            @click="() => onAdd(true)"
                        />
                    </div>

                    <div
                        v-if="showItemsError && !!itemsErrorMessage"
                        class="hint-error--message text-negative q-mt-sm custom-error"
                    >
                        {{ itemsErrorMessage }}
                    </div>
                </q-list>
            </q-scroll-area>
        </div>
    </app-form>
</template>
