<script lang="ts" setup>
import { fetchLabelsWithCount } from '~/modules/core/services/labels';

const { labels } = useMyLabels();

onMounted(async () => {
    await fetchLabelsWithCount();
});
</script>

<template>
    <label-separator
        v-if="labels.length > 0"
        class="q-mt-lg q-mb-sm"
        color="on-surface-2"
        line-color="on-surface-3"
        :label="$t('navigation.groups.labels')"
    />

    <div v-if="labels.length > 0" class="row q-px-md">
        <flat-btn
            v-for="label in labels"
            :key="label.Id"
            :to="{ name: 'projects', query: { [`filter[LabelId][eq]`]: label.Id } }"
            class="q-mr-md q-my-sm q-pa-none"
        >
            <label-chip :label="label.Name" :projects-count="label.ProjectsCount" />
        </flat-btn>
    </div>
</template>
