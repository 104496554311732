{
    "pages": {
        "humanitarian-care": "Humanitární pomoc",
        "humanitarian-care-id": "Humanitární pomoc - Přehled",
        "humanitarian-care-id-settings": "Humanitární pomoc - Nastavení",
        "humanitarian-care-id-settings-section": "Humanitární pomoc - Nastavení",
        "humanitarian-care-id-new": "Humanitární pomoc - Nová transakce",
        "humanitarian-care-new": "Humanitární pomoc - Nový projekt",
        "humanitarian-care-transactions-id": "Humanitární pomoc - Detail transakce"
    },
    "humanitarianCare": {
        "overview": {
            "title-of-table": "Transakce",
            "actions": {
                "history": "Všechny změny"
            },
            "transactions": {
                "actions": {
                    "new-transaction": "Nová transakce",
                    "detail": "Detail"
                },
                "prompts": {
                    "delete": {
                        "title": "Odstranit transakci",
                        "message": "Opravdu chcete odstranit tuto transakci?",
                        "cancel": "Zrušit",
                        "confirm": "Odstranit"
                    },
                    "restore": {
                        "title": "Obnovit transakci",
                        "message": "Opravdu chcete obnovit tuto transakci?",
                        "cancel": "Zrušit",
                        "confirm": "Obnovit"
                    }
                }
            },
            "dashboard": {
                "title": {
                    "Total": "Celkem darovaná částka",
                    "ThisMonth": "Aktuální měsíc",
                    "LastThreeMonths": "Poslední tři měsíce",
                    "BiggestDonor": "Největší přispěvatel"
                },
                "label": {
                    "for-last-month": "Za poslední měsíc",
                    "same-period-last-year": "Oproti stejnému období v loňském roce"
                },
                "chart": {
                    "transactions-history": "Historie darů",
                    "this-year": "Tento rok",
                    "last-year": "Minulý rok",
                    "month": {
                        "january": "Led",
                        "february": "Úno",
                        "march": "Břen",
                        "april": "Dub",
                        "may": "Kvě",
                        "june": "Čer",
                        "july": "Čec",
                        "august": "Srp",
                        "september": "Zář",
                        "october": "Říj",
                        "november": "Lis",
                        "december": "Pro"
                    }
                }
            },
            "table": {
                "columns": {
                    "donor": "Dárce",
                    "amount": "Částka",
                    "purpose": "Účel",
                    "type": "Typ",
                    "accountedAt": "Přijetí daru"
                }
            }
        },
        "new": {
            "transaction": {
                "title": "Nová transakce",
                "form": {
                    "inputs": {
                        "IsMailable": "Poslat potvrzení mailem",
                        "Name": "Název",
                        "Ico": "IČO",
                        "Address": "Adresa",
                        "Email": "E-mail",
                        "Note": "Poznámka",
                        "Amount": "Částka daru",
                        "Purpose": "Účel daru",
                        "Type": "Typ platby",
                        "AccountedAt": "Datum platby"
                    },
                    "submit": {
                        "new": "Přidat platbu"
                    }
                },
                "notify": {
                    "success": "Transakce byla úspěšně vytvořena"
                }
            }
        },
        "detail": {
            "transaction": {
                "title": "Detail transakce",
                "exportPdf": "Exportovat do PDF",
                "titles": {
                    "amount": "Částka daru",
                    "donor-email": "E-mail",
                    "donor-ico": "IČO",
                    "donor-address": "Adresa",
                    "accounted-at": "Datum platby",
                    "is-mailable": "Poslat potvrzení mailem",
                    "note": "Poznámka",
                    "type": "Typ platby",
                    "purpose": "Účel daru"
                }
            }
        },
        "settings": {
            "sections": {
                "receiver": {
                    "title": "Nastavení Příjemce",
                    "action": "Spravovat Příjemce"
                }
            },
            "notify": {
                "receiver": {
                    "success": "Formulář byl úspěšně uložen"
                }
            },
            "receiver": {
                "title": "Příjemce",
                "form": {
                    "inputs": {
                        "Name": "Název",
                        "Ico": "IČO",
                        "Address": "Adresa"
                    },
                    "submit": {
                        "edit": "Upravit příjemce"
                    }
                }
            }
        },
        "enums": {
            "type": {
                "Cash": "V hotovosti",
                "Card": "Kartou",
                "Transfer": "Převod"
            }
        }
    }
}
