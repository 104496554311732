<script lang="ts">
const debounce = 500
</script>
<script lang="ts" setup>
import { AppSearchInfo } from '~/modules/core/api/search/_types';
import { AppSearchBarProps } from './_types';

const props = defineProps({
  "onBlur": null,
  "onFocus": null,
  "autofocus": null
});


const inputElRef = ref<HTMLInputElement>();
const isInputFocused = ref(false);
const isMenuOpen = computed(() => (noResultsFound.value || totalResultsCount.value > 0) && isInputFocused.value);
const {
    isLoading,
    searchPhrase,
    searchResult,
    totalResultsCount,
    noResultsFound,
    search,
    clear,
    goToSearchPage,
    goToResultPage,
} = useAppSearch();

const focus = (): void => {
    inputElRef.value?.focus();
};

const clearInput = (): void => {
    clear(true);

    setTimeout(() => {
        inputElRef.value?.blur();
    }, 1);
};

const onInputFocus = () => {
    isInputFocused.value = true;
};

const onInputBlur = () => {
    // @plachtova we need a little bit delay because otherwise the click events from menu will not work
    // (menu from DOM will be removed before click events are fired)
    setTimeout(() => {
        isInputFocused.value = false;
    }, 100);
};

const onInputValueChange = async (): Promise<void> => {
    await search();
};

const onInputKeyEnter = useDebounceFn(() => {
    goToSearchPage();
    clearInput();
}, debounce);

const onMenuItemClick = (result: AppSearchInfo): void => {
    goToResultPage(result);
    clearInput();
};

defineExpose({ focus: focus });
</script>
<template>
    <q-input
        ref="inputElRef"
        v-model="searchPhrase"
        v-bind="props"
        type="search"
        class="q-ml-md q-px-sm"
        :debounce="debounce"
        :placeholder="$t('search.searchBar.whatAreYouSearchingFor')"
        filled
        name="search"
        hide-bottom-space
        dense
        autocomplete="off"
        @update:model-value="onInputValueChange"
        @focus="onInputFocus"
        @blur="onInputBlur"
        @keydown.enter="onInputKeyEnter"
    >
        <template #prepend>
            <q-icon class="q" name="las la-search" />
        </template>
        <!-- TODO: for some reason click does not work if we have 'mobile version of header' -->
        <q-menu
            class="column justify-center"
            style="min-width: 255px; min-height: 70px"
            :model-value="isMenuOpen"
            no-focus
            no-parent-event
            :offset="[0, 3]"
        >
            <div v-if="searchResult == null || noResultsFound" class="justify-center q-ma-sm text-bold">
                {{ $t('search.searchBar.noResults') }}
            </div>
            <div v-else-if="totalResultsCount > 0">
                <app-search-bar-result :results="searchResult" @click="onMenuItemClick" />
            </div>
            <q-inner-loading :showing="isLoading" />
        </q-menu>
    </q-input>
</template>
