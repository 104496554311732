<script lang="ts" setup>
import { TextNote } from '~/modules/core/api/textNotes/_types';
import { Checklist } from '~/modules/core/api/checklists/_types';
import { NoteTypes, isTextNote } from '~/modules/core/components/notes/_types';

import { deleteTextNote } from '~/modules/core/services/textNotes';

const {
    textNotes,
    totalTextNotes,
    textNoteFormOpen,
    activeTextNote,
    modalTextNoteOpen,
    setTextNoteFormOpen,
    setActiveTextNote,
    setModalTextNoteOpen,
    clearTextNotes,
} = useTextNotes();

const { requestOptions, isLoading, request } = useLoadTextNotes();

const canLoadMore = computed(() => {
    return totalTextNotes.value > textNotes.value.length;
});

const afterCloseModal = () => {
    clearTextNotes();
    setActiveTextNote(null);
    setTextNoteFormOpen(false);
};

const fncDeleteTextNote = async (item: Checklist | TextNote) => {
    if (!isTextNote(item)) {
        return;
    }

    const result = await deleteTextNote(item);

    if (result.isSuccess) {
        clearTextNotes();
        setActiveTextNote(null);
        await fncLoadTextNotes({ index: 1 });
    }
};

const fncLoadTextNotes = async (data: { index: number; done?: (stop?: boolean) => void }) => {
    const opt = JSON.parse(JSON.stringify(requestOptions));

    opt.pagination.page = data.index;

    await request(opt);

    if (data.done) {
        data.done();
    }
};

const fncSetActiveTextNote = (item: Checklist | TextNote | null) => {
    if (item !== null && !isTextNote(item)) {
        return;
    }

    setActiveTextNote(item);
};

const submitFormSuccess = async (data: TextNote) => {
    setTextNoteFormOpen(false);
    fncSetActiveTextNote(null);
    clearTextNotes();

    await fncLoadTextNotes({ index: 1 });

    fncSetActiveTextNote(data);
};

watch(modalTextNoteOpen, async (newValue: boolean) => {
    if (newValue === false) {
        afterCloseModal();
    }
});
</script>

<template>
    <notes-modal
        :modal-open="modalTextNoteOpen"
        :type="NoteTypes.textNotes"
        :form-open="textNoteFormOpen"
        :items="textNotes"
        :active-item="activeTextNote"
        :disable-load="!canLoadMore"
        :is-loading="isLoading"
        :set-modal-open="setModalTextNoteOpen"
        :set-form-open="setTextNoteFormOpen"
        :load-items="fncLoadTextNotes"
        :set-active-item="fncSetActiveTextNote"
        :delete-item="fncDeleteTextNote"
    >
        <template #itemOfList="Item">
            <text-notes-list-item v-if="isTextNote(Item)" :item="Item" />
        </template>

        <template #itemDetail="Item">
            <text-notes-detail v-if="isTextNote(Item)" :item="Item" />
        </template>

        <template #itemForm>
            <text-notes-form
                :active-item="activeTextNote"
                @submit-form-success="submitFormSuccess"
                @close-form="setTextNoteFormOpen(false)"
            />
        </template>
    </notes-modal>
</template>
