<script setup lang="ts">
import { isEmpty } from 'lodash-es';
import { QRejectedEntry, QUploader } from 'quasar';
import { useField } from 'vee-validate';
import { t } from '~/plugins/i18n';
import { VUploaderFile, VUploaderProps } from './_types';
import { createFile } from './uploaderUtils';
import { useConfigStore } from '~/modules/core/stores/config';
import { getHumanFileSize } from '~/modules/core/utils/fileUtils';

const props = defineProps({
  "headerTitle": null,
  "name": null,
  "mode": { default: 'chips' },
  "onFileUpload": { type: Function,  },
  "initialFiles": null,
  "flat": { default: true },
  "label": null,
  "color": { default: 'surface' },
  "textColor": { default: 'on-surface-1' },
  "bordered": { default: true },
  "maxFileSize": null,
  "maxTotalSize": null,
  "accept": { default: '*/*' },
  "disable": null,
  "readonly": null,
  "multiple": { default: false },
  "errorKeyName": null,
  "validateOnValueUpdate": { type: Boolean,  }
});

const emit = defineEmits(["update:model-value", "update:uploading"]);

// > Refs
const uploaderRef = ref<InstanceType<typeof QUploader>>();
const files = ref<VUploaderFile[]>([]);

const isUploading = computed(() => files.value.some((x) => x.status === 'uploading'));

const canAddFiles = computed(() =>
    props.multiple == null || props.multiple === false ? files.value.length === 0 : true
);
const errorBagMessage = computed(() =>
    errors.value.reduce((prev, item) => (isEmpty(prev) ? item : `${prev}, ${item}`), '')
);

const humanFileSizeString = computed<string | null>(() => {
    let fileSize = configData.value?.UploadMaxFilesize;

    if (props.maxFileSize) {
        fileSize = typeof props.maxFileSize === 'string' ? parseInt(props.maxFileSize) : props.maxFileSize;
    }

    return fileSize ? getHumanFileSize(fileSize) : null;
});

const { configData } = storeToRefs(useConfigStore());

watch(isUploading, (newVal) => {
    emit('update:uploading', newVal);
});

watch(
    () => props.initialFiles,
    () => {
        files.value =
            props.initialFiles?.map((x) => {
                return createFile({ status: 'exists', info: x });
            }) || [];
    }
);

const { setErrors, errors } = useField(
    toRef(props, 'name'),
    {},
    {
        label: toRef(props, 'label'),
        validateOnValueUpdate: props.validateOnValueUpdate,
    }
);

const resetErrors = () => {
    const allErrors = files.value.reduce((prev, item) => prev.concat(item.errors), [] as string[]);
    setErrors([...new Set(allErrors)]);
};

const uploadFile = async (file: VUploaderFile): Promise<void> => {
    const { isSuccess, errors: fileErrors, info } = await props.onFileUpload(file.file as File);
    if (!isSuccess && !isEmpty(fileErrors)) {
        if (typeof fileErrors === 'string') {
            file.errors.push(fileErrors);
        } else {
            file.errors = fileErrors;
        }
    } else {
        file.errors = [];
    }
    file.status = isSuccess ? 'uploaded' : 'failed';
    file.info = info;
    uploaderRef.value?.removeFile(file.file as File);
};

const onFileAdded = async (uploadFiles: readonly File[]) => {
    uploadFiles.forEach((file) => {
        files.value.push(createFile({ status: 'uploading', file: file }));
    });

    const tasks = files.value.filter((x) => x.status === 'uploading').map((f) => uploadFile(f));
    await Promise.all(tasks);
    resetErrors();
    emit('update:model-value', files.value);
};

const onRemoveFile = (file: VUploaderFile) => {
    files.value = files.value.filter((x) => x.id !== file.id);
    resetErrors();
    emit('update:model-value', files.value);
};

const onRejected = (rejectedEntries: QRejectedEntry[]) => {
    rejectedEntries.forEach((entry) => {
        files.value.push(
            createFile({
                status: 'failed',
                file: entry.file,
                errors: [
                    t(`validation.components.uploader.${entry.failedPropValidation}`, {
                        file: entry.file.name,
                    }),
                ],
            })
        );
    });

    resetErrors();
    emit('update:model-value', files.value);
};
</script>

<template>
    <div class="column items-stretch">
        <q-uploader
            v-bind="props"
            ref="uploaderRef"
            class="app-uploader col"
            :class="`app-uploader-${mode}`"
            :auto-upload="false"
            :max-file-size="props.maxFileSize ?? configData?.UploadMaxFilesize"
            hide-upload-btn
            @added="onFileAdded"
            @rejected="onRejected"
            :disable="props.disable || isUploading"
        >
            <template #header>
                <div class="row justify-center">
                    <slot name="header">
                        <div class="app-uploader--label q-ma-sm flex flex-center" :class="`text-${textColor}`">
                            {{ headerTitle }}
                            <template v-if="props.maxFileSize || configData">
                                ({{ $t('components.uploader.maxFileSize') }}: {{ humanFileSizeString }})
                            </template>
                        </div>
                    </slot>
                    <q-space />
                    <flat-btn v-if="canAddFiles" class="q-pr-sm" icon="las la-plus-circle" :text-color="textColor">
                        <q-tooltip>
                            {{ $t('components.uploader.addFile') }}
                        </q-tooltip>
                        <q-uploader-add-trigger />
                    </flat-btn>
                </div>
                <q-separator />
            </template>
            <template #list>
                <div v-if="files.length > 0" class="q-mt-xs">
                    <div v-if="canAddFiles" class="q-ma-xs row q-col-gutter-xs flex-center text-on-surface-1">
                        <q-icon name="las la-cloud-upload-alt" size="sm" />
                        <div>{{ $t('components.uploader.dndText') }}</div>
                    </div>
                    <div v-if="mode === 'chips'">
                        <v-uploader-file-chip v-for="f in files" :key="f.id" :file="f" @remove="onRemoveFile" />
                    </div>
                    <div
                        v-if="mode === 'thumbnails'"
                        class="row q-my-sm q-gutter-md"
                        :class="{
                            'justify-center': !multiple,
                        }"
                    >
                        <v-uploader-file-thumbnail v-for="f in files" :key="f.id" :file="f" @remove="onRemoveFile" />
                    </div>
                </div>
                <div v-else class="q-mx-md q-mb-sm q-mt-xs column flex-center text-on-surface-1">
                    <q-icon name="las la-cloud-upload-alt" size="lg" />
                    <div>{{ $t('components.uploader.dndText') }}</div>
                </div>
            </template>
        </q-uploader>
        <div class="q-field__bottom">
            <div
                v-if="errors.length > 0"
                class="row noflexwrap items-center hint-error--message text-negative q-my-none"
            >
                <q-icon class="q-mr-xs" name="las la-exclamation-circle" size="xs" />
                <div class="q-mr-xs">{{ errorBagMessage }}</div>
            </div>
        </div>
    </div>
</template>
