{
    "validation": {
        "messages": {
            "_default": "The {0} is invalid.",
            "alpha": "The {0} must only contain letters.",
            "alpha_num": "The {0} must only contain letters and numbers.",
            "alpha_dash": "The {0} must only contain letters, numbers, dashes and underscores.",
            "alpha_spaces": "The {0} must only contain letters, numbers and spaces",
            "between": "The {0} must be between {1} and {2}.",
            "confirmed": "The {0} confirmation does not match.",
            "digits": "The {0} must be {1} digits.",
            "dimensions": "The {0} has invalid image dimensions.",
            "email": "The {0} must be a valid email address.",
            "excluded": "The {0} is invalid.",
            "ext": "The {0} must be a valid file.",
            "image": "The {0} must be an image.",
            "integer": "The {0} must be an integer.",
            "length": "The {0} must be {1} characters.",
            "max_value": "The {0} must not be greater than {1}.",
            "max": "The {0} must not be greater than {1} characters.",
            "mimes": "The {0} must have a valid file type.",
            "min_value": "The {0} must be at least {1}.",
            "min": "The {0} must be at least {1} characters.",
            "numeric": "The {0} must be a number.",
            "one_of": "The selected {0} is invalid.",
            "regex": "The {0} format is invalid.",
            "required_if": "The {0} field is required.",
            "required": "The {0} field is required.",
            "size": "The {0} must not be greater than {1} kilobytes.",
            "url": "The {0} must be a valid URL.",
            "prohibitedUnless": "The {0} can only be filled in if {1} is filled.",
            "date": "The {0} is not a valid date.",
            "after": "The {0} must be a date after {1}.",
            "afterOrEqual": "The {0} must be a date after or equal to {1}.",
            "afterToday": "The {0} must be a date after or equal to today."
        },
        "fields": {
            "PasswordConfirm": {
                "confirmed": "Password does not match"
            }
        },
        "components": {
            "uploader": {
                "max-file-size": "File '%{file}' is too large",
                "max-total-size": "Maximum files size limit has been reached.",
                "accept": "File type for '%{file}' is not supported",
                "duplicate": "File '%{file}' has already been uploaded"
            }
        }
    }
}
