import { Guid } from 'guid-ts';
import { VUploaderFile } from './_types';

const icons: Record<VUploaderFile['status'], string> = {
    exists: 'las la-arrow-circle-down',
    failed: 'las la-exclamation-triangle',
    uploaded: 'las la-check',
    uploading: 'las la-arrow-circle-up',
};

const colors: Record<VUploaderFile['status'], string> = {
    exists: 'primary',
    failed: 'negative',
    uploaded: 'positive',
    uploading: 'primary',
};

export const getIconByStatus = (file: VUploaderFile): string => {
    return icons[file.status];
};

export const getColorByStatus = (file: VUploaderFile): string => {
    return colors[file.status];
};

export const getFileName = (file: VUploaderFile): string => {
    return file.info?.FileName || file.file?.name || '';
};

export const createFile = (file: Partial<VUploaderFile>): VUploaderFile => {
    return {
        id: file.id || Guid.newGuid().toString(),
        status: file.status || 'failed',
        file: file.file,
        info: file.info,
        errors: file.errors || [],
    };
};
