import { RouteWatchOptions } from '~/modules/core/composables/_types';
import useReportingReportStore from '~/modules/reporting/stores/reports';
import { fetchReportingReportDetail } from '~/modules/reporting/services/reports';
import { ReportDetail } from '~/modules/reporting/api/reports/_types';
import { FetchDetailOptions } from '~/modules/core/services/_types';

export const useRouteWatchReportingReportDetail = (routeLoaderOpt: RouteWatchOptions, opt?: FetchDetailOptions) => {
    const store = useReportingReportStore();
    const { activeReport } = storeToRefs(store);
    const activeReportProjectId = computed(() => activeReport.value?.Project.Id || null);
    const { isLoading, reload } = useRouteWatchApiData<ReportDetail, FetchDetailOptions>(
        async (id: number, opt?: FetchDetailOptions) => {
            return await fetchReportingReportDetail(id, opt);
        },
        routeLoaderOpt,
        opt
    );

    return {
        activeReport,
        activeReportProjectId,
        isLoading,
        reload,
    };
};

export const useWatchReportingReportDetail = (projectId: Ref<number | null>) => {
    const store = useReportingReportStore();
    const { activeReport } = storeToRefs(store);
    const { isLoading, reload } = useWatchApiData<ReportDetail, null>(projectId, async (id: number) => {
        return await fetchReportingReportDetail(id);
    });
    return {
        activeReport,
        isLoading,
        reload,
    };
};

export const useReportingReportDetail = () => {
    const store = useReportingReportStore();
    const { activeReport } = storeToRefs(store);
    const { isLoading, reload } = useApiDataWithArgs(async (id: number) => {
        return await fetchReportingReportDetail(id);
    });

    return {
        activeReport,
        isLoading,
        reload,
    };
};
