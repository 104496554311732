{
    "pages": {
        "planning": "Planning",
        "planning-new": "Planning - New project",
        "planning-id": "Planning - Overview",
        "planning-id-settings": "Planning - Settings",
        "planning-id-settings-section": "Planning - Settings"
    },
    "planning": {
        "settings": {
            "sections": {
                "workers": {
                    "title": "Workers settings",
                    "action": "Manage workers"
                }
            }
        },
        "workers": {
            "actions": {
                "create": "New Worker"
            },
            "table": {
                "columns": {
                    "fullName": "Name",
                    "function": "Position",
                    "issuesCount": "Tasks"
                },
                "prompts": {
                    "delete": {
                        "title": "Remove Worker",
                        "message": "Are you sure you want to remove this worker?",
                        "confirm": "Delete",
                        "cancel": "Cancel"
                    }
                }
            },
            "form": {
                "title": {
                    "new": "New Worker",
                    "edit": "Edit Worker"
                },
                "submit": {
                    "new": "Create Worker",
                    "edit": "Edit Worker"
                },
                "inputs": {
                    "FirstName": "First name",
                    "LastName": "Last name",
                    "Function": "Working position"
                }
            }
        },
        "issues": {
            "filter": {
                "DispatchingWorkerId": "Dispatching Worker",
                "PlanningWorkerId": "Planning Worker",
                "Function": "Position",
                "BeginAt": "Solve from",
                "FinishAt": "Solve to"
            },
            "gantt": {
                "actions": {
                    "addWorker": "Add Worker",
                    "addIssue": "Add Issue",
                    "deleteWorker": "Delete Worker",
                    "deleteIssue": "Delete",
                    "detailIssue": "Detail",
                    "editIssue": "Edit"
                },
                "daysCount": "{n} day |  {n} days | {n} days",
                "daysCountInfinity": "\u221e days",
                "never": "\u221e",
                "beginAt": "Solve from",
                "finishAt": "Solve to",
                "loading": "Loading ...",
                "noData": "No data available",
                "worker": "Worker"
            },
            "prompts": {
                "delete": {
                    "title": "Remove Issue",
                    "message": "Are you sure you want to delete this issue?",
                    "confirm": "Remove",
                    "cancel": "Cancel"
                }
            },
            "form": {
                "title": {
                    "new": "Create Issue",
                    "edit": "Edit Issue"
                },
                "submit": {
                    "new": "Create Issue",
                    "edit": "Edit Issue"
                },
                "inputs": {
                    "Name": "Issue name",
                    "WorkerId": "Worker",
                    "BeginAt": "Solve from",
                    "FinishAt": "Solve to",
                    "Body": "Issue description"
                }
            },
            "detail": {
                "title": "Issue detail",
                "titles": {
                    "Name": "Name",
                    "Worker": "Worker",
                    "BeginAt": "Solve from",
                    "FinishAt": "Solve to",
                    "Body": "Description"
                },
                "actions": {
                    "close": "Close"
                }
            }
        }
    }
}
