import { FetchDetailOptions } from '~/modules/core/services/_types';
import useProjectStore from '~/modules/core/stores/project';
import { fetchProjectDetail } from '~/modules/core/services/projects';
import { ProjectDetail } from '~/modules/core/api/projects/_types';
import { RouteWatchOptions, WatchOptions } from './_types';

export const useRouteWatchProjectDetail = (routeLoaderOpt: RouteWatchOptions, fetchOpt?: FetchDetailOptions) => {
    const store = useProjectStore();
    const { activeProject } = storeToRefs(store);
    const { isLoading, reload } = useRouteWatchApiData<ProjectDetail, FetchDetailOptions>(
        async (id: number, opt?: FetchDetailOptions) => {
            return await fetchProjectDetail(id, opt);
        },
        routeLoaderOpt,
        fetchOpt
    );

    return {
        activeProject,
        isLoading,
        reload,
    };
};

export const useWatchProjectDetail = (
    id: Ref<number | null>,
    fetchOpt?: FetchDetailOptions,
    watchOpt?: WatchOptions
) => {
    const store = useProjectStore();
    const { activeProject } = storeToRefs(store);
    const { isLoading, reload } = useWatchApiData<ProjectDetail, FetchDetailOptions>(
        id,
        async (id: number, opt?: FetchDetailOptions) => {
            return await fetchProjectDetail(id, opt);
        },
        null,
        fetchOpt,
        watchOpt
    );

    return {
        activeProject,
        isLoading,
        reload,
    };
};

export const useProjectDetail = (fetchOpt?: FetchDetailOptions) => {
    const store = useProjectStore();
    const { activeProject } = storeToRefs(store);
    const { isLoading, reload } = useApiDataWithArgs(async (id: number, opt?: FetchDetailOptions) => {
        return await fetchProjectDetail(id, opt);
    }, fetchOpt);

    return {
        activeProject,
        isLoading,
        reload,
    };
};
