import usePermissionProject from '~/modules/core/composables/permissions/usePermissionsProject';
import usePermissionSubject from '~/modules/core/composables/permissions/usePermissionsSubject';
import { RoutePagePemissions } from '~/plugins/router/_types';

export const permissions = (): RoutePagePemissions => {
    const { canCreateAnyProject } = usePermissionSubject();
    const { canManageAny } = usePermissionProject();

    return {
        'settings-projects': canManageAny,
        'settings-projects-new': canCreateAnyProject,
    };
};
