import { RouteLocationNormalized } from 'vue-router';
import { RoutePagePemissions } from '~/plugins/router/_types';
import usePermissionDispatchingEnum from '~/modules/dispatching/composables/permissions/usePermissionsDispatchingEnum';
import usePermissionDispatchingIssue from '~/modules/dispatching/composables/permissions/usePermissionsDispatchingIssue';
import usePermissionProject from '~/modules/core/composables/permissions/usePermissionsProject';

export const permissions = (): RoutePagePemissions => {
    const { getProjectDetailFromRoute } = usePermissionProject();

    const { canManageEnums } = usePermissionDispatchingEnum();
    const { canCreateIssue } = usePermissionDispatchingIssue();

    const canGoSettings = async (to: RouteLocationNormalized) => {
        const project = await getProjectDetailFromRoute(to);
        return canManageEnums(project);
    };

    const canGoNewIssue = async (to: RouteLocationNormalized) => {
        const project = await getProjectDetailFromRoute(to);
        return canCreateIssue(project);
    };

    return {
        'dispatching-id-new': canGoNewIssue,
        'dispatching-id-settings': canGoSettings,
    };
};
