import { ApiResponseTransformer } from '~/plugins/apiClient/_types';
import { AppSearchResult, AppSearchLabelInfo, AppSearchProjectInfo, AppSearchSubjectInfo } from './_types';

export const rawToAppSearchSubjectInfo: ApiResponseTransformer<AppSearchSubjectInfo> = (data) => {
    return {
        _kind: 'subject',
        Id: data.Id,
        Name: data.Name,
        NameShort: data.NameShort,
    };
};

export const rawToAppSearchProjectInfo: ApiResponseTransformer<AppSearchProjectInfo> = (data) => {
    return {
        _kind: 'project',
        Id: data.Id,
        Name: data.Name,
        ModuleKey: data.ModuleKey,
    };
};

export const rawToAppSearchLabelInfo: ApiResponseTransformer<AppSearchLabelInfo> = (data) => {
    return {
        _kind: 'label',
        Id: data.Id,
        Name: data.Name,
    };
};

export const rawToAppSearchResult: ApiResponseTransformer<AppSearchResult | null> = (data) => {
    if (data == null) return null;
    return {
        Subjects: Array.isArray(data.Subjects) ? data.Subjects.map((x: any) => rawToAppSearchSubjectInfo(x)) : [],
        Projects: Array.isArray(data.Projects) ? data.Projects.map((x: any) => rawToAppSearchProjectInfo(x)) : [],
        Labels: Array.isArray(data.Labels) ? data.Labels.map((x: any) => rawToAppSearchLabelInfo(x)) : [],
    };
};
