<script lang="ts" setup>
import { AppSearchProjectInfo } from '~/modules/core/api/search/_types';
import { getIconNameByModuleKey } from '~/modules/core/utils/iconsUtils';
import { AppSearchBarResultProjectsProps } from './_types';

defineProps({
  "projects": null
});
const emit = defineEmits(["click"]);

const onClick = (data: AppSearchProjectInfo) => {
    emit('click', data);
};
</script>
<template>
    <template v-if="projects.length">
        <q-item dense>
            <q-item-section>
                <q-item-label class="text-bold text-on-surface-1">{{ $t('search.groups.projects') }}</q-item-label>
            </q-item-section>
        </q-item>
        <q-separator />
        <q-item v-for="p in projects" :key="p.Id" clickable @click="() => onClick(p)">
            <q-item-section avatar style="min-width: auto">
                <q-icon :name="getIconNameByModuleKey(p.ModuleKey)" />
            </q-item-section>
            <q-item-section>
                <q-item-label class="text-bold">
                    {{ p.Name }}
                </q-item-label>
                <q-item-label caption>
                    {{ $t(`modules.${p.ModuleKey}`) }}
                </q-item-label>
            </q-item-section>
            <q-item-section side>
                <q-icon name="las la-angle-right" color="secondary" />
            </q-item-section>
        </q-item>
    </template>
</template>
