import { useAuthStore } from '~/plugins/auth';
import { AuthUser } from '~/plugins/auth/_types';
import { ProjectDetail } from '~/modules/core/api/projects/_types';
import { SubjectInfo } from '~/modules/core/api/subjects/_types';
import { ModuleKey } from '~/modules/core/enums/_types';
import { PermissionOptions } from './_types';

const usePermissions = () => {
    const { user } = storeToRefs(useAuthStore());

    const _canPermission = (
        canPermissions: string[],
        permissions: Set<string> | null,
        opt?: PermissionOptions
    ): boolean => {
        if (permissions == null) return false;

        return opt?.strict
            ? canPermissions.every((x) => permissions.has(x))
            : canPermissions.some((x) => permissions.has(x));
    };

    const _canUser = (permissionFn: (user: AuthUser) => boolean): boolean => {
        if (user.value == null) return false;
        if (user.value.IsAdmin) return true;

        return permissionFn(user.value);
    };

    const can = (permissions: string[], opt?: PermissionOptions): boolean => {
        return _canUser((user) => {
            return canPermission(permissions, user.Permissions, opt);
        });
    };

    const canPermission = (canPermissions: string[], permissions: Set<string> | null, opt?: PermissionOptions) => {
        return _canUser(() => {
            return _canPermission(canPermissions, permissions, opt);
        });
    };

    const canSubject = (permissions: string[], subject: SubjectInfo | null, opt?: PermissionOptions): boolean => {
        return _canUser(() => {
            return canPermission(permissions, subject?.Permissions || new Set<string>(), opt);
        });
    };

    const canProject = (permissions: string[], project: ProjectDetail | null, opt?: PermissionOptions): boolean => {
        return _canUser(() => {
            const allProjectPermissions = project?.Subjects.reduce((p, currVal) => {
                return [...p, ...currVal.Permissions];
            }, [] as string[]);
            return canPermission(permissions, new Set<string>(allProjectPermissions) || new Set<string>(), opt);
        });
    };

    const canProjectForSubject = (
        permissions: string[],
        project: ProjectDetail | null,
        subjectId: number,
        opt?: PermissionOptions
    ): boolean => {
        return _canUser(() => {
            return canPermission(
                permissions,
                project?.Subjects.find((x) => x.Subject.Id === subjectId)?.Permissions || new Set<string>(),
                opt
            );
        });
    };

    const canModule = (permissions: string[], module: ModuleKey, opt?: PermissionOptions): boolean => {
        return _canUser(() => {
            return canPermission(
                permissions,
                user.value?.Modules.find((x) => x.Key === module)?.Permissions || new Set<string>(),
                opt
            );
        });
    };

    return {
        user,
        can,
        canSubject,
        canProject,
        canProjectForSubject,
        canModule,
        canPermission,
    };
};

export default usePermissions;
