<script lang="ts" setup>
import localeStorage from '~/plugins/i18n/store';
import { Locale } from '~/plugins/i18n/_types';

const { activeLocale, availableLocales, localeDisplayNames } = localeStorage;

const onLocaleChanged = (newLocale: Locale) => {
    activeLocale.value = newLocale;
    // @plachtova: this is not the best solution, but is it a solution on one line to ensure full localization changes
    location.reload();
};
</script>
<template>
    <q-list>
        <q-item
            v-for="l in availableLocales"
            :key="l"
            clickable
            @click="onLocaleChanged(l)"
            :active="l === activeLocale"
        >
            <q-item-section>
                {{ localeDisplayNames?.of(l) }}
            </q-item-section>
        </q-item>
    </q-list>
</template>
