export const useApiData = <TData, TOptions>(
    fetchDataCallback: (opt?: TOptions) => Promise<{ isSuccess: boolean; data: TData | null }>,
    fetchOpt?: TOptions
) => {
    const {
        isLoading,
        data,
        reload: reloadArgs,
    } = useApiDataWithArgs<null, TData, TOptions>((_, opt) => fetchDataCallback(opt), fetchOpt);

    const reload = async (opt?: TOptions, delay?: number) => {
        return await reloadArgs(null, opt, delay);
    };

    return {
        data,
        isLoading,
        reload,
    };
};

export const useApiDataWithArgs = <TArgs, TData, TOptions>(
    fetchDataCallback: (args: TArgs, opt?: TOptions) => Promise<{ isSuccess: boolean; data: TData | null }>,
    fetchOpt?: TOptions
) => {
    const data = ref<TData | null>();

    const { isLoading, execute } = useAsyncState(
        async (args: TArgs, opt?: TOptions) => {
            const res = await fetchDataCallback(args, opt);
            data.value = res.data;

            return res;
        },
        { isSuccess: false, data: null },
        {
            immediate: false,
        }
    );

    const reload = async (
        args: TArgs,
        opt?: TOptions,
        delay?: number
    ): Promise<{ isSuccess: boolean; data: TData | null }> => {
        return await execute(delay, args, opt || fetchOpt);
    };

    return {
        data,
        isLoading,
        reload,
    };
};
