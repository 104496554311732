import { HttpStatusCode } from 'axios';
import { SubmissionContext } from 'vee-validate';
import { Router } from 'vue-router';
import { ApiErrors, ApiGenericValues, ApiStatusCode } from '~/plugins/apiClient/_types';
import { goToAccessDeniedPage, goToNotFoundPage } from '~/plugins/router/utils';
import { FetchCollectionOptions, FetchDetailOptions, RedirectOptions, StoreOptions } from './_types';

export const redirectIfApiError = async (
    router: Router,
    status: ApiStatusCode,
    opt?: RedirectOptions
): Promise<boolean> => {
    if (status === HttpStatusCode.NotFound && opt?.redirectIfNotFound) {
        await goToNotFoundPage(router);
        return true;
    }

    if (status === HttpStatusCode.Forbidden && opt?.redirectIfAccessDenied) {
        await goToAccessDeniedPage(router);
        return true;
    }

    return false;
};

export const fetchAndStoreCollection = async <TArgs, TData>(
    getDataFromStoreFn: () => Ref<TData[] | null>,
    fetchDataFn: (args?: TArgs | null) => Promise<{ isSuccess: boolean; status: ApiStatusCode; data: TData[] | null }>,
    args?: TArgs | null,
    opt?: FetchCollectionOptions
): Promise<{ isSuccess: boolean; data: TData[] | null }> => {
    const storeData = getDataFromStoreFn();

    if (storeData.value != null && !opt?.force && !opt?.noStore) {
        return { isSuccess: true, data: storeData.value };
    }

    const { isSuccess, data } = await fetchDataFn(args);

    if (isSuccess && !opt?.noStore) {
        storeData.value = data;
    }

    return { isSuccess, data };
};

export const fetchAndStoreData = async <TData extends { Id: number; DisplayId?: number }>(
    id: number,
    router: Router,
    getDataFromStoreFn: () => Ref<TData | null>,
    fetchDataFn: (id: number) => Promise<{ isSuccess: boolean; status: ApiStatusCode; data: TData | null }>,
    opt?: FetchDetailOptions,
    storeOpt?: StoreOptions
): Promise<{ isSuccess: boolean; data: TData | null }> => {
    const storeData = getDataFromStoreFn();
    const idLabel = storeOpt?.useDisplayId ? 'DisplayId' : 'Id';
    if (storeData.value && storeData.value[idLabel] === id && !opt?.force && !opt?.noStore) {
        return { isSuccess: true, data: storeData.value };
    }

    const { isSuccess, data, status } = await fetchDataFn(id);

    if (!opt?.noStore && !router.currentRoute.value.name?.toString().startsWith(opt?.routeNamePrefix ?? '')) {
        return { isSuccess: false, data: null };
    }

    if (isSuccess && !opt?.noStore) {
        storeData.value = data;
    } else {
        await redirectIfApiError(router, status, opt);
    }

    return { isSuccess, data };
};

/**
 * @param args
 * @param values
 * @param setErrors
 */
export const updateAndStoreFormData: <TArgs, TValues extends ApiGenericValues, TData>(
    args: TArgs,
    values: TValues,
    ctx: SubmissionContext<TValues>,
    getDataFromStoreFn: () => Ref<TData | null>,
    updateDataFn: (
        args: TArgs,
        values: TValues
    ) => Promise<{ isSuccess: boolean; isValidationError: boolean; errors: ApiErrors<TValues>; data: TData | null }>,
    transformErrors?: (errors: ApiErrors<TValues>) => ApiErrors<TValues>
) => Promise<{ isSuccess: boolean; data: TData | null }> = async (
    args,
    values,
    { setErrors },
    getDataFromStoreFn,
    updateDataFn,
    transformErrors
) => {
    const storeData = getDataFromStoreFn();
    const { data, isSuccess, isValidationError, errors } = await updateDataFn(args, values);

    if (isSuccess) {
        storeData.value = data;
    } else if (isValidationError) {
        setErrors(transformErrors ? transformErrors(errors) : errors);
    }

    return { isSuccess, isValidationError, data };
};
