<script lang="ts" setup>
import { getColorByStatus, getFileName, getIconByStatus } from './uploaderUtils';
import { VUploaderFile, VUploaderFileProps } from './_types';

const props = defineProps({
  "file": null
});
const emit = defineEmits(["remove"]);

const fileName = computed(() => getFileName(props.file));
const onRemove = () => {
    emit('remove', props.file);
};
</script>

<template>
    <q-card
        class="relative-position"
        :class="`app-uploader-thumbnails--card__${file.status}`"
        style="min-width: 200px; max-width: 200px"
        bordered
        flat
    >
        <q-img
            v-if="file.status === 'uploaded' || file.status === 'exists'"
            no-spinner
            fit="contain"
            height="100px"
            :src="file.info?.FileUrl"
        >
            <template #error>
                <div class="flex flex-center full-width" style="height: 100px">
                    <q-icon name="las la-file-alt" size="lg" />
                </div>
            </template>
        </q-img>
        <div v-else class="flex flex-center relative-position" style="height: 100px">
            <q-icon :name="getIconByStatus(file)" :color="getColorByStatus(file)" size="lg" />
            <q-inner-loading :showing="file.status === 'uploading'" color="primary" />
        </div>
        <q-separator />
        <q-card-section class="q-pa-sm">
            <div class="flex item-stretch justify-stretch">
                <span class="ellipsis non-selectable">
                    {{ fileName }}
                    <q-tooltip>
                        {{ fileName }}
                    </q-tooltip>
                </span>
            </div>
        </q-card-section>
        <contained-btn
            class="app-uploader-thumbnails--close"
            round
            size="sm"
            icon="las la-times"
            dense
            color="secondary"
            text-color="on-secondary"
            @click="onRemove"
        />
    </q-card>
</template>
