{
    "pages": {
        "statistics": "Statistics",
        "statistics-new": "Statistics - New Project",
        "statistics-id": "Statistics - Overview",
        "statistics-id-settings": "Statistics - Settings",
        "statistics-id-settings-section": "Statistics - Settings",
        "statistics-id-new-product": "Statistics - New Product",
        "statistics-id-new-dashboard": "Statistics - New Dashboard",
        "statistics-products-id": "Statistics - Product Detail",
        "statistics-dashboards-id": "Statistics - Dashboard Detail"
    },
    "statistics": {
        "widgets": {
            "masterProduct": {
                "title": "Statistics products"
            }
        },
        "historyPeriod": {
            "d": "Day",
            "w": "Week",
            "m": "Month",
            "q": "Quarter",
            "y": "Year"
        },
        "settings": {
            "sections": {
                "inventories": {
                    "title": "Inventory Settings",
                    "action": "Manage inventories",
                    "notify": {
                        "success": "Form was successfully saved"
                    }
                }
            }
        },
        "new": {
            "masterProduct": {
                "title": "Product details"
            },
            "dashboard": {
                "title": "Dasboard details"
            }
        },
        "form": {
            "inventories": {
                "title": "Inventories Settings",
                "submit": "Save Settings"
            },
            "masterProduct": {
                "inputs": {
                    "Name": "Name",
                    "AmountMinimal": "Minimal Amount",
                    "AmountOptimal": "Optimal Amount",
                    "AmountMaximal": "Maximal Amount"
                },
                "searchProducts": {
                    "searchTitle": "Search products",
                    "searchNoData": "No products found",
                    "selectedTitle": "Selected products",
                    "selectedNoData": "No selected products",
                    "input": "Name/EAN",
                    "columns": {
                        "Name": "Name",
                        "Subject": "Subject",
                        "Inventory": "Inventory",
                        "Ean": "EAN",
                        "Amount": "Amount"
                    }
                },
                "submit": {
                    "new": "Create Product",
                    "edit": "Edit Product"
                }
            },
            "dashboard": {
                "inputs": {
                    "Name": "Name"
                },
                "searchProducts": {
                    "searchTitle": "Search products",
                    "searchNoData": "No products found",
                    "selectedTitle": "Selected products",
                    "selectedNoData": "No selected products",
                    "input": "Name",
                    "columns": {
                        "Name": "Name",
                        "Amount": "Amount"
                    }
                },
                "submit": {
                    "new": "Create Dashboard"
                }
            }
        },
        "overview": {
            "actions": {
                "newDashboard": "Create dashboard",
                "newProduct": "Add Product"
            },
            "dashboards": {
                "title": "Dashboards"
            },
            "table": {
                "columns": {
                    "name": "Name",
                    "amount": "Current Amount",
                    "amountMinimal": "Minimal Amount",
                    "amountOptimal": "Optimal Amount",
                    "amountMaximal": "Maximal Amount",
                    "state": "State"
                },
                "actions": {
                    "detail": "detail"
                }
            }
        },
        "detail": {
            "masterProduct": {
                "title": "Product Detail",
                "actions": {
                    "goBackOverview": "Back to Overview",
                    "editProduct": "Edit Product",
                    "deleteProduct": "Delete Product"
                }
            },
            "dashboard": {
                "title": "Dashboard detail",
                "actions": {
                    "goBackOverview": "Back to Overview",
                    "deleteDashboard": "Delete dashboard"
                }
            }
        },
        "charts": {
            "noData": "No data available to show graph",
            "emptyDataLabel": "Not Specified",
            "masterProduct": {
                "product": {
                    "tabs": {
                        "Inventory": "Inventory",
                        "Subject": "Subject",
                        "Expiration": "Expiration"
                    }
                },
                "history": {
                    "tabs": {
                        "IncomeOutcome": "Income/Outcome",
                        "Consuption": "Consuption"
                    },
                    "labels": {
                        "Income": "Income",
                        "Outcome": "Outcome"
                    },
                    "inputs": {
                        "period": "Period",
                        "from": "From",
                        "to": "To"
                    }
                }
            }
        },
        "prompts": {
            "masterProduct": {
                "delete": {
                    "title": "Delete Product",
                    "message": "Do you really want to delete this product?",
                    "confirm": "Delete",
                    "cancel": "Cancel"
                }
            },
            "dashboard": {
                "delete": {
                    "title": "Delete Dashboard",
                    "message": "Do you really want to delete this dashboard?",
                    "confirm": "Delete",
                    "cancel": "Cancel"
                }
            }
        }
    }
}
