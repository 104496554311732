{
    "pages": {
        "reporting": "Hlášení",
        "reporting-new": "Hlášení - Nový projekt",
        "reporting-id": "Hlášení",
        "reporting-id-new-report": "Nové Hlášení",
        "reporting-reports-id": "Hlášení",
        "reporting-reports-id-new-report": "Nové Hlášení",
        "reporting-id-settings": "Hlášení - Nastavení",
        "reporting-id-settings-section": "Hlášení - Nastavení"
    },
    "reporting": {
        "model": {
            "reportingDataItem": {
                "boolean": {
                    "true": "Ano",
                    "false": "Ne"
                }
            }
        },
        "overview": {
            "table": {
                "columns": {
                    "name": "Název hlášení",
                    "interventionAt": "Datum hlášení",
                    "creatorFullName": "Autor hlášení"
                },
                "actions": {
                    "detail": "detail"
                }
            },
            "noData": {
                "noReports": "Žádná hlášení?",
                "howItHappened": "Vůbec si nedokážeme vysvětlit, jak se to mohlo stát!",
                "createReport": "Vytvořit hlášení"
            }
        },
        "detail": {
            "title": "Detail hlášení",
            "project": "Projekt",
            "backToOverview": "Zpět na přehled",
            "exportPdf": "Exportovat do PDF",
            "attachmentsTitle": "Přílohy",
            "component": {
                "name": "Název hlášení",
                "interventionAt": "Datum hlášení",
                "recipients": "Příjemci hlášení",
                "sender": "Odesílatel hlášení",
                "author": "Autor hlášení"
            }
        },
        "new": {
            "title": "Nové hlášení",
            "form": {
                "inputs": {
                    "templateOfReport": "Šablona",
                    "recipientsOfReport": "Příjemci",
                    "titleOfReport": "Název",
                    "interventionAt": "Datum zásahu"
                },
                "buttons": {
                    "sendReport": "Odeslat Hlášení",
                    "cancel": "Zahodit"
                }
            }
        }
    }
}
