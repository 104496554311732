<script lang="ts" setup>
import { getCssVar, QBtnSlots } from 'quasar';
import { LinkBtnProps } from './_types';

const props = defineProps({
  "label": null,
  "textColor": { default: 'primary' },
  "dense": null,
  "align": null,
  "stretch": null,
  "disable": null,
  "padding": null,
  "size": null,
  "type": null,
  "to": null,
  "loading": null,
  "round": null,
  "onClick": null,
  "activeColor": { default: 'secondary' }
});
const activeColor = computed(() => getCssVar(props.activeColor || '') || props.activeColor);
const textColor = computed(() => getCssVar(props.textColor || '') || props.textColor);
</script>
<template>
    <q-btn
        v-bind="props"
        class="app-btn app-btn-link app-btn--no-q-hover text-bold"
        flat
        :text-color="textColor"
        :style="{ '--app-btn-link--text-color': textColor, '--app-btn-link--active-color': activeColor }"
        no-caps
        :ripple="false"
    >
        <template v-for="(_, slotName) in ($slots as Readonly<QBtnSlots>)" #[slotName]="slotProps">
            <slot :name="slotName" v-bind="((slotProps || {}) as any)" />
        </template>
    </q-btn>
</template>
