import { TextNote } from '~/modules/core/api/textNotes/_types';
import { Checklist } from '~/modules/core/api/checklists/_types';

export enum NoteTypes {
    textNotes = 'textNotes',
    checklists = 'checklists',
}

export interface NotesProps {
    modalOpen: boolean;
    type: NoteTypes;
    formOpen: boolean;
    items: Array<TextNote | Checklist>;
    activeItem: TextNote | Checklist | null;
    disableLoad: boolean;
    isLoading: boolean;
    setModalOpen: (open: boolean) => void;
    setFormOpen: (open: boolean) => void;
    loadItems: (data: { index: number; done?: (stop?: boolean) => void }) => void;
    setActiveItem: (item: TextNote | Checklist | null) => void;
    deleteItem: (item: Checklist | TextNote) => Promise<void>;
}

export const isChecklist = (item: Checklist | TextNote): item is Checklist => {
    return 'Items' in item;
};

export const isTextNote = (item: Checklist | TextNote): item is TextNote => {
    return 'Body' in item && 'Attachments' in item;
};
