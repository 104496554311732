import { RouteLocationNormalized } from 'vue-router';
import usePermissions from './usePermissions';
import { ProjectPermission } from '~/modules/core/enums/permissions/_types';
import { ProjectDetail, ProjectSubjectInfo } from '~/modules/core/api/projects/_types';
import { ModuleKey } from '~/modules/core/enums/_types';

const usePermissionProject = () => {
    const { can, canProject, canModule, canPermission } = usePermissions();
    const { reload: reloadProject } = useProjectDetail();

    const canEditProject = (project: ProjectDetail | null): boolean => {
        return canProject([ProjectPermission.Update], project);
    };

    const canDeleteAnyProject = (): boolean => {
        return can([ProjectPermission.Delete]);
    };

    const canDetailProject = (project: ProjectDetail | null): boolean => {
        return canProject([ProjectPermission.Detail], project);
    };

    const canDetailProjectModule = (module: ModuleKey): boolean => {
        return canModule([ProjectPermission.Detail], module);
    };

    const canUserManageProject = (project: ProjectDetail | null): boolean => {
        return canProject([ProjectPermission.UserManage], project);
    };

    const canUserManageProjectSubject = (projectSubject: ProjectSubjectInfo | null): boolean => {
        return canPermission([ProjectPermission.UserManage], projectSubject?.Permissions || null);
    };

    const canInviteSubjectsProject = (project: ProjectDetail | null): boolean => {
        return canProject([ProjectPermission.InviteSubject], project);
    };

    const canManage = (project: ProjectDetail | null): boolean => {
        return canProject([ProjectPermission.Update, ProjectPermission.Delete, ProjectPermission.UserManage], project);
    };

    const canManageAny = (): boolean => {
        return can([ProjectPermission.Update, ProjectPermission.Delete, ProjectPermission.UserManage]);
    };

    const filterUserManageSubjects = (projects: ProjectSubjectInfo[]) => {
        const p = [ProjectPermission.UserManage];
        return projects.filter((x) => canPermission(p, x.Permissions));
    };

    const getProjectDetailFromRoute = async (to: RouteLocationNormalized): Promise<ProjectDetail | null> => {
        const id = +to.params.id;

        if (!id) null;

        const { data } = await reloadProject(id);

        return data;
    };

    return {
        canEditProject,
        canDetailProject,
        canDetailProjectModule,
        canUserManageProject,
        canUserManageProjectSubject,
        canInviteSubjectsProject,
        filterUserManageSubjects,
        canManage,
        canManageAny,
        getProjectDetailFromRoute,
        canDeleteAnyProject,
    };
};

export default usePermissionProject;
