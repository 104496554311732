{
    "validation": {
        "messages": {
            "_default": "Hodnota není platná.",
            "alpha": "Hodnota může obsahovat pouze písmena.",
            "alpha_num": "Hodnota může obsahovat pouze písmena a číslice.",
            "alpha_dash": "Hodnota může obsahovat pouze písmena, číslice, pomlčky a podtržítka. České znaky (á, é, í, ó, ú, ů, ž, š, č, ř, ď, ť, ň) nejsou podporovány.",
            "alpha_spaces": "Hodnota může obsahovat pouze písmena, číslice a mezery.",
            "between": "Hodnota musí být mezi {1} a {2}.",
            "confirmed": "Hodnota nesouhlasí.",
            "digits": "Hodnota musí být {1} pozic dlouhá.",
            "dimensions": "Hodnota má neplatné rozměry.",
            "email": "Hodnota nemá platný formát emailové adresy.",
            "excluded": "Hodnota není platná.",
            "ext": "Hodnota musí být soubor.",
            "image": "Hodnota musí být obrázek.",
            "integer": "Hodnota musí být celé číslo.",
            "length": "Hodnota musí být přesně {1} znaků dlouhá.",
            "max_value": "Hodnota nemůže být větší než {1}.",
            "max": "Hodnota nemůže být delší než {1} znaků.",
            "mimes": "Hodnota musí být správný datový typ.",
            "min_value": "Hodnota musí být větší než {1}.",
            "min": "Hodnota musí být delší než {1} znaků.",
            "numeric": "Hodnota musí být číslo.",
            "one_of": "Zvolená hodnota je neplatná.",
            "regex": "Hodnota nemá správný formát.",
            "required": "Pole je povinné.",
            "required_if": "Pole je povinné.",
            "size": "Hodnota musí být menší než {1}KB",
            "url": "Formát je neplatný.",
            "prohibitedUnless": "Pole může být vyplněno pouze pokud {1} je vyplněno.",
            "date": "Hodnota musí být platné datum.",
            "after": "Hodnota musí být datum po {1}.",
            "afterOrEqual": "Hodnota musí být datum {1} nebo pozdější.",
            "afterToday": "Hodnota musí být dnešní datum nebo pozdější."
        },
        "fields": {
            "PasswordConfirm": {
                "confirmed": "Heslo se neshoduje"
            }
        },
        "components": {
            "uploader": {
                "max-file-size": "Velikost souboru '%{file}' přesáhla povolenou maximální velikost.",
                "max-total-size": "Byl dosažen limit na celkovou velikost souborů.",
                "accept": "Typ souboru pro '%{file}' není podporovaný.",
                "duplicate": "Soubor '%{file}' už byl nahrán."
            }
        }
    }
}
