{
    "pages": {
        "planning": "Kapacitní plánování",
        "planning-new": "Kapacitní plánování - Nový projekt",
        "planning-id": "Kapacitní plánování - Přehled",
        "planning-id-settings": "Kapacitní plánování - Nastavení",
        "planning-id-settings-section": "Kapacitní plánování - Nastavení"
    },
    "planning": {
        "settings": {
            "sections": {
                "workers": {
                    "title": "Nastavení pracovníků",
                    "action": "Spravovat pracovníky"
                }
            }
        },
        "workers": {
            "actions": {
                "create": "Nový pracovník"
            },
            "table": {
                "columns": {
                    "fullName": "Jméno",
                    "function": "Pozice",
                    "issuesCount": "Úkoly"
                },
                "prompts": {
                    "delete": {
                        "title": "Odstranit pracovníka",
                        "message": "Opravdu chcete odstranit tohoto pracovníka?",
                        "confirm": "Odstranit",
                        "cancel": "Zrušit"
                    }
                }
            },
            "form": {
                "title": {
                    "new": "Nový pracovník",
                    "edit": "Upravit pracovníka"
                },
                "submit": {
                    "new": "Založit pracovníka",
                    "edit": "Upravit pracovníka"
                },
                "inputs": {
                    "FirstName": "Jméno",
                    "LastName": "Příjmení",
                    "Function": "Pracovní pozice"
                }
            }
        },
        "issues": {
            "filter": {
                "DispatchingWorkerId": "Pracovník dispečinku",
                "PlanningWorkerId": "Pracovník plánování",
                "Function": "Funkce",
                "BeginAt": "Řešit od",
                "FinishAt": "Vyřešit do"
            },
            "gantt": {
                "actions": {
                    "addWorker": "Přidat pracovníka",
                    "addIssue": "Přidat úkol",
                    "deleteWorker": "Smazat pracovníka",
                    "deleteIssue": "Smazat",
                    "detailIssue": "Detail",
                    "editIssue": "Upravit"
                },
                "daysCount": "{n} den |  {n} dny | {n} dní",
                "daysCountInfinity": "\u221e dní",
                "never": "\u221e",
                "beginAt": "Řešit od",
                "finishAt": "Vyřešit do",
                "loading": "Načítá se ...",
                "noData": "Žádná data k dispozici",
                "worker": "Pracovník"
            },
            "prompts": {
                "delete": {
                    "title": "Odstranit úkol",
                    "message": "Opravdu chcete odstranit tento úkol?",
                    "confirm": "Odstranit",
                    "cancel": "Zrušit"
                }
            },
            "form": {
                "title": {
                    "new": "Nový úkol",
                    "edit": "Upravit úkol"
                },
                "submit": {
                    "new": "Vytvořit úkol",
                    "edit": "Upravit úkol"
                },
                "inputs": {
                    "Name": "Název úkolu",
                    "WorkerId": "Pracovník",
                    "BeginAt": "Řešit od",
                    "FinishAt": "Vyřešit do",
                    "Body": "Popis úkolu"
                }
            },
            "detail": {
                "title": "Detail úkolu",
                "titles": {
                    "Name": "Název",
                    "Worker": "Pracovník",
                    "BeginAt": "Řešit od",
                    "FinishAt": "Vyřešit do",
                    "Body": "Popis"
                },
                "actions": {
                    "close": "Zavřít"
                }
            }
        }
    }
}
