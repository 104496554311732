<script lang="ts" setup>
import { AppSearchInfo } from '~/modules/core/api/search/_types';
import { AppSearchBarResultProps } from './_types';

defineProps({
  "results": null
});

const emit = defineEmits(["click"]);

const onClick = (result: AppSearchInfo): void => {
    emit('click', result);
};
</script>
<template>
    <q-list style="max-width: 400px">
        <app-search-bar-result-subjects :subjects="results.Subjects" @click="onClick" />
        <app-search-bar-result-projects :projects="results.Projects" @click="onClick" />
        <app-search-bar-result-labels :labels="results.Labels" @click="onClick" />
    </q-list>
</template>
