<script setup lang="ts"></script>
<template>
    <div>
        <q-separator class="q-my-sm q-mx-none" inset color="on-surface-3" />
        <div class="row inline justify-center items-center full-width q-pb-sm text-center text-on-surface-1">
            &copy; 2023 {{ $t('app.title') }} &nbsp; Created by
            <flat-btn class="q-pa-none q-pl-xs text-weight-regular" href="https://www.awebsys.cz" target="_blank">
                A-WebSys
            </flat-btn>
        </div>
    </div>
</template>
