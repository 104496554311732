import { omit } from 'lodash-es';
import { FetchListType } from '~/modules/core/enums/_types';
import {
    ProjectApiFormData,
    ProjectDetail,
    ProjectFormData,
    ProjectInfo,
    ProjectInvitationFormData,
    ProjectSubjectBindInfo,
    ProjectSubjectUnassignedUserInfo,
    ProjectTemplateInfo,
    ProjectUserDetail,
    ProjectUserFormData,
    ProjectUserInfo,
} from '~/modules/core/api/projects/_types';
import {
    deleteProjectFavoriteRequest,
    deleteProjectRequest,
    deleteProjectSubjectBindingRequest,
    deleteProjectUserRequest,
    fetchProjectDetailRequest,
    fetchProjectsRequest,
    fetchProjectTemplatesRequest,
    fetchProjectUnassignedUsersRequest,
    fetchProjectUserDetailRequest,
    fetchProjectUsersRequest,
    patchProjectSubjectBindingAcceptRequest,
    patchProjectUserRequest,
    postProjectFavoriteRequest,
    postProjectInvitationsRequest,
    postProjectRequest,
    postProjectUserRequest,
    putProjectRequest,
} from '~/modules/core/api/projects';
import { fetchSimpleProjectsRequest } from '~/modules/core/api/enums';
import { ApiErrors, FormHandler, FormHandlerWithArgs } from '~/plugins/apiClient/_types';
import useProjectStore from '~/modules/core/stores/project';
import { FetchDetailOptions } from '~/modules/core/services/_types';
import { fetchAndStoreData, updateAndStoreFormData } from '~/modules/core/services/utils';
import routerInstance from '~/plugins/router';
import { LabelSimpleInfo } from '~/modules/core/api/enums/_types';
import { pushErrorToArray, transformApiErrors } from '~/modules/core/utils/validationUtils';
import { FetchListOptions, PaginationMeta } from '~/modules/core/api/_types';
import { ProjectSimpleInfo } from '~/modules/core/api/enums/_types';

/**
 * @param type
 * @param opt
 */
export const fetchProjects = async (
    type: FetchListType,
    opt?: FetchListOptions
): Promise<{ isSuccess: boolean; data: ProjectInfo[] | null; meta: PaginationMeta | null }> => {
    return await fetchProjectsRequest({ type, opt });
};

export const fetchEnumsProjects = async (
    opt?: FetchListOptions
): Promise<{ isSuccess: boolean; data: ProjectSimpleInfo[] | null; meta: PaginationMeta | null }> => {
    return await fetchSimpleProjectsRequest({ opt });
};

/**
 * @param id
 * @param val
 */
export const toggleFavoriteProject = async (id: number, val: boolean): Promise<{ isSuccess: boolean }> => {
    return val ? await postProjectFavoriteRequest(id) : await deleteProjectFavoriteRequest(id);
};

/**
 * @param values
 * @param ctx
 */
export const createProject: FormHandler<ProjectFormData, { isSuccess: boolean; data: ProjectDetail | null }> = async (
    values,
    ctx
) => {
    return await updateAndStoreFormData(
        null,
        values,
        ctx,
        getActiveProjectFromStore,
        async (_, values) => await postProjectRequest(transformProjectFormData(values)),
        transformErrorsProjectFormData
    );
};

/**
 * @param id
 * @param values
 * @param ctx
 */
export const updateProject: FormHandlerWithArgs<
    number,
    ProjectFormData,
    { isSuccess: boolean; data: ProjectDetail | null }
> = async (id, values, ctx) => {
    return await updateAndStoreFormData(
        id,
        values,
        ctx,
        getActiveProjectFromStore,
        async (id, values) =>
            await putProjectRequest({
                id: id,
                data: transformProjectFormData(values),
            }),
        transformErrorsProjectFormData
    );
};

/**
 * @param id
 */
export const deleteProject = async (id: number): Promise<{ isSuccess: boolean }> => {
    return await deleteProjectRequest(id);
};

/**
 * @param id
 * @param opt
 */
export const fetchProjectDetail = async (
    id: number,
    opt?: FetchDetailOptions
): Promise<{ isSuccess: boolean; data: ProjectDetail | null }> => {
    return await fetchAndStoreData(
        id,
        routerInstance,
        getActiveProjectFromStore,
        async (id) => await fetchProjectDetailRequest(id),
        opt
    );
};

export const fetchProjectTemplates = async (): Promise<{ isSuccess: boolean; data: ProjectTemplateInfo[] | null }> => {
    return await fetchProjectTemplatesRequest();
};

/**
 * @param projectId
 * @param opt
 */
export const fetchProjectUsers = async (
    projectId: number,
    opt?: FetchListOptions
): Promise<{ isSuccess: boolean; data: ProjectUserInfo[] | null; meta: PaginationMeta | null }> => {
    return await fetchProjectUsersRequest({ projectId, opt });
};

/**
 * @param projectId
 * @param userId
 * @param opt
 */
export const fetchProjectUserDetail = async (
    projectId: number,
    userId: number,
    opt?: FetchDetailOptions
): Promise<{ isSuccess: boolean; data: ProjectUserDetail | null }> => {
    return await fetchAndStoreData(
        userId,
        routerInstance,
        getActiveProjectUserFromStore,
        async (id) => await fetchProjectUserDetailRequest({ projectId: projectId, userId: id }),
        opt
    );
};

/**
 * @param projectId
 * @param values
 * @param ctx
 */
export const addProjectUsers: FormHandlerWithArgs<number, ProjectUserFormData, { isSuccess: boolean }> = async (
    projectId,
    values,
    { setErrors }
) => {
    const { isSuccess, isValidationError, errors } = await postProjectUserRequest({
        projectId,
        data: values,
    });

    if (isValidationError) {
        setErrors(errors);
    }

    return { isSuccess, isValidationError };
};

/**
 * @param projectId
 * @param userId
 * @param values
 * @param ctx
 */
export const updateProjectUser: FormHandlerWithArgs<
    { projectId: number; userId: number },
    ProjectUserFormData,
    { isSuccess: boolean; data: ProjectUserDetail | null }
> = async ({ projectId, userId }, values, ctx) => {
    return await updateAndStoreFormData(
        userId,
        values,
        ctx,
        getActiveProjectUserFromStore,
        async (id, values) =>
            await patchProjectUserRequest({
                projectId: projectId,
                userId: id,
                data: values,
            })
    );
};

/**
 * @param projectId
 * @param userId
 */
export const deleteProjectUser = async (projectId: number, userId: number): Promise<{ isSuccess: boolean }> => {
    return await deleteProjectUserRequest({ projectId, userId });
};

/**
 * @param projectSubjectId
 * @param userId
 */
export const deleteProjectSubjectBinding = async (
    projectSubjectId: number
): Promise<{ isSuccess: boolean; data: ProjectSubjectBindInfo }> => {
    return await deleteProjectSubjectBindingRequest(projectSubjectId);
};

/**
 * @param projectSubjectId
 * @param userId
 */
export const patchProjectSubjectBindingAccept = async (
    projectSubjectId: number
): Promise<{ isSuccess: boolean; data: ProjectSubjectBindInfo }> => {
    return await patchProjectSubjectBindingAcceptRequest(projectSubjectId);
};

/**
 * @param projectId
 * @param values
 * @param ctx
 */
export const createProjectInvitation: FormHandlerWithArgs<
    number,
    ProjectInvitationFormData,
    { isSuccess: boolean }
> = async (projectId, values, { setErrors }) => {
    const { isSuccess, isValidationError, errors } = await postProjectInvitationsRequest({
        projectId,
        data: values,
    });

    if (isValidationError) {
        setErrors(errors);
    }

    return { isSuccess, isValidationError };
};

/**
 * @param projectId
 * @param opt
 */
export const fetchProjectUnassignedUsers = async (
    projectId: number,
    opt?: FetchListOptions
): Promise<{ isSuccess: boolean; data: ProjectSubjectUnassignedUserInfo[] | null; meta: PaginationMeta | null }> => {
    return await fetchProjectUnassignedUsersRequest({ projectId, opt });
};

const getActiveProjectFromStore = (): Ref<ProjectDetail | null> => {
    const { activeProject } = storeToRefs(useProjectStore());
    return activeProject;
};

const getActiveProjectUserFromStore = (): Ref<ProjectUserDetail | null> => {
    const { activeProjectUser } = storeToRefs(useProjectStore());
    return activeProjectUser;
};

const transformProjectFormData = (data: ProjectFormData): ProjectApiFormData => {
    return {
        ...omit(data, ['Labels']),
        Labels: {
            New: data.Labels.filter((x): x is string => typeof x === 'string'),
            Existing: data.Labels.filter((x): x is LabelSimpleInfo => typeof x !== 'string').map((x) => x.Id),
        },
    };
};

const transformErrorsProjectFormData = (errors: ApiErrors<ProjectFormData>): ApiErrors<ProjectFormData> => {
    return transformApiErrors(errors, (errors) => {
        const labelsErrors: string[] = [];

        Object.keys(errors).forEach((eKey) => {
            if (!eKey.startsWith('Labels')) return;

            pushErrorToArray(labelsErrors, errors[eKey]);
            delete errors[eKey];
        });

        errors['Labels'] = labelsErrors;
        return errors;
    });
};
