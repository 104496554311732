<script lang="ts" setup>
import { AppSearchLabelInfo } from '~/modules/core/api/search/_types';
import { AppSearchBarResultLabelsProps } from './_types';

defineProps({
  "labels": null
});
const emit = defineEmits(["click"]);

const onClick = (data: AppSearchLabelInfo) => {
    emit('click', data);
};
</script>
<template>
    <template v-if="labels.length">
        <q-item dense>
            <q-item-section>
                <q-item-label class="text-bold text-on-surface-1">{{ $t('search.groups.labels') }}</q-item-label>
            </q-item-section>
        </q-item>
        <q-separator />
        <q-item>
            <q-item-section>
                <div class="row items-center">
                    <q-chip
                        v-for="l in labels"
                        :key="l.Id"
                        class="text-bold"
                        :label="l.Name"
                        square
                        clickable
                        color="primary"
                        text-color="on-primary"
                        @click="() => onClick(l)"
                    />
                </div>
            </q-item-section>
        </q-item>
    </template>
</template>
