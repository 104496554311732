<script lang="ts" setup>
import { getIconNameByNotificationType } from '~/modules/core/utils/iconsUtils';
import { NotificationStatusItemProps } from './_types';

defineProps({
  "notification": null,
  "canExecute": { type: Boolean,  }
});
const emit = defineEmits(["execute", "remove"]);

const onExecute = () => {
    emit('execute');
};

const onRemove = () => {
    emit('remove');
};
</script>

<template>
    <q-item class="q-pr-sm">
        <q-item-section avatar style="min-width: auto">
            <div
                class="flex flex-center app-notifications"
                :class="`app-notifications--type-${notification.Type.toLocaleLowerCase()}`"
                style="width: 28px; height: 28px; border-radius: 50%"
            >
                <q-icon :name="getIconNameByNotificationType(notification.Type)" size="xs" />
            </div>
        </q-item-section>
        <q-item-section class="text-bold">
            <q-item-label>{{ notification.Title }}</q-item-label>
            <q-item-label v-if="canExecute" caption style="margin-top: 0">
                <link-btn
                    v-close-popup
                    class="q-pa-none text-dense"
                    style="min-height: auto"
                    dense
                    size="0.7rem"
                    :label="$t(`notifications.actions.${notification.RouteName}.actionTitle`)"
                    @click="onExecute"
                />
            </q-item-label>
        </q-item-section>
        <q-item-section side>
            <flat-btn icon="las la-times" dense @click="onRemove" size="sm" />
        </q-item-section>
    </q-item>
</template>
