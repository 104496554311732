{
    "app": {
        "title": "ISKL",
        "title-long": "Informační Systém Krizové Logistiky",
        "subjects": "Subjects",
        "projects": "Projects",
        "modules": "Modules",
        "labels": "Labels",
        "service": "SaaS"
    },
    "api": {
        "errors": {
            "title": "Error occurred"
        }
    },
    "header": {
        "changeLanguage": "Change Language",
        "notifications": "Notifications",
        "accountSettings": "Account Settings",
        "logout": "Log Out"
    },
    "footer": {
        "copyright": "A-WebSys Copyright ©2023"
    },
    "modules": {
        "dispatching": "Dispatching",
        "human-resources": "Human Resources Management",
        "inventory": "Inventory Management",
        "humanitarian-care": "Humanitarian Care Management",
        "reporting": "Reporting",
        "statistics": "Statistics",
        "planning": "Planning"
    },
    "systemFeatures": {
        "crisiscommunication": "Crisis Communication",
        "crisisdocuments": "Crisis Documents"
    },
    "navigation": {
        "backToOverview": "Back to overview",
        "groups": {
            "modules": "Modules",
            "labels": "Labels"
        }
    },
    "generic": {
        "from": "from",
        "boolean": {
            "true": "Yes",
            "false": "No"
        },
        "edit": "Edit",
        "delete": "Delete",
        "restore": "Restore",
        "download": "Download",
        "add": "Add"
    },
    "modals": {
        "notes": "Notes",
        "checklists": "Checklists"
    },
    "pages": {
        "all": "Page Not Found",
        "access-denied": "Access Denied",
        "index": "Home Page",
        "auth-login": "Login Page",
        "auth-login2fa": "Two-factor Login Page",
        "auth-forgot-password": "Forgot Password",
        "auth-change-password-token-email": "Change Password",
        "auth-user-confirmation-confirmation-token": "Confirm email change",
        "auth-set-password-token-email": "Set Password",
        "teams-id": "Team detail",
        "search": "Search Results",
        "profile": "Profile Settings",
        "settings": "Settings",
        "settings-subjects": "Manage Subjects",
        "settings-projects": "Manage Projects",
        "settings-users": "Manage Users",
        "settings-users-id": "User detail",
        "settings-users-id-section": "User detail",
        "settings-users-new": "New user",
        "settings-documents": "Manage Documents",
        "settings-enums": "Manage Enums",
        "settings-labels": "Manage Labels",
        "settings-app-settings": "App Settings",
        "settings-subjects-new": "Create Subject",
        "settings-projects-new": "Create Project",
        "settings-subjects-id": "Subject Detail",
        "settings-subjects-id-new-subject": "Create subject",
        "settings-subjects-id-new-project": "Create project",
        "settings-subjects-id-settings": "Subject Settings",
        "settings-subjects-id-settings-section": "Subject Settings",
        "projects": "My Projects",
        "projects-new": "Create Project",
        "documents": "Crisis Documents",
        "communication": "Crisis Communication",
        "communication-send": "Crisis Communication - Message",
        "notifications": "Notifications Overview"
    },
    "components": {
        "appForm": {
            "actions": {
                "create": "Create",
                "submit": "Save",
                "cancel": "Cancel"
            }
        },
        "uploader": {
            "dndText": "Drag and Drop files here",
            "addFile": "Add file",
            "maxFileSize": "Max. file size"
        },
        "select": {
            "no-result": "No data available"
        },
        "dialog": {
            "confirm": "OK",
            "cancel": "Cancel"
        },
        "datePickerModal": {
            "reset": "reset"
        },
        "labelsInput": {
            "noData": "No Results"
        },
        "filterBar": {
            "hints": {
                "string": {
                    "Like": "has"
                },
                "boolean": {
                    "Eq": "is",
                    "Neq": "is not"
                },
                "enum": {
                    "Eq": "is",
                    "Neq": "is not"
                },
                "date": {
                    "Eq": "is",
                    "Neq": "is not",
                    "Lte": "to",
                    "Gte": "from"
                },
                "number": {
                    "Eq": "equals",
                    "Neq": "not equal",
                    "Lte": "less than or equal",
                    "Gte": "greater than or equal",
                    "Lt": "less than",
                    "Gt": "greater than"
                }
            },
            "options": {
                "boolean": {
                    "true": "Yes",
                    "false": "No"
                }
            },
            "noData": "No results"
        },
        "userRoleAccesslevelPicker": {
            "roles": "Roles",
            "markers": {
                "first": "Low",
                "last": "High"
            }
        },
        "passwordRequirements": {
            "title": "The password must contain:",
            "labels": {
                "MinLength": "Minimum length: {0} characters",
                "MixedCase": "At least one uppercase and one lowercase letter",
                "Numbers": "At least one number",
                "Symbols": "At least one symbol"
            }
        }
    },
    "auth": {
        "form": {
            "login": {
                "inputs": {
                    "login": "Login",
                    "password": "Password"
                },
                "submit": "Log In",
                "errors": {
                    "invalidCredentials": "Invalid login credentials"
                }
            },
            "sms2fa": {
                "code": "∗∗∗∗∗",
                "sendNewCode": "Send new code",
                "submit": "Verify"
            },
            "changePasswordStart": {
                "inputs": {
                    "Email": "Email"
                },
                "submit": "Send"
            },
            "changePasswordFinish": {
                "inputs": {
                    "Password": "Password",
                    "PasswordConfirm": "Password Again",
                    "Login": "Login"
                },
                "submit": "Change Password"
            }
        },
        "prompts": {
            "tokenInvalidPrompt": "Link for changing the password had expired.",
            "enterSmsCodePrompt": "please enter the confirmation code we have sent to you on your mobile phone",
            "enterLoginPrompt": "Please enter your login credentials",
            "forgotPasswordStartPrompt": "Enter the email of the account for which you want to reset the password. You will receive a link to reset your password on this email.",
            "changePasswordFinishPrompt": "to finish reset password set your new password",
            "setPasswordFinishPrompt": "to finish user registration set your new password"
        },
        "actions": {
            "sendForgottenPassword": "Send a forgotten password?",
            "backToLogin": "back to login",
            "backToHomePage": "back to home page"
        },
        "notify": {
            "loggedIn": "Logged In",
            "loggedOut": "Logged Out",
            "smsHasBeenSent": "New sms has been sent.",
            "forgotPasswordEmailSent": "Password reset link has been sent to your email."
        },
        "confirmEmailChange": {
            "success": "Change of email address was confirmed."
        }
    },
    "notifications": {
        "showAll": "Show all notifications",
        "sections": {
            "read": {
                "title": "Read notifications",
                "noData": "No read notifications"
            },
            "unread": {
                "title": "Unread notifications",
                "noData": "No unread notifcations"
            }
        },
        "actions": {
            "InventoryProduct": {
                "actionTitle": "Show product"
            },
            "SubjectMessage": {
                "actionTitle": "Show crisis communication"
            },
            "DispatchingProject": {
                "actionTitle": "Show project"
            },
            "HumanitarianCareProject": {
                "actionTitle": "Show project"
            },
            "HumanResourcesProject": {
                "actionTitle": "Show project"
            },
            "InventoryProject": {
                "actionTitle": "Show project"
            },
            "PlanningProject": {
                "actionTitle": "Show project"
            },
            "ReportingProject": {
                "actionTitle": "Show project"
            },
            "StatisticsProject": {
                "actionTitle": "Show project"
            },
            "DispatchingIssue": {
                "actionTitle": "Show issue"
            }
        }
    },
    "home": {
        "homepage": "Home Page",
        "welcomeBack": "Welcome back",
        "widgets": {
            "favoriteProjects": {
                "title": "Prioritized projects",
                "noData": {
                    "noFavorites": "No prioritized projects",
                    "wantToAdd":"Add projects to your prioritized",
                    "myProjects": "my projects"
                }
            },
            "weather": {
                "title": "Weather",
                "todayIs": "Today is",
                "location": "Location",
                "noData": "No data is currently available",
                "daily": {
                    "tab": "Long term"
                },
                "hourly": {
                    "tab": "Short term",
                    "temperature": {
                        "title": "Temperature"
                    },
                    "precipitation": {
                        "title": "Precipitation"
                    }
                }
            }
        }
    },
    "search": {
        "groups": {
            "subjects": "Subjects",
            "projects": "Projects",
            "labels": "Tags"
        },
        "searchBar": {
            "whatAreYouSearchingFor": "What are you searching for?",
            "noResults": "No Results"
        },
        "searchResults": {
            "resultsFor": "Results for",
            "noResultsFound": "No results found",
            "groups": {
                "subjects": "No results found for subjects",
                "projects": "No results found for projects",
                "labels": "No results found for labels"
            }
        }
    },
    "profile": {
        "title": "Profile Settings",
        "detail": {
            "info": {
                "title": "Profile"
            },
            "security": {
                "title": "Security",
                "changePassword": "Change Password",
                "changeEmail": "Change email",
                "changePhone": "Change Phone"

            }
        },
        "form": {
            "submit": "Save Settings",
            "inputs": {
                "FirstName": "First Name",
                "LastName": "Last Name",
                "Function": "Function",
                "Organization": "Organization",
                "TitleBefore": "Title Before",
                "TitleAfter": "Title After",
                "Phone": "Phone",
                "Avatar": "Avatar"
            }
        },
        "form-pass": {
            "title": "Password change",
            "submit": "Save",
            "inputs": {
                "CurrentPassword": "Current Password",
                "NewPassword": "New Password",
                "PasswordConfirm": "New Password Confirm"

            }
        },
        "form-phone": {
            "title": "Phone number change",
            "submit": "Save",
            "inputs": {
                "CurrentPassword": "Password",
                "NewPhone": "New phone"
            },
            "enterSmsCodePrompt": "please fill the confirmation code we have sent to your mobile phone"
        },
        "form-confirm": {
            "submit": "Confirm",
            "cancel": "Back",
            "inputs": {
                "Code": "Confirmation Code"
            }
        },
        "form-email": {
            "title": "Change email address",
            "submit": "Save",
            "inputs": {
                "CurrentPassword": "Password",
                "NewEmail": "New email"
            },
            "successActionRequired": "please confirm email change by link we have sent to your new email address"
        },
        "notify": {
            "submit": "Setting change was successfull"
        }
    },
    "settings": {
        "createSubject": "Create Subject",
        "createProject": "Create Project",
        "sections": {
            "subjects": {
                "title": "Subjects",
                "action": "Manage subjects"
            },
            "projects": {
                "title": "Projects",
                "action": "Manage projects"
            },
            "users": {
                "title": "Users",
                "action": "Manage users"
            },
            "documents": {
                "title": "Documents",
                "action": "Manage documents"
            },
            "enums": {
                "title": "Enums",
                "action": "Manage enums"
            },
            "labels": {
                "title": "Labels",
                "action": "Manage labels"
            },
            "appSettings": {
                "title": "App Settings",
                "action": "Manage app settings"
            }
        }
    },
    "subjects": {
        "new": {
            "title": "New Subject",
            "subjectData": "Subject Data"
        },
        "edit": {
            "title": "Edit subject"
        },
        "overview": {
            "createSubject": "Create Subject",
            "table": {
                "columns": {
                    "name": "Name",
                    "parent": "Parent subject"
                },
                "actions": {
                    "detail": "show"
                }
            }
        },
        "detail": {
            "subtitle": "Organization subject",
            "manageSubject": "Subject Settings",
            "widgets": {
                "children": {
                    "newChildSubject": "New Child Subject",
                    "columns": {
                        "name": "Subsubjects"
                    },
                    "noData": {
                        "noSubjectChildren": "No Subject Children?",
                        "wantToCreate": "Do you want to create subject child for organization?",
                        "createSubjectChild": "create subject child"
                    }
                },
                "contacts": {
                    "title": "Contacts",
                    "manageContacts": "manage contacts",
                    "columns": {
                        "name": "Contact Name",
                        "email": "E-mail",
                        "phone": "Phone"
                    },
                    "noData": {
                        "noContacts": "No important contacts?",
                        "wantToImport": "Import your contacts into the system",
                        "importContacts": "import contacts"
                    },
                    "prompts": {
                        "delete": {
                            "title": "Remove Contact",
                            "message": "Are you sure you want to remove this contact?",
                            "confirm": "Remove",
                            "cancel": "Cancel"
                        }
                    }
                },
                "projects": {
                    "title": "Projects",
                    "columns": {
                        "name": "Name",
                        "module": "Module",
                        "favorite": "Prioritized"
                    },
                    "actions": {
                        "create": "New Project",
                        "detail": "show"
                    },
                    "noData": {
                        "noProjects": "No project created yet?",
                        "howItHappened": "We cannot explain at all how this could happen!",
                        "createProject": "create project"
                    }
                },
                "users": {
                    "title": "Users",
                    "manageUsers": "manage users",
                    "columns": {
                        "name": "User Name",
                        "organization": "Organization",
                        "roles": "Role"
                    },
                    "noData": {
                        "noUsers": "No users yet?",
                        "wantToAdd": "Add users to your subject now",
                        "addUsers": "add users"
                    },
                    "prompts": {
                        "delete": {
                            "title": "Delete User",
                            "message": "Are you sure you want to remove this user from subject?",
                            "confirm": "Delete",
                            "cancel": "Cancel"
                        }
                    }
                }
            }
        },
        "form": {
            "subject": {
                "inputs": {
                    "ParentSubject": "Parent Subject",
                    "Name": "Name",
                    "NameShort": "Short Name",
                    "Description": "Description",
                    "Contact": {
                        "Street": "Street",
                        "Town": "Town",
                        "Zip": "Zip Code",
                        "Country": "Country"
                    },
                    "LogoId": "Logo"
                },
                "submit": {
                    "new": "Create Subject",
                    "edit": "Edit Subject"
                },
                "cancel": "Cancel"
            },
            "contacts": {
                "title": {
                    "add": "Add Contacts",
                    "edit": "Edit Contact"
                },
                "tabs": {
                    "add": "Add Contacts",
                    "import": "Import Contacts"
                },
                "inputs": {
                    "Name": "Name",
                    "Function": "Function",
                    "Email": "Email",
                    "Phone": "Phone",
                    "File": "Import File"
                },
                "submit": {
                    "new": "Add Contacts",
                    "import": "Import Contacts",
                    "edit": "Edit Contact"
                },
                "sampleImport": "downlaod sample import",
                "cancel": "Cancel"
            },
            "users": {
                "title": {
                    "add": "Add Users",
                    "edit": "Edit User"
                },
                "sections": {
                    "subject": "Subject",
                    "projects": "Projects"
                },
                "inputs": {
                    "users": "User E-Mails"
                },
                "submit": {
                    "add": "Add Users",
                    "edit": "Edit User"
                }
            }
        },
        "settings": {
            "title": "Settings",
            "subtitle": "Organization subject",
            "goBack": "Back to Overview",
            "sections": {
                "detail": {
                    "title": "General Information",
                    "action": "Edit subject"
                },
                "users": {
                    "title": "Subject Users",
                    "action": "Manage users"
                },
                "contacts": {
                    "title": "Subject Contacts",
                    "action": "Manage Contacts"
                },
                "invitations": {
                    "title": "Project Invitations",
                    "action": "Manage invitations"
                }
            },
            "notify": {
                "detail": {
                    "success": "Form was successfully saved"
                }
            },
            "detail": {
                "title": "Subject Information"
            },
            "contacts": {
                "title": "Contacts",
                "addContacts": "Add contacts",
                "columns": {
                    "name": "Name",
                    "function": "Function",
                    "email": "E-mail",
                    "phone": "Phone"
                },
                "prompts": {
                    "delete": {
                        "title": "Delete Contact",
                        "message": "Are you sure you want to remove this contact?",
                        "confirm": "Delete",
                        "cancel": "Cancel"
                    }
                }
            },
            "users": {
                "title": "Users",
                "addUsers": "Add Users",
                "columns": {
                    "firstName": "First Name",
                    "lastName": "Last Name",
                    "function": "Function",
                    "organization": "Organization",
                    "roles": "Role"
                },
                "prompts": {
                    "delete": {
                        "title": "Delete User",
                        "message": "Are you sure you want to remove this user form subject?",
                        "confirm": "Delete",
                        "cancel": "Cancel"
                    }
                }
            },
            "invitations": {
                "title": "Project Invitations",
                "columns": {
                    "project": "Project",
                    "expiration": "Expiration",
                    "bindType": "State"
                },
                "bindType": {
                    "Owner": "Owner",
                    "Participant": "Participant",
                    "Invited": "Invited",
                    "Declined": "Declined",
                    "Inactive": "Inactive",
                    "Remote": "Remote"
                },
                "prompts": {
                    "decline": {
                        "title": "Decline Invitation",
                        "message": "Do you really want to decline invitation for this project?",
                        "confirm": "Decline",
                        "cancel": "Cancel"
                    },
                    "accept": {
                        "title": "Accept Invitation",
                        "message": "Do you really want to accept invitation for this project?",
                        "confirm": "Accept",
                        "cancel": "Cancel"
                    }
                }
            }
        }
    },
    "users": {
        "model": {
            "firstName": "First Name",
            "lastName": "Last Name",
            "email": "Email",
            "status": "Status",
            "function": "Function",
            "organization": "Organization",
            "login": "Login Name",
            "titleBefore": "Title before",
            "titleAfter": "Title after",
            "phone": "Phone",
            "isAdmin": "Admin",
            "projects": {
                "id": "Id",
                "name": "Project",
                "module": {
                    "name": "Module"
                }
            },
            "subjects": {
                "id": "Id",
                "name": "Subject"
            },
            "roles": {
                "id": "Id",
                "name": "Role"
            }
        },
        "enum": {
            "status": {
                "Active": "Active",
                "Inactive": "Inactive",
                "Invited": "Invited"
            }
        },
        "overview": {
            "createUser": "Create user",
            "searchPlaceholder": "Search...",
            "table": {
                "columns": {
                    "name": "Name",
                    "parent": "Parent subject"
                },
                "actions": {
                    "detail": "show"
                }
            }
        },
        "settings": {
            "title": "Users",
            "addUser": "Create user"
        },
        "new": {
            "title": "New user"
        },
        "detail": {
            "title": "User detail",
            "editUser": "Edit information",
            "resetPassword": "Reset password",
            "activateUser": "Activate user",
            "deactivateUser": "Deactivate user",
            "navigation": {
                "info": "Basic information",
                "subjects": "Subjects",
                "projects": "Projects",
                "roles": "Roles"
            },
            "notify": {
                "resetPasswordSuccess": "Password has been reset.",
                "userActivatedSuccess": "User has been activated.",
                "userDeactivatedSuccess": "User has been deactivated."
            },
            "prompt": {
                "activate": "Do you want to activate user?",
                "deactivate": "Do you want to deactivate user?"
            }
        },
        "form": {
            "globalRoles": {
                "title": "Global roles"
            }
        }
    },
    "projects": {
        "new": {
            "title": "New Project",
            "projectData": "Project Information"
        },
        "edit": {
            "title": "Edit Project"
        },
        "overview": {
            "createProject": "Create Project",
            "table": {
                "columns": {
                    "name": "Name",
                    "subject": "Subject",
                    "module": "Module",
                    "labels": "Labels",
                    "label": "Label",
                    "favorite": "Prioritized"
                },
                "actions": {
                    "detail": "show",
                    "delete": "delete"
                },
                "prompts": {
                    "delete": {
                        "title": "Delete project",
                        "message": "Do you really want to delete this project?",
                        "cancel": "Cancel",
                        "confirm": "Delete"
                    }
                }
            }
        },
        "detail": {
            "manageProject": "Manage Project"
        },
        "form": {
            "detail": {
                "inputs": {
                    "Name": "Name",
                    "SubjectId": "Project Owner",
                    "ModuleId": "Project Type",
                    "Description": "Description",
                    "Labels": "Labels",
                    "TemplateId": "Template"
                },
                "submit": {
                    "new": "Create Project",
                    "edit": "Edit Project"
                }
            },
            "users": {
                "title": {
                    "add": "Add Users",
                    "edit": "Edit User"
                },
                "inputs": {
                    "users": "Users",
                    "subject": "Subject"
                },
                "submit": {
                    "add": "Add Users",
                    "edit": "Edit User"
                },
                "noUnassignedUsers": "No new unassigned users available"
            },
            "invitations": {
                "title": {
                    "create": "Create Invitation"
                },
                "inputs": {
                    "subjects": "Subjects",
                    "expiresAt": "Expiration",
                    "accessLevel": "Access Level"
                },
                "submit": {
                    "create": "Create Invitation"
                }
            }
        },
        "settings": {
            "title": "Settings",
            "goBack": "Back to Overview",
            "sections": {
                "detail": {
                    "title": "General Information",
                    "action": "Edit project"
                },
                "users": {
                    "title": "Project Users",
                    "action": "Manage users"
                },
                "invitations": {
                    "title": "Subject Invitations",
                    "action": "Manage invitations"
                }
            },
            "notify": {
                "detail": {
                    "success": "Form was successfully saved"
                }
            },
            "detail": {
                "title": "Project Information"
            },
            "users": {
                "title": "Users",
                "addUsers": "Add Users",
                "columns": {
                    "firstName": "First Name",
                    "lastName": "Last Name",
                    "function": "Function",
                    "organization": "Organization",
                    "roles": "Role"
                },
                "prompts": {
                    "delete": {
                        "title": "Delete user",
                        "message": "Do you really want to remove this user from project?",
                        "confirm": "Delete",
                        "cancel": "Cancel"
                    }
                }
            },
            "invitations": {
                "title": "Subject Invitations",
                "createInvitation": "Create Invitation",
                "columns": {
                    "subject": "Subject",
                    "expiration": "Expiration",
                    "bindType": "State"
                },
                "bindType": {
                    "Owner": "Owner",
                    "Participant": "Participant",
                    "Invited": "Invited",
                    "Declined": "Declined",
                    "Inactive": "Inactive",
                    "Remote": "Remote"
                },
                "prompts": {
                    "delete": {
                        "title": "Delete Invitation",
                        "message": "Do you really want to delete invitation for this subject?",
                        "confirm": "Delete",
                        "cancel": "Cancel"
                    }
                }
            }
        }
    },
    "documents": {
        "overview": {
            "manageDocuments": "Manage Documents",
            "uploadDocument": "Upload Document",
            "added": "added",
            "noData": "No documents were uploaded yet",
            "prompts": {
                "delete": {
                    "title": "Remove Document",
                    "message": "Are you sure you want to remove this document?",
                    "confirm": "Remove",
                    "cancel": "Cancel"
                }
            }
        },
        "form": {
            "title": "New Document",
            "inputs": {
                "DisplayName": "Name",
                "File": "File"
            },
            "submit": "Upload Document",
            "cancel": "Cancel"
        }
    },
    "communication": {
        "info": {
            "sender": "Sender"
        },
        "form": {
            "inputs": {
                "Subjects": "Subjects",
                "Priority": "Message Type",
                "Title": "Message Title",
                "Body": "Message Body",
                "Type": "Message Source"
            },
            "submit": "Send Message",
            "cancel": "Cancel"
        },
        "send": {
            "title": "Crisis Communication - Send Message",
            "newMessage": "New Message",
            "type": {
                "Info": "Information",
                "Success": "Success",
                "Warning": "Warning",
                "Danger": "Danger"
            },
            "source": {
                "Sms": "SMS",
                "Email": "E-mail"
            }
        },
        "overview": {
            "title": "Crisis Communication",
            "createMessage": "Create Message",
            "noData": "No messages have been sent yet"
        }
    },
    "notes": {
        "options": {
            "yes": "Yes",
            "no": "No"
        },
        "textNotes": {
            "newNote": "New note",
            "listOfNotes": "My notes",
            "editNote": "Edit note",
            "createNewNote": "Create new note",
            "deleteNote": "Delete note",
            "noteTitle": "Title",
            "noteDescription": "Text",
            "cancel": "Cancel",
            "save": "Save note",
            "edit": "Edit",
            "back": "Back",
            "insertFiles": "Insert files",
            "confirm_title": "Delete note",
            "confirm_text": "Do you really want to delete this note?"
        },
        "checklists": {
            "newNote": "New checklist",
            "listOfNotes": "My checklists",
            "editNote": "Edit checklist",
            "createNewNote": "Create new checklist",
            "deleteNote": "Delete checklist",
            "noteTitle": "Title",
            "noteDescription": "Text",
            "cancel": "Cancel",
            "save": "Save checklist",
            "edit": "Edit",
            "back": "Back",
            "resolved": "Resolved",
            "reslove": "Resolve",
            "confirm_title": "Delete checklist",
            "confirm_text": "Do you really want to delete this checklist?"
        }
    },
    "labels": {
        "model": {
            "name": "Label name",
            "isActive": "Active",
            "projectsCount": "Number of projects"
        },
        "overview": {
            "newLabel": "New label",
            "searchPlaceholder": "Search...",
            "prompts": {
                "delete": {
                    "title": "Delete label",
                    "message": "Do you really want to delete this label?",
                    "confirm": "Delete",
                    "cancel": "Cancel"
                }
            }
        },
        "form": {
            "new": {
                "title": "New label",
                "submit": {
                    "create": "Create label"
                }
            }
        }
    },
    "errors": {
        "title": "There was a mistake",
        "goHomePage": "Home page",
        "403": {
            "title": "Ooh.",
            "text": "We're sorry. You do not have access to this page."
        },
        "404": {
            "title": "Ooh.",
            "text": "We're sorry. We still don't have everything ready for you. The page you're looking for, we're probably looking for as well. Please try again later."
        }
    }
}
