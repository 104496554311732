<script lang="ts" setup>
import usePermissionSettings from '~/modules/core/composables/permissions/usePermissionsSettings';
import { AppNavigationSettingsProps } from './_types';

defineProps({
  "showSubItems": { type: Boolean,  }
});
const { settings, canShowSettings } = usePermissionSettings();
</script>

<template>
    <div v-if="canShowSettings()">
        <app-navigation-item icon="las la-cog" :title="$t('pages.settings')" :to="{ name: 'settings' }" />
        <div v-if="showSubItems">
            <div v-for="s in settings" :key="s.key">
                <app-navigation-item
                    v-if="s.canShow()"
                    :title="$t(`settings.sections.${s.key}.title`)"
                    is-subitem
                    :to="s.to"
                />
            </div>
        </div>
    </div>
</template>
