<script lang="ts" setup>
import usePermissionCommunication from '~/modules/core/composables/permissions/usePermissionsCommunication';
import { SystemFeatureKey } from '~/modules/core/enums/_types';
import { getIconNameByModuleKey } from '~/modules/core/utils/iconsUtils';
import { getModuleOverviewRoute, getModuleOverviewRouteTitle } from '~/modules/core/utils/routeUtils';

import { AppNavigationModulesProps } from './_types';

defineProps({
  "show": { type: Boolean,  }
});

const { modules } = useModules();
const { canReceiveAny } = usePermissionCommunication();
</script>

<template>
    <div v-if="show">
        <label-separator
            class="q-my-sm"
            color="on-surface-2"
            line-color="on-surface-3"
            :label="$t('navigation.groups.modules')"
        />

        <app-navigation-item
            v-for="m in modules"
            :key="m.Id"
            :icon="getIconNameByModuleKey(m.Key)"
            :title="$t(getModuleOverviewRouteTitle(m.Key))"
            :to="getModuleOverviewRoute(m.Key)"
        />
        <q-separator class="q-my-sm" inset color="on-surface-3" />
        <app-navigation-item
            icon="las la-file-alt"
            :title="$t(`systemFeatures.${SystemFeatureKey.CrisisDocuments}`)"
            :to="{ name: 'documents' }"
        />
        <app-navigation-item
            v-if="canReceiveAny()"
            icon="las la-envelope-open-text"
            :title="$t(`systemFeatures.${SystemFeatureKey.CrisisCommunication}`)"
            :to="{ name: 'communication' }"
        />
    </div>
</template>
