import { RoutePagePemissions } from '~/plugins/router/_types';
import usePermissionGlobal from '~/modules/core/composables/permissions/usePermissionsGlobal';

export const permissions = (): RoutePagePemissions => {
    const { canLabelsManage } = usePermissionGlobal();
    return {
        'settings-labels': canLabelsManage,
        'settings-labels-new': canLabelsManage,
    };
};
