import api from '~/plugins/apiClient';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import { fetchListOptionsToQuery } from '~/modules/core/api/_transformers';
import { rawToChecklist, rawToChecklistArray } from './_transformers';
import { ApiHandlerWithData, ApiResponseContext } from '~/plugins/apiClient/_types';
import { FetchListOptions, PaginationMeta } from '~/modules/core/api/_types';
import { Checklist, ChecklistFormData } from './_types';

const apiEndpointPrefix = 'checklists';

export const fetchChecklistsRequest: ApiHandlerWithData<
    { opt?: FetchListOptions },
    ApiResponseContext<Checklist[] | null, null, PaginationMeta>
> = async ({ opt }) => {
    return api.get(formatApiUrl(apiEndpointPrefix), {
        transform: rawToChecklistArray,
        params: fetchListOptionsToQuery(opt),
    });
};

export const createChecklistRequest: ApiHandlerWithData<
    { values: ChecklistFormData },
    ApiResponseContext<Checklist | null, ChecklistFormData>
> = async ({ values }) => {
    return api.post(formatApiUrl(apiEndpointPrefix), values, {
        transform: rawToChecklist,
    });
};

export const updateChecklistRequest: ApiHandlerWithData<
    { id: number; values: ChecklistFormData },
    ApiResponseContext<Checklist | null, ChecklistFormData>
> = async ({ id, values }) => {
    return api.put(formatApiUrl(apiEndpointPrefix, id), values, {
        transform: rawToChecklist,
    });
};

export const deleteChecklistRequest: ApiHandlerWithData<
    { id: number },
    ApiResponseContext<Checklist | null, ChecklistFormData>
> = async ({ id }) => {
    return api.delete(formatApiUrl(apiEndpointPrefix, id));
};
