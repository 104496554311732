import { RouteLocationNormalized } from 'vue-router';
import { RoutePagePemissions } from '~/plugins/router/_types';
import usePermissionProject from '~/modules/core/composables/permissions/usePermissionsProject';
import usePermissionsHumanitarianCare from '~/modules/humanitarian-care/composables/permissions/usePermissionsHC';

export const permissions = (): RoutePagePemissions => {
    const { getProjectDetailFromRoute } = usePermissionProject();

    const { canCreateTransaction, canManageSettings } = usePermissionsHumanitarianCare();

    const canGoNewTransaction = async (to: RouteLocationNormalized) => {
        const project = await getProjectDetailFromRoute(to);
        return canCreateTransaction(project);
    };

    const canGoSettings = async (to: RouteLocationNormalized) => {
        const project = await getProjectDetailFromRoute(to);
        return canManageSettings(project);
    };

    return {
        'humanitarian-care-id-new': canGoNewTransaction,
        'humanitarian-care-id-settings': canGoSettings,
        'humanitarian-care-id-settings-section': canGoSettings,
    };
};
