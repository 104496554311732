{
    "pages": {
        "dispatching": "Dispečink",
        "dispatching-new": "Dispečink - Nový projekt",
        "dispatching-id": "Dispečink - Požadavky",
        "dispatching-id-new": "Dispečink - Nový požadavek",
        "dispatching-id-settings": "Dispečink - Nastavení",
        "dispatching-id-settings-section": "Dispečink - Nastavení",
        "dispatching-issues-id": "Dispečink - Detail požadavku"
    },
    "dispatching": {
        "overview": {
            "manageProject": "Nastavení projektu",
            "manageData": "Nastavení dat",
            "table": {
                "tab": "Přehled",
                "newIssue": "Nový požadavek",
                "columns": {
                    "name": "Název",
                    "id": "ID",
                    "state": "Stav",
                    "priority": "Priorita",
                    "creator": "Autor",
                    "assignee": "Řešitel",
                    "createdAt": "Založeno",
                    "finishAt": "Uzavřít do",
                    "project": "Projekt"
                },
                "actions": {
                    "detail": "detail"
                }
            },
            "map": {
                "tab": "Mapa",
                "columns": {
                    "name": "Název",
                    "ticket": "Ticket",
                    "assignee": "Řešitel",
                    "createdAt": "Založeno"
                },
                "planRoute": {
                    "start": "Naplánovat trasu",
                    "stop": "Ukončit plánování",
                    "markers": {
                        "start": "Start",
                        "end": "Cíl",
                        "waypoint": "Zastávka %{idx}"
                    },
                    "mapPosition": "Pozice na mapě",
                    "chooseMapPosition": "Vyberte pozici na mapě"
                },
                "traffic": {
                    "showTraffic": "Ukázat dopravu",
                    "showDiversions": "Výluky",
                    "showTrams": "Tramvaje",
                    "showTrolleybuses": "Trolejbusy",
                    "showBuses": "Autobusy",
                    "showBusesRegional": "Regionální autobusy",
                    "showTrains": "Vlaky",
                    "delay": "Zpoždění",
                    "noDelay": "včas"
                }
            }
        },
        "new": {
            "title": "Nový požadavek",
            "issueData": "Údaje o požadavku"
        },
        "detail": {
            "goToOverview": "Zpět na přehled",
            "issue": {
                "issue": "Požadavek",
                "assignedIssues": "Přiřazené požadavky",
                "titles": {
                    "Name": "Název",
                    "State": "Stav",
                    "Priority": "Priorita",
                    "Creator": "Autor",
                    "CreatedAt": "Založeno",
                    "BeginAt": "Řešit od",
                    "FinishAt": "Vyřešit do",
                    "Assignee": "Řešitel",
                    "Body": "Popis",
                    "Map": "Mapa",
                    "ShowOnMap": "Zobrazit na mapě",
                    "Attachments": "Přílohy"
                },
                "menu": {
                    "edit": "Upravit",
                    "changeState": "Změnit stav",
                    "delete": "Odstranit"
                },
                "prompts": {
                    "delete": {
                        "title": "Odstranit požadavek",
                        "message": "Opravdu chcete odstranit tento požadavek?",
                        "confirm": "Odstranit",
                        "cancel": "Zrušit"
                    }
                }
            },
            "comment": {
                "comments": "Komentáře",
                "addComment": "Vložit komentář",
                "type": {
                    "comment": "Komentář",
                    "response": "Odpověď"
                },
                "actions": {
                    "response": "Odpovědět"
                },
                "menu": {
                    "edit": "Upravit",
                    "delete": "Odstranit"
                },
                "prompts": {
                    "delete": {
                        "title": "Odstranit komentář",
                        "message": "Opravdu chcete odstranit tento komentář?",
                        "confirm": "Odstranit",
                        "cancel": "Zrušit"
                    }
                }
            }
        },
        "form": {
            "issue": {
                "inputs": {
                    "Name": "Název",
                    "StateId": "Stav",
                    "PriorityId": "Priorita",
                    "BeginAt": "Řešit od",
                    "FinishAt": "Vyřešit do",
                    "AssigneeId": "Řešitel",
                    "Body": "Popis",
                    "Attachments": "Přílohy",
                    "Position": "Vyberte polohu"
                },
                "submit": {
                    "new": "Založit požadavek",
                    "edit": "Upravit požadavek"
                },
                "cancel": "Zahodit"
            },
            "comment": {
                "response": "Odpovědět",
                "inputs": {
                    "Body": "Komentář",
                    "Attachments": "Přílohy"
                },
                "submit": {
                    "new": "Založit komentář",
                    "edit": "Upravit komentář",
                    "response": "Odpovědět"
                },
                "cancel": "Zahodit"
            },
            "enum": {
                "title": "Nastavení dat",
                "tabs": {
                    "priority": "Priority",
                    "state": "Stavy"
                },
                "inputs": {
                    "Name": "Název"
                },
                "submit": {
                    "save": "Uložit"
                },
                "cancel": "Zahodit"
            }
        },
        "settings": {
            "sections": {
                "enums": {
                    "title": "Nastavení číselníků",
                    "action": "Spravovat číselníky"
                }
            },
            "notify": {
                "enums": {
                    "success": "Formulář byl úspěšně uložen"
                }
            }
        },
        "widget": {
            "assignedTable": {
                "title": "Přiřazené požadavky",
                "noData": {
                    "noIssues": "Žádné přiřazené požadavky",
                    "wantToAdd": "Podívejte se jestli není potřeba něco vyřešit",
                    "dispatchingProjects": "projekty dispečinku"
                }
            },
            "assignedMap": {
                "title": "Mapa přiřazených požadavků"
            }
        }
    }
}