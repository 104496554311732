import { pick } from 'lodash-es';
import { DateTime } from 'luxon';

import { TextNote } from './_types';
import { ApiResponseTransformer } from '~/plugins/apiClient/_types';

const rawToTextNoteFunc: ApiResponseTransformer<TextNote> = (note) => {
    return {
        ...pick(note, ['Id', 'Title', 'Body']),
        Attachments: note.Attachments || [],
        CreatedAt: DateTime.fromISO(note.CreatedAt),
        UpdatedAt: DateTime.fromISO(note.UpdatedAt),
    };
};

export const rawToTextNoteArray: ApiResponseTransformer<TextNote[] | null> = (data) => {
    if (data == null) return null;

    return Array.isArray(data) ? data.map(rawToTextNoteFunc) : null;
};

export const rawToTextNote: ApiResponseTransformer<TextNote | null> = (data) => {
    if (data == null) return null;

    return rawToTextNoteFunc(data);
};
