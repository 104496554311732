<script lang="ts" setup>
import { getCssVar, QBtnSlots } from 'quasar';
import { OutlinedBtnProps } from './_types';

const props = defineProps({
  "label": null,
  "textColor": { default: 'primary' },
  "dense": null,
  "align": null,
  "stretch": null,
  "disable": null,
  "padding": null,
  "size": null,
  "type": null,
  "to": null,
  "loading": null,
  "round": null,
  "onClick": null,
  "icon": null,
  "iconRight": null,
  "smallIcons": { type: Boolean,  },
  "color": { default: 'on-primary' }
});
const color = computed(() => getCssVar(props.color || '') || props.color);
const textColor = computed(() => getCssVar(props.textColor || '') || props.textColor);
</script>
<template>
    <q-btn
        v-bind="props"
        class="app-btn app-btn-outlined app-btn--no-q-hover text-bold"
        :class="{ 'app-btn--small-icon': smallIcons }"
        outline
        :color="color"
        :text-color="textColor"
        :style="{ '--app-btn-outlined--text-color': textColor, '--app-btn-outlined--color': color }"
        no-caps
        :ripple="false"
    >
        <template v-for="(_, slotName) in ($slots as Readonly<QBtnSlots>)" #[slotName]="slotProps">
            <slot :name="slotName" v-bind="((slotProps || {}) as any)" />
        </template>
    </q-btn>
</template>
