{
    "pages": {
        "human-resources": "Human Resources",
        "human-resources-new": "Human Resources - New Project",
        "human-resources-id": "Human Resources - Overview",
        "human-resources-id-new-worker": "Human Resources - New Worker",
        "human-resources-id-section": "Human Resources - Overview",
        "human-resources-id-settings": "Human Resources - Settings",
        "human-resources-id-settings-section": "Human Resources - Settings",
        "human-resources-workers-id": "Human Resources - Workers Detail"
    },
    "humanResources": {
        "settings": {
            "sections": {
                "checklists": {
                    "title": "Checklist Settings",
                    "action": "Manage Checklists"
                }
            },
            "checklists": {
                "title": "Checklist Templates",
                "actions": {
                    "create": "Create Checklist"
                },
                "columns": {
                    "name": "Name",
                    "createdAt": "Created At",
                    "updatedAt": "Last Change"
                },
                "prompts": {
                    "delete": {
                        "title": "Delete checklist",
                        "message": "Do you really want to delete this checklist",
                        "confirm": "Delete",
                        "cancel": "Cancel"
                    }
                }
            }
        },
        "new": {
            "worker": {
                "title": "Workers Information"
            }
        },
        "overview": {
            "actions": {
                "newWorker": "New Worker"
            },
            "filter": {
                "columns": {
                    "firstName": "First Name",
                    "lastName": "Last Name",
                    "employmentRelationship": "Employment Relationship",
                    "function": "Position",
                    "entryDate": "Entry Date",
                    "terminationDate": "Termination Date",
                    "birthDate": "Birth Date",
                    "phone": "Phone",
                    "city": "City"
                }
            },
            "table": {
                "columns": {
                    "fullName": "Name",
                    "employmentRelationship": "Employment Relationship",
                    "function": "Position",
                    "entryDate": "Entry Date",
                    "terminationDate": "Termination Date",
                    "birthDate": "Birth Date"
                },
                "actions": {
                    "detail": "detail"
                }
            },
            "boards": {
                "menu": {
                    "edit": "Edit",
                    "delete": "Delete"
                },
                "labels": {
                    "function": "Position",
                    "phone": "Phone",
                    "city": "City"
                },
                "actions": {
                    "newBoard": "Add board"
                },
                "prompts": {
                    "delete": {
                        "title": "Delete board",
                        "message": "Do you really want to delete this board?",
                        "confirm": "Delete",
                        "cancel": "Cancel"
                    }
                }
            }
        },
        "detail": {
            "worker": {
                "sections": {
                    "data": "Workers data",
                    "checklists": "Checklists",
                    "attachments": "Attachments",
                    "notes": "Notes"
                },
                "actions": {
                    "backToOverview": "Back to Overview"
                },
                "menu": {
                    "checklists": {
                        "edit": "Edit",
                        "delete": "Delete"
                    }
                },
                "noData": {
                    "checklists": "Pracovník zatím nemá přiřazené žádné checklisty"
                },
                "notify": {
                    "data": "Form was successfully saved",
                    "notes": "Note was successfully added",
                    "attachments": "Attachment was successfully uploaded",
                    "checklists": "Checklist was successfully added"
                },
                "prompts": {
                    "notes": {
                        "delete": {
                            "title": "Delete note",
                            "message": "Do you really want to delete this note?",
                            "confirm": "Delete",
                            "cancel": "Cancel"
                        }
                    },
                    "attachment": {
                        "delete": {
                            "title": "Delete attachment",
                            "message": "Do you really want to delete this attachment?",
                            "confirm": "Delete",
                            "cancel": "Cancel"
                        }
                    },
                    "checklists": {
                        "delete": {
                            "title": "Delete checklist",
                            "message": "Do you really want to delete this workers checklist?",
                            "confirm": "Delete",
                            "cancel": "Cancel"
                        }
                    }
                }
            }
        },
        "form": {
            "board": {
                "title": {
                    "new": "Create Board",
                    "edit": "Edit Board"
                },
                "inputs": {
                    "Name": "Name",
                    "Description": "Description"
                },
                "submit": {
                    "new": "Create Board",
                    "edit": "Edit Board"
                }
            },
            "checklists": {
                "manage": "manage templates",
                "noData": "No unused templates are available",
            
                "title": {
                    "new": {
                        "template": "New Template",
                        "checklist": "New Checklist"
                    },
                    "edit": {
                        "template": "Edit Template",
                        "checklist": "Edit Checklist"
                    }
                },
                "inputs": {
                    "Name": "Name",
                    "Title": "Item",
                    "Person": "Responsible Person",
                    "ChecklistId": "Choose template"
                },
                "submit": {
                    "new": "Create Checklist",
                    "edit": "Edit Checklist"
                }
            },
            "worker": {
                "sections": {
                    "basicData": "Basic Data",
                    "personalData": "Personal Data"
                },
                "submit": {
                    "new": "Create Employee",
                    "edit": "Edit Employee"
                },
                "inputs": {
                    "FirstName": "First Name",
                    "LastName": "Last Name",
                    "Function": "Position",
                    "Phone": "Phone",
                    "Email": "Email",
                    "Folder": "Folder",
                    "Board": "Board",
                    "Street": "Address",
                    "City": "City",
                    "PostCode": "PostCode",
                    "Description": "Description",
                    "BankAccountNumber": "Bank Account Number",
                    "BirthDate": "BirthDate",
                    "BirthNumber": "Birth Number",
                    "BirthPlace": "Birth Place",
                    "Citizenship": "Citizenship",
                    "DependentChildrenNumber": "Number of Dependent Children",
                    "EmploymentRelationship": "Employee Relationship",
                    "EntryDate": "Entry Date",
                    "TerminationDate": "Termination Date",
                    "MaritalStatus": "Marital Status",
                    "MedicalExaminationDate": "Medical Examination Date",
                    "PrivateEmail": "Private E-mail",
                    "SalaryAmount": "Current Salary Amount",
                    "SalaryRevisionDate": "Last Salary Revision",
                    "Student": "Student",
                    "Ztp": "Ztp",
                    "HealthInsurance": "Health Insurance",
                    "GDPRAgreed": "The employee agrees to the processing of personal data"
                }
            },
            "note": {
                "inputs": {
                    "Note": "Note"
                },
                "submit": "Add Note"
            },
            "attachment": {
                "inputs": {
                    "DisplayName": "Name",
                    "File": "File",
                    "AttachmentTypeKey": "Attachment Type"
                },
                "submit": "Upload Attachment"
            }
        }
    },
    "validation": {
        "fields": {
            "GDPRAgreed": {
                "required": "Consent to the processing of personal data is required."
            }
        }
    }
}
