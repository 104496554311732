<script lang="ts" setup>
import { QInfiniteScroll, QInfiniteScrollProps, QItem, QList, QScrollArea, useQuasar } from 'quasar';
import { NotesProps } from './_types';
import { useAppStore } from '~/modules/core/stores/app';
import { t } from '~/plugins/i18n';

const thumbStyle = {
    width: '5px',
};

const props = defineProps({
  "modalOpen": { type: Boolean,  },
  "type": null,
  "formOpen": { type: Boolean,  },
  "items": null,
  "activeItem": null,
  "disableLoad": { type: Boolean,  },
  "isLoading": { type: Boolean,  },
  "setModalOpen": { type: Function,  },
  "setFormOpen": { type: Function,  },
  "loadItems": { type: Function,  },
  "setActiveItem": { type: Function,  },
  "deleteItem": { type: Function,  }
});

const $q = useQuasar();
const appStore = useAppStore();

const infiniteScrollRef = ref<QInfiniteScroll | null>(null);
const scrollAreaRef = ref<QScrollArea | null>(null);

const { showConfirm } = useDialog();

const showModal = computed({
    get() {
        return props.modalOpen;
    },
    set(value) {
        props.setModalOpen(value);
    },
});

const notesTitle = computed(() => {
    let key = 'listOfNotes';

    if (props.formOpen) {
        key = props.activeItem ? 'editNote' : 'newNote';
    }

    return `notes.${props.type}.${key}`;
});

const prevId = computed(() => {
    if (props.activeItem == null) return null;
    const activeIndex = props.items.findIndex((item) => props.activeItem?.Id === item.Id);
    return activeIndex > 0 ? props.items[activeIndex - 1].Id : null;
});

const nextId = computed(() => {
    if (props.activeItem == null) return null;
    const activeIndex = props.items.findIndex((item) => props.activeItem?.Id === item.Id);
    return activeIndex < props.items.length - 1 ? props.items[activeIndex + 1].Id : null;
});

const onCreateItem = () => {
    props.setActiveItem(null);
    props.setFormOpen(true);
};

const onEditItem = () => {
    props.setFormOpen(true);
};

const onDeleteItem = async () => {
    if (!props.activeItem) {
        return;
    }

    showConfirm({
        title: t(`notes.${props.type}.confirm_title`),
        message: t(`notes.${props.type}.confirm_text`),
        cancel: {
            label: t('notes.options.no'),
        },
        confirm: {
            label: t('notes.options.yes'),
            color: 'negative',
        },
    }).onOk(async () => {
        if (scrollAreaRef.value) {
            scrollAreaRef.value.setScrollPosition('vertical', 0, 300);
        }

        if (infiniteScrollRef.value) {
            infiniteScrollRef.value.setIndex(1);
        }

        if (props.activeItem) {
            await props.deleteItem(props.activeItem);
        }
    });
};

const onActiveItem = (activeId: number | null) => {
    props.setActiveItem(props.items.find((item) => item.Id === activeId) || null);

    if (scrollAreaRef.value && activeId) {
        const itemIndex = props.items.findIndex((item) => item.Id === activeId);

        const scrollAreaElement = scrollAreaRef.value.getScrollTarget();

        const scrollPosition = scrollAreaRef.value.getScrollPosition();
        const scrolledPercentage = scrollAreaRef.value.getScrollPercentage();

        const scrollTo =
            ((scrollAreaElement.querySelector('.q-list')?.clientHeight || 0) / props.items.length) * itemIndex;

        if (scrolledPercentage && (scrolledPercentage.top < 1 || scrollPosition.top > scrollTo)) {
            scrollAreaRef.value.setScrollPosition('vertical', scrollTo, 300);
        }
    }
};

const loadData: QInfiniteScrollProps['onLoad'] = async (idx, done) => {
    props.loadItems({ index: idx, done });
};

watch(showModal, (newVal: boolean) => {
    if (newVal && appStore.isLeftNavigationOpen) {
        appStore.toggleLeftNavigation();
    }
});
</script>

<template>
    <q-dialog
        v-model="showModal"
        :full-width="$q.screen.lt.md"
        :full-height="$q.screen.lt.md"
        :maximized="$q.screen.lt.sm"
        :no-esc-dismiss="true"
        :no-backdrop-dismiss="true"
        :no-shake="true"
        @show="loadItems({ index: 1 })"
    >
        <q-card class="column notes no-wrap">
            <q-card-section class="row justify-between">
                <h5 class="self-center text-bold q-my-none">{{ $t(notesTitle) }}</h5>
                <flat-btn
                    v-close-popup
                    icon="las la-times"
                    text-color="secondary"
                    active-color="primary"
                    size="1.2em"
                    dense
                />
            </q-card-section>

            <q-separator />

            <template v-if="formOpen">
                <q-card-section class="col-grow notes-form-height">
                    <slot name="itemForm" :item="activeItem"></slot>
                </q-card-section>
            </template>

            <template v-else>
                <q-card-section horizontal class="col-grow notes-height">
                    <q-card-section
                        v-if="!$q.screen.lt.md || !activeItem"
                        class="col-3 column"
                        :class="$q.screen.lt.md ? 'col-12' : 'col-3'"
                    >
                        <contained-btn
                            type="button"
                            :label="$t(`notes.${type}.createNewNote`)"
                            class="q-mb-sm full-width"
                            @click="onCreateItem"
                        />

                        <q-scroll-area
                            v-if="items.length > 0"
                            ref="scrollAreaRef"
                            class="col-grow full-width"
                            :thumb-style="thumbStyle"
                        >
                            <q-infinite-scroll
                                :offset="50"
                                :initial-index="1"
                                class="full-height"
                                ref="infiniteScrollRef"
                                :disable="disableLoad"
                                @load="loadData"
                            >
                                <q-list class="full-height">
                                    <q-item
                                        v-for="item in items"
                                        :key="item.Id"
                                        class="notes__list-item q-py-md"
                                        :class="{ active: item.Id === activeItem?.Id }"
                                        clickable
                                        @click="onActiveItem(item.Id)"
                                    >
                                        <slot v-bind="item" name="itemOfList"></slot>
                                    </q-item>
                                </q-list>
                                <template #loading>
                                    <div class="text-center">
                                        <q-spinner color="primary" size="3em" :thickness="2" />
                                    </div>
                                </template>
                            </q-infinite-scroll>
                        </q-scroll-area>
                        <div v-if="isLoading && items.length === 0" class="text-center">
                            <q-spinner color="primary" size="3em" :thickness="2" />
                        </div>
                    </q-card-section>

                    <q-separator v-if="!$q.screen.lt.md" vertical />

                    <q-card-section
                        v-if="!$q.screen.lt.md || activeItem"
                        class="column no-wrap"
                        :class="$q.screen.lt.md ? 'col-12' : 'col-max-shrink'"
                    >
                        <template v-if="activeItem">
                            <div class="row">
                                <div class="col-12 row justify-between items-center q-pb-sm">
                                    <h5 class="q-my-none text-bold text-primary">{{ activeItem.Title }}</h5>
                                    <span class="text-on-surface-1">{{ activeItem.UpdatedAt.toLocaleString() }}</span>
                                </div>
                            </div>

                            <div class="row col-grow">
                                <div class="col-12 q-pb-sm column no-wrap">
                                    <slot v-bind="activeItem" name="itemDetail"></slot>
                                </div>
                            </div>

                            <q-separator />

                            <div class="row justify-between q-pt-md">
                                <contained-btn
                                    type="button"
                                    :label="$t(`notes.${type}.deleteNote`)"
                                    color="error"
                                    text-color="on-error"
                                    @click="onDeleteItem"
                                />
                                <contained-btn type="button" :label="$t(`notes.${type}.edit`)" @click="onEditItem" />
                            </div>
                        </template>
                    </q-card-section>
                </q-card-section>

                <q-separator />

                <q-card-section class="row justify-between">
                    <div>
                        <outlined-btn
                            v-if="$q.screen.lt.md && activeItem"
                            type="button"
                            :label="$t('notes.textNotes.back')"
                            color="on-secondary"
                            text-color="secondary"
                            @click="setActiveItem(null)"
                        />
                    </div>

                    <div v-if="activeItem || $q.screen.md">
                        <flat-btn
                            icon="las la-angle-left"
                            text-color="secondary"
                            active-color="primary"
                            size="1.2em"
                            dense
                            :disable="prevId == null"
                            @click="onActiveItem(prevId)"
                        />
                        <flat-btn
                            icon="las la-angle-right"
                            text-color="secondary"
                            active-color="primary"
                            size="1.2em"
                            dense
                            :disable="nextId == null"
                            @click="onActiveItem(nextId)"
                        />
                    </div>
                </q-card-section>
            </template>
        </q-card>
    </q-dialog>
</template>
