import api from '~/plugins/apiClient';
import { formatApiUrl } from '~/plugins/apiClient/utils';
import { rawToAppFileDetail } from '~/modules/core/api/files/_transformers';
import { fetchListOptionsToQuery } from '~/modules/core/api/_transformers';
import { rawToTextNote, rawToTextNoteArray } from './_transformers';
import { ApiHandlerWithData, ApiResponseContext } from '~/plugins/apiClient/_types';
import { FetchListOptions, PaginationMeta } from '~/modules/core/api/_types';
import { TextNote, TextNoteFormData } from './_types';
import { AppFileDetail, UploadFileFormData } from '~/modules/core/api/files/_types';

const apiEndpointPrefix = 'notes';

export const fetchTextNotesRequest: ApiHandlerWithData<
    { opt?: FetchListOptions },
    ApiResponseContext<TextNote[] | null, null, PaginationMeta>
> = async ({ opt }) => {
    return api.get(formatApiUrl(apiEndpointPrefix), {
        transform: rawToTextNoteArray,
        params: fetchListOptionsToQuery(opt),
    });
};

export const createTextNoteRequest: ApiHandlerWithData<
    { values: TextNoteFormData },
    ApiResponseContext<TextNote | null, TextNoteFormData>
> = async ({ values }) => {
    return api.post(formatApiUrl(apiEndpointPrefix), values, { transform: rawToTextNote });
};

export const updateTextNoteRequest: ApiHandlerWithData<
    { id: number; values: TextNoteFormData },
    ApiResponseContext<TextNote | null, TextNoteFormData>
> = async ({ id, values }) => {
    return api.put(formatApiUrl(apiEndpointPrefix, id), values, { transform: rawToTextNote });
};

export const deleteTextNoteRequest: ApiHandlerWithData<
    { id: number },
    ApiResponseContext<TextNote | null, TextNoteFormData>
> = async ({ id }) => {
    return api.delete(formatApiUrl(apiEndpointPrefix, id));
};

export const postAttachmentRequest: ApiHandlerWithData<
    { data: UploadFileFormData },
    ApiResponseContext<AppFileDetail | null, UploadFileFormData>
> = async ({ data }) => {
    return api.post(formatApiUrl(apiEndpointPrefix, 'file'), data, {
        transform: rawToAppFileDetail,
    });
};
