import { WatchOptions } from './_types';

const useWatchApiData = <TData, TOptions>(
    id: Ref<number | null>,
    fetchDataCallback: (id: number, opt?: TOptions) => Promise<{ isSuccess: boolean; data: TData | null }>,
    canReloadFn?: (() => boolean) | null,
    fetchOpt?: TOptions,
    watchOpt?: WatchOptions
) => {
    const { isLoading, data, reload } = useApiDataWithArgs(fetchDataCallback, fetchOpt);

    onMounted(async () => {
        if (watchOpt?.noReloadOnMount) return;

        await tryReload();
    });

    watch(id, async () => {
        await tryReload();
    });

    const tryReload = async () => {
        if ((canReloadFn == null || canReloadFn()) && id.value != null) {
            await reload(id.value, fetchOpt);
        }
    };

    return {
        isLoading,
        data,
        reload,
    };
};

export default useWatchApiData;
