<script lang="ts" setup>
import { QMenu } from 'quasar';
import { setNotificationRead } from '~/modules/core/services/notifications';
import { NotificationInfo } from '~/modules/core/api/notifications/_types';
import { FetchListFilterOperator } from '~/modules/core/enums/_types';

const menuRef = ref<QMenu>();

const notifications = computed(() => data.value?.Notifications || []);

const implicitFilter = [{ column: 'ReadAt', op: FetchListFilterOperator.Eq, val: null }];
const { status, hasUnreadNotifications, isLoading, data, request, canExecuteAction, executeAction } = useNotifications(
    {
        rowsPerPage: 5,
        sortBy: 'CreatedAt',
        descending: true,
    },
    implicitFilter
);

watch(isLoading, () => {
    nextTick(() => {
        menuRef.value?.updatePosition();
    });
});

const onMenuBeforeShow = async () => {
    await request();
    nextTick(() => {
        menuRef.value?.updatePosition();
    });
};

const onRemove = async (id: number) => {
    const { isSuccess } = await setNotificationRead(id);
    if (isSuccess) {
        await request();
    }
};

const onExecuteAction = async (info: NotificationInfo) => {
    await executeAction(info);
};
</script>

<template>
    <flat-btn size="1.2em" icon="las la-bell">
        <floating-badge
            v-if="hasUnreadNotifications"
            rounded
            :label="status?.Unread"
            class="text-bold"
            text-color="on-surface"
            color="on-surface-3"
            :offset="[10, 4]"
        />
        <q-menu
            ref="menuRef"
            style="min-width: 100px"
            @before-show="onMenuBeforeShow"
            anchor="bottom right"
            self="top right"
            :offset="[0, 19]"
        >
            <q-list>
                <q-inner-loading
                    :showing="isLoading && notifications.length > 0"
                    class="q-mb-md"
                    color="primary"
                    size="sm"
                />
                <q-item v-if="isLoading && notifications.length === 0">
                    <q-item-section>
                        <q-inner-loading :showing="isLoading" color="primary" size="sm" />
                    </q-item-section>
                </q-item>
                <div v-else-if="notifications.length === 0">
                    <q-item class="text-bold text-center">
                        <q-item-section>
                            {{ $t('notifications.sections.unread.noData') }}
                        </q-item-section>
                    </q-item>
                    <q-separator />
                </div>

                <div v-for="n in notifications" :key="n.Id">
                    <notification-status-item
                        :can-execute="canExecuteAction(n)"
                        :notification="n"
                        @execute="() => onExecuteAction(n)"
                        @remove="() => onRemove(n.Id)"
                    />
                    <q-separator />
                </div>
                <q-item>
                    <q-item-section>
                        <link-btn v-close-popup :label="$t('notifications.showAll')" :to="{ name: 'notifications' }" />
                    </q-item-section>
                </q-item>
            </q-list>
        </q-menu>
    </flat-btn>
</template>
