{
    "pages": {
        "statistics": "Statistiky",
        "statistics-new": "Statistiky - Nový projekt",
        "statistics-id": "Statistiky - Přehled",
        "statistics-id-settings": "Statistiky - Nastavení",
        "statistics-id-settings-section": "Statistiky - Nastavení",
        "statistics-id-new-product": "Statistiky - Nový produkt",
        "statistics-id-new-dashboard": "Statistiky - Nový dashboard",
        "statistics-products-id": "Statistiky - Detail produktu",
        "statistics-dashboards-id": "Statistiky - Detail dashboardu"
    },
    "statistics": {
        "widgets": {
            "masterProduct": {
                "title": "Statistické produkty"
            }
        },
        "historyPeriod": {
            "d": "Den",
            "w": "Týden",
            "m": "Měsíc",
            "q": "Čtvrtletí",
            "y": "Rok"
        },
        "settings": {
            "sections": {
                "inventories": {
                    "title": "Nastavení skladů",
                    "action": "Spravovat sklady",
                    "notify": {
                        "success": "Formulář byl úspěšně uložen"
                    }
                }
            }
        },
        "new": {
            "masterProduct": {
                "title": "Údaje o produktu"
            },
            "dashboard": {
                "title": "Údaje o dashboardu"
            }
        },
        "form": {
            "inventories": {
                "title": "Nastavení skladů",
                "submit": "Uložit nastavení"
            },
            "masterProduct": {
                "inputs": {
                    "Name": "Název",
                    "AmountMinimal": "Minimální množství",
                    "AmountOptimal": "Optimální množství",
                    "AmountMaximal": "Maximální množství"
                },
                "searchProducts": {
                    "searchTitle": "Vyhledat produkty",
                    "searchNoData": "Nebyly nalezeny žádné produkty",
                    "selectedTitle": "Vybrané produkty",
                    "selectedNoData": "Nebyly vybrány žádné produkty",
                    "input": "Název/EAN",
                    "columns": {
                        "Name": "Název",
                        "Subject": "Subjekt",
                        "Inventory": "Sklad",
                        "Ean": "EAN",
                        "Amount": "Množství"
                    }
                },
                "submit": {
                    "new": "Vytvořit produkt",
                    "edit": "Upravit produkt"
                }
            },
            "dashboard": {
                "inputs": {
                    "Name": "Název"
                },
                "searchProducts": {
                    "searchTitle": "Vyhledat produkty",
                    "searchNoData": "Nebyly nalezeny žádné produkty",
                    "selectedTitle": "Vybrané produkty",
                    "selectedNoData": "Nebyly vybrány žádné produkty",
                    "input": "Název",
                    "columns": {
                        "Name": "Název",
                        "Amount": "Množství"
                    }
                },
                "submit": {
                    "new": "Vytvořit dashboard"
                }
            }
        },
        "overview": {
            "actions": {
                "newDashboard": "Vytvořit dashboard",
                "newProduct": "Přidat produkt"
            },
            "dashboards": {
                "title": "Dashboardy"
            },
            "table": {
                "columns": {
                    "name": "Název",
                    "amount": "Aktuální množství",
                    "amountMinimal": "Minimální množství",
                    "amountOptimal": "Optimální množství",
                    "amountMaximal": "Maximální množství",
                    "state": "Stav"
                },
                "actions": {
                    "detail": "detail"
                }
            }
        },
        "detail": {
            "masterProduct": {
                "title": "Detail produktu",
                "actions": {
                    "goBackOverview": "Zpět na přehled",
                    "editProduct": "Upravit produkt",
                    "deleteProduct": "Smazat produkt"
                }
            },
            "dashboard": {
                "title": "Detail dashboardu",
                "actions": {
                    "goBackOverview": "Zpět na přehled",
                    "deleteDashboard": "Smazat dashboard"
                }
            }
        },
        "charts": {
            "noData": "Nejsou k dispozici žádná data pro vykreslení grafu",
            "emptyDataLabel": "Neuvedeno",
            "masterProduct": {
                "product": {
                    "tabs": {
                        "Inventory": "Podle skladu",
                        "Subject": "Podle subjektu",
                        "Expiration": "Podle expirace"
                    }
                },
                "history": {
                    "tabs": {
                        "IncomeOutcome": "Příjem/Výdej",
                        "Consuption": "Podle spotřeby"
                    },
                    "labels": {
                        "Income": "Příjem",
                        "Outcome": "Výdej"
                    },
                    "inputs": {
                        "period": "Perioda",
                        "from": "Od",
                        "to": "Do"
                    }
                }
            }
        },
        "prompts": {
            "masterProduct": {
                "delete": {
                    "title": "Odstranit produkt",
                    "message": "Opravdu chcete odstranit tento produkt?",
                    "confirm": "Odstranit",
                    "cancel": "Zrušit"
                }
            },
            "dashboard": {
                "delete": {
                    "title": "Odstranit dashboard",
                    "message": "Opravdu chcete odstranit tento dashboard?",
                    "confirm": "Odstranit",
                    "cancel": "Zrušit"
                }
            }
        }
    }
}
