import config from '~/plugins/config';
import { RoutePagePemissions } from './_types';

const resolvePagePermissions = (): RoutePagePemissions => {
    const localeFiles = import.meta.glob<{ permissions: () => RoutePagePemissions }>(
        `../../modules/**/pages/**/_permissions.ts`,
        {
            eager: true,
        }
    );

    const pagePermissions: RoutePagePemissions = {};
    Object.entries(localeFiles).forEach(async ([key, value]) => {
        const matchedModule = key.match(/modules\/([A-Za-z0-9-_]+)\/pages/);

        if (matchedModule?.length !== 2) {
            console.warn('[Plugin router]', `Can't load '${key}' pemissions.`);
            return;
        }

        const module = matchedModule[1];

        if (!config.isModuleSupported(module)) {
            console.log('[Plugin router]', `Module '${module}' is not supported for permissions.`);
            return;
        }

        const permissions = value.permissions();
        for (const key in permissions) {
            pagePermissions[key] = permissions[key];
        }
    });

    return pagePermissions;
};

export const getPagePermissions = () => {
    if (_pagePermissions == null) {
        _pagePermissions = resolvePagePermissions();
    }
    return _pagePermissions;
};

let _pagePermissions: RoutePagePemissions | null = null;
