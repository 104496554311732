<script lang="ts" setup>
import { AppFormProps } from './_types';

defineProps({
  "loading": { type: Boolean,  },
  "submitting": { type: Boolean,  },
  "canSubmit": { type: Boolean, default: true },
  "submitBtnLabel": null,
  "showCancel": { type: Boolean,  },
  "cancelBtnLabel": null,
  "hideActionsSeparator": { type: Boolean,  }
});

const emit = defineEmits(["cancel", "submit"]);

const onCancel = () => {
    emit('cancel');
};

const onSubmit = (evt: Event | SubmitEvent) => {
    emit('submit', evt);
};
</script>

<template>
    <q-form @submit.prevent="onSubmit">
        <slot />
        <q-inner-loading class="q-pa-md" color="primary" :showing="loading" />
        <slot name="actions">
            <q-separator v-if="!hideActionsSeparator" />
            <div class="row items-center" :class="{ 'q-mt-lg': !hideActionsSeparator }">
                <outlined-btn
                    v-if="!(showCancel === false)"
                    :label="cancelBtnLabel ?? $t('components.appForm.actions.cancel')"
                    size="1.1em"
                    @click="onCancel"
                />
                <q-space />
                <contained-btn
                    type="submit"
                    :label="submitBtnLabel ?? $t('components.appForm.actions.submit')"
                    :loading="submitting"
                    :disable="loading || submitting || !canSubmit"
                    size="1.1em"
                />
            </div>
        </slot>
    </q-form>
</template>
