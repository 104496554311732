import { TextNote } from '~/modules/core/api/textNotes/_types';

const useTextNotesStore = defineStore('textnotes', () => {
    const textNotes = ref<Array<TextNote>>([]);
    const totalTextNotes = ref<number>(-1);
    const activeTextNote = ref<TextNote | null>(null);
    const textNoteFormOpen = ref<boolean>(false);
    const modalTextNoteOpen = ref<boolean>(false);

    const addNewTextNote = (checklist: TextNote) => {
        textNotes.value.unshift(checklist);
    };

    const updateTextNote = (checklist: TextNote) => {
        deleteTextNoteById(checklist.Id);
        addNewTextNote(checklist);
    };

    const deleteTextNoteById = (id: number) => {
        const index = textNotes.value.findIndex((textNote) => textNote.Id === id);

        if (index > -1) {
            textNotes.value.splice(index, 1);
        }
    };

    const setActiveTextNote = (textNote: TextNote | null) => {
        activeTextNote.value = textNote;
    };

    const setTextNoteFormOpen = (open: boolean) => {
        textNoteFormOpen.value = open;
    };

    const setModalTextNoteOpen = (open: boolean) => {
        modalTextNoteOpen.value = open;
    };

    const clearTextNotes = () => {
        textNotes.value = [];
        totalTextNotes.value = -1;
    };

    return {
        textNotes,
        totalTextNotes,
        activeTextNote,
        textNoteFormOpen,
        modalTextNoteOpen,
        addNewTextNote,
        updateTextNote,
        deleteTextNoteById,
        setActiveTextNote,
        setTextNoteFormOpen,
        setModalTextNoteOpen,
        clearTextNotes,
    };
});

export default useTextNotesStore;
