<script setup lang="ts">
import { QInputProps, QInputSlots } from 'quasar';
import { useField, useFieldError } from 'vee-validate';
import { getInputErrorKey } from '~/utils/baseUtils';
import RequiredInputLabelSlot from './slots/RequiredInputLabelSlot.vue';
import { VInputProps } from './_types';

const props = defineProps({
  "name": null,
  "validateOnValueUpdate": { type: Boolean, default: true },
  "label": null,
  "stackLabel": { default: false },
  "hint": null,
  "hideBottomSpace": { default: false },
  "outlined": { default: true },
  "clearable": null,
  "dense": { default: false },
  "disable": null,
  "readonly": null,
  "debounce": { default: 500 },
  "color": null,
  "labelColor": null,
  "errorKeyName": null,
  "counter": null,
  "suffix": null,
  "prefix": null,
  "inputClass": null,
  "required": { type: Boolean, default: false },
  "type": { default: 'text' }
});

// Computed
const errorKey = computed(() => props.errorKeyName || getInputErrorKey(props.name));

// Refs
const { value, handleReset, handleBlur, setValue } = useField<QInputProps['modelValue']>(
    toRef(props, 'name'),
    {},
    {
        label: toRef(props, 'label'),
        validateOnValueUpdate: props.validateOnValueUpdate,
    }
);

const errorMessage = useFieldError(errorKey);

const onUpdateValue = (val: QInputProps['modelValue']) => {
    if (props.type === 'number' && typeof val === 'string') {
        const numberVal = parseFloat(val);
        val = isNaN(numberVal) ? val : numberVal;
    }

    setValue(val);
};
</script>

<template>
    <q-input
        :model-value="value"
        v-bind="props"
        :error-message="errorMessage"
        :error="!!errorMessage"
        @update:model-value="onUpdateValue"
        @reset="handleReset"
        @blur="handleBlur"
    >
        <template v-for="(_, slotName) in ($slots as Readonly<QInputSlots>)" #[slotName]="slotProps">
            <slot :name="slotName" v-bind="((slotProps || {}) as any)" />
        </template>
        <template v-if="props.required" #label>
            <required-input-label-slot :label="props.label" />
        </template>
    </q-input>
</template>
