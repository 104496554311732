<script lang="ts" setup>
import { TextNotesListItemProps } from './_types';

const props = defineProps({
  "item": null
});
const { item } = toRefs(props);
</script>

<template>
    <q-item-section>
        <q-item-label class="notes__list-item-label">{{ item.Title }}</q-item-label>
        <q-item-label caption>{{ item.UpdatedAt.toLocaleString() }}</q-item-label>
    </q-item-section>
</template>
