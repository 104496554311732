import { RoutePagePemissions } from '~/plugins/router/_types';
import usePermissionCommunication from '~/modules/core/composables/permissions/usePermissionsCommunication';

export const permissions = (): RoutePagePemissions => {
    const { canSendAny, canReceiveAny } = usePermissionCommunication();
    return {
        'communication-send': canSendAny,
        communication: canReceiveAny,
    };
};
