import { fetchAppSearchResult } from '~/modules/core/api/search';
import { AppSearchResult } from '~/modules/core/api/search/_types';

/**
 * @param searchPhrase
 */
export const searchApp = async (
    searchPhrase: string
): Promise<{ data: AppSearchResult | null; isSuccess: boolean; totalResultCount: number; skipped: boolean }> => {
    const { data, isSuccess } = await fetchAppSearchResult(searchPhrase);
    return { data, isSuccess, totalResultCount: getTotalResultCount(data), skipped: false };
};

const getTotalResultCount = (result: AppSearchResult | null): number => {
    if (result == null) {
        return 0;
    }
    return result.Labels.length + result.Projects.length + result.Subjects.length;
};
