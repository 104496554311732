import { ModuleKey } from '~/modules/core/enums/_types';

export interface AppSearchResult {
    Subjects: AppSearchSubjectInfo[];
    Projects: AppSearchProjectInfo[];
    Labels: AppSearchLabelInfo[];
}

export interface AppSearchSubjectInfo {
    _kind: 'subject';
    Id: number;
    Name: string;
    NameShort: string;
}

export interface AppSearchProjectInfo {
    _kind: 'project';
    Id: number;
    Name: string;
    ModuleKey: ModuleKey;
}

export interface AppSearchLabelInfo {
    _kind: 'label';
    Id: number;
    Name: string;
}

export type AppSearchInfo = AppSearchSubjectInfo | AppSearchProjectInfo | AppSearchLabelInfo;

export const isAppSearchSubjectInfo = (item: AppSearchInfo): item is AppSearchSubjectInfo => {
    return item._kind === 'subject';
};

export const isAppSearchProjectInfo = (item: AppSearchInfo): item is AppSearchProjectInfo => {
    return item._kind === 'project';
};

export const isAppSearchLabelInfo = (item: AppSearchInfo): item is AppSearchLabelInfo => {
    return item._kind === 'label';
};
