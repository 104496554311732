import { RouteLocation, RouteLocationNormalized, RouteLocationRaw, Router } from 'vue-router';
import { ACCESS_DENIED_ROUTE, HOME_ROUTE, NOT_FOUND_ROUTE, PUBLIC_ROUTES } from '~/plugins/router/_constants';

/**
 * Resolve a router path (fallback to index)
 */
export const resolveRouteFromQuery = (router: Router, toNameFallback = HOME_ROUTE): RouteLocation => {
    const { currentRoute } = router;
    const toName = <string>currentRoute.value.query?.to || toNameFallback;

    try {
        const params = JSON.parse(<string>currentRoute.value.query?.params);
        return router.resolve({ name: toName, params });
    } catch (e) {
        return router.resolve({ name: toNameFallback });
    }
};

export const isPublicRoute = (route: RouteLocationNormalized): boolean => {
    return PUBLIC_ROUTES.includes(<string>route.name);
};

export const goToNotFoundPage = async (router: Router): Promise<void> => {
    await replacePageAndKeepUrl(router, { name: NOT_FOUND_ROUTE });
};

export const goToAccessDeniedPage = async (router: Router): Promise<void> => {
    await replacePageAndKeepUrl(router, { name: ACCESS_DENIED_ROUTE });
};

export const replacePageAndKeepUrl = async (router: Router, to: RouteLocationRaw): Promise<void> => {
    const path = router.currentRoute.value.fullPath;
    await router.replace(to);
    history.replaceState(null, '', path);
};
