{
    "pages": {
        "human-resources": "Lidské zdroje",
        "human-resources-new": "Lidské zdroje - Nový projekt",
        "human-resources-id": "Lidské zdroje - Přehled",
        "human-resources-id-new-worker": "Lidské zdroje - Nový pracovník",
        "human-resources-id-section": "Lidské zdroje - Přehled",
        "human-resources-id-settings": "Lidské zdroje - Nastavení",
        "human-resources-id-settings-section": "Lidské zdroje - Nastavení",
        "human-resources-workers-id": "Lidské zdroje - Detail pracovníka"
    },
    "humanResources": {
        "settings": {
            "sections": {
                "checklists": {
                    "title": "Nastavení checklistů",
                    "action": "Spravovat checklisty"
                }
            },
            "checklists": {
                "title": "Šablony checklistů",
                "actions": {
                    "create": "Vytvořit checklist"
                },
                "columns": {
                    "name": "Název",
                    "createdAt": "Vytvořeno",
                    "updatedAt": "Poslední změna"
                },
                "prompts": {
                    "delete": {
                        "title": "Odstranit checklist",
                        "message": "Opravdu chcete odstranit tento checklist?",
                        "confirm": "Odstranit",
                        "cancel": "Zrušit"
                    }
                }
            }
        },
        "new": {
            "worker": {
                "title": "Údaje o pracovníkovi"
            }
        },
        "overview": {
            "actions": {
                "newWorker": "Nový pracovník"
            },
            "filter": {
                "columns": {
                    "firstName": "Jméno",
                    "lastName": "Příjmení",
                    "employmentRelationship": "Typ pracovního poměru",
                    "function": "Pozice",
                    "entryDate": "Datum nástupu",
                    "terminationDate": "Datum odchodu",
                    "birthDate": "Narozeniny",
                    "phone": "Telefon",
                    "city": "Město"
                }
            },
            "table": {
                "columns": {
                    "fullName": "Jméno",
                    "employmentRelationship": "Typ pracovního poměru",
                    "function": "Pozice",
                    "entryDate": "Datum nástupu",
                    "terminationDate": "Datum odchodu",
                    "birthDate": "Narozeniny"
                },
                "actions": {
                    "detail": "detail"
                }
            },
            "boards": {
                "menu": {
                    "edit": "Editovat",
                    "delete": "Smazat"
                },
                "labels": {
                    "function": "Pozice",
                    "phone": "Telefon",
                    "city": "Město"
                },
                "actions": {
                    "newBoard": "Přidat nástěnku"
                },
                "prompts": {
                    "delete": {
                        "title": "Odstranit nástěnku",
                        "message": "Opravdu chcete odstranit tuto nástěnku?",
                        "confirm": "Odstranit",
                        "cancel": "Zrušit"
                    }
                }
            }
        },
        "detail": {
            "worker": {
                "sections": {
                    "data": "Údaje o pracovníkovi",
                    "checklists": "Checklisty",
                    "attachments": "Přílohy",
                    "notes": "Poznámky"
                },
                "actions": {
                    "backToOverview": "Zpět na přehled"
                },
                "menu": {
                    "checklists": {
                        "edit": "Upravit",
                        "delete": "Smazat"
                    }
                },
                "noData": {
                    "checklists": "Pracovník zatím nemá přiřazené žádné checklisty"
                },
                "notify": {
                    "data": "Formulář byl úspěšně uložen",
                    "notes": "Poznámka byla úspěšně přidána",
                    "attachments": "Příloha byla úspěšně nahrána",
                    "checklists": "Checklist byl úspěšně přidán"
                },
                "prompts": {
                    "notes": {
                        "delete": {
                            "title": "Odstranit poznámku",
                            "message": "Opravdu chcete odstranit tuto poznámku?",
                            "confirm": "Odstranit",
                            "cancel": "Zrušit"
                        }
                    },
                    "attachments": {
                        "delete": {
                            "title": "Odstranit přílohu",
                            "message": "Opravdu chcete odstranit tuto přílohu?",
                            "confirm": "Odstranit",
                            "cancel": "Zrušit"
                        }
                    },
                    "checklists": {
                        "delete": {
                            "title": "Odstranit checklist",
                            "message": "Opravdu chcete odstranit tento checklist pracovníka?",
                            "confirm": "Odstranit",
                            "cancel": "Zrušit"
                        }
                    }
                }
            }
        },
        "form": {
            "board": {
                "title": {
                    "new": "Vytvořit nástěnku",
                    "edit": "Upravit nástěnku"
                },
                "inputs": {
                    "Name": "Název",
                    "Description": "Popis"
                },
                "submit": {
                    "new": "Vytvořit nástěnku",
                    "edit": "Upravit nástěnku"
                }
            },
            "checklists": {
                "manage": "spravovat šablony",
                "noData": "Žádné nepoužité šablony nejsou k dispozici",
                "title": {
                    "new": {
                        "template": "Nová šablona",
                        "checklist": "Nový checklist"
                    },
                    "edit": {
                        "template": "Upravit šablonu",
                        "checklist": "Upravit checklist"
                    }
                },
                "inputs": {
                    "Name": "Název",
                    "Title": "Položka",
                    "Person": "Zodpovědná osoba",
                    "ChecklistId": "Vyberte šablonu"
                },
                "submit": {
                    "new": "Vytvořit checklist",
                    "edit": "Upravit checklist"
                }
            },
            "worker": {
                "sections": {
                    "basicData": "Základní údaje",
                    "personalData": "Osobní údaje"
                },
                "submit": {
                    "new": "Vytvořit pracovníka",
                    "edit": "Upravit pracovníka"
                },
                "inputs": {
                    "FirstName": "Jméno",
                    "LastName": "Příjmení",
                    "Function": "Pozice",
                    "Phone": "Telefon",
                    "Email": "Email",
                    "Folder": "Složka",
                    "Board": "Nástěnka",
                    "Street": "Ulice, čp.",
                    "City": "Město",
                    "PostCode": "PSČ",
                    "Description": "Popis",
                    "BankAccountNumber": "Číslo bankovního účtu",
                    "BirthDate": "Datum narození",
                    "BirthNumber": "Rodné číslo",
                    "BirthPlace": "Místo narození",
                    "Citizenship": "Státní občanství",
                    "DependentChildrenNumber": "Počet vyživovaných dětí",
                    "EmploymentRelationship": "Typ pracovního poměru",
                    "EntryDate": "Datum nástupu",
                    "TerminationDate": "Datum odchodu",
                    "MaritalStatus": "Rodinný stav",
                    "MedicalExaminationDate": "Lékařská prohlídka",
                    "PrivateEmail": "Soukromý e-mail",
                    "SalaryAmount": "Aktální výše mzdy",
                    "SalaryRevisionDate": "Poslední revize mzdy",
                    "Student": "Student",
                    "Ztp": "Ztp",
                    "HealthInsurance": "Zdravotní pojišťovna",
                    "GDPRAgreed": "Pracovník souhlasí se zpracováním osobních údajů"
                }
            },
            "note": {
                "inputs": {
                    "Note": "Poznámka"
                },
                "submit": "Přidat poznámku"
            },
            "attachment": {
                "inputs": {
                    "DisplayName": "Název",
                    "File": "Soubor",
                    "AttachmentTypeKey": "Typ přílohy"
                },
                "submit": "Nahrát přílohu"
            }
        }
    },
    "validation": {
        "fields": {
            "GDPRAgreed": {
                "required": "Je vyžadován souhlas se zpracováním osobních údajů."
            }
        }
    }
}
