<script lang="ts" setup>
import { isEmpty } from 'lodash-es';
import { getAcronym } from '~/utils/baseUtils';
import { UserAvatarProps } from './_types';

const props = defineProps({
  "avatar": null,
  "icon": null,
  "fullName": null
});

const imgError = ref(false);
const canShowAvatar = computed(() => !isEmpty(props.avatar) && !imgError.value);
</script>

<template>
    <q-avatar class="app-user-avatar">
        <div v-if="!canShowAvatar" class="app-user-avatar--content text-bold text-primary">
            <q-icon :name="icon" v-if="icon" size="lg" color="on-surface-2"></q-icon>
            <span v-else>{{ getAcronym(fullName) }}</span>
        </div>
        <q-img
            v-else
            class="app-user-avatar--content"
            no-spinner
            no-transition
            :ratio="1"
            :src="avatar || ''"
            @error="() => (imgError = true)"
        />
    </q-avatar>
</template>
