<script setup lang="ts">
import { isEmpty, pick } from 'lodash-es';
import { QSeparatorProps } from 'quasar';
import { LabelSeparatorProps } from './_types';

const props = defineProps({
  "label": null,
  "iconLabelClass": null,
  "icon": null,
  "spaced": null,
  "size": null,
  "color": null,
  "lineColor": null
});

// Computed
const separatorProps = computed<QSeparatorProps>(() => ({
    ...pick(props, ['size', 'spaced']),
    color: props.lineColor ? props.lineColor : props.color,
}));
</script>

<template>
    <div class="row items-center" :class="[iconLabelClass]">
        <div class="col-auto row items-center q-ml-md">
            <slot>
                <q-icon v-if="!isEmpty(icon)" class="q-mr-xs" :color="color" :name="icon" />
                <div :class="`${color ? `text-${color}` : ''}`">{{ label }}</div>
            </slot>
        </div>
        <div class="col">
            <q-separator inset v-bind="separatorProps" />
        </div>
    </div>
</template>
