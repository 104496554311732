import { RouteLocationNormalized } from 'vue-router';
import { RoutePagePemissions } from '~/plugins/router/_types';
import usePermissionProject from '~/modules/core/composables/permissions/usePermissionsProject';
import usePermissionStatistics from '~/modules/statistics/composables/permissions/usePermissionsStatistics';

export const permissions = (): RoutePagePemissions => {
    const { getProjectDetailFromRoute, canManage } = usePermissionProject();

    const { canManageInventory, canManageDashboard, canManageMasterProduct } = usePermissionStatistics();

    const canGoSettings = async (to: RouteLocationNormalized) => {
        const project = await getProjectDetailFromRoute(to);
        return canManage(project) || canManageInventory(project);
    };

    const canGoNewProduct = async (to: RouteLocationNormalized) => {
        const project = await getProjectDetailFromRoute(to);
        return canManageMasterProduct(project);
    };

    const canGoNewDashboard = async (to: RouteLocationNormalized) => {
        const project = await getProjectDetailFromRoute(to);
        return canManageDashboard(project);
    };

    return {
        'statistics-id-new-dashboard': canGoNewDashboard,
        'statistics-id-new-product': canGoNewProduct,
        'statistics-id-settings': canGoSettings,
        'statistics-id-settings-section': canGoSettings,
    };
};
