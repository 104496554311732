import { executeRoute } from '~/modules/core/utils/routeUtils';
import { NotificationAction } from '~/plugins/notificationManager/_types';

export enum NotificationActionSharedKey {
    Message = 'SubjectMessage',
}

export const getActions = (): NotificationAction[] => {
    return [
        {
            key: NotificationActionSharedKey.Message,
            actionFn: async () =>
                await executeRoute({
                    name: 'communication',
                }),
        },
    ];
};
