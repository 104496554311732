<script lang="ts" setup>
import { LabelChipProps } from './_types';

defineProps({
  "label": null,
  "projectsCount": null,
  "removable": { type: Boolean,  }
});
const emit = defineEmits(["remove"]);

const onRemove = () => {
    emit('remove');
};
</script>

<template>
    <div class="row items-stretch noflexwrap fit-content overflow-hidden rounded-borders">
        <div class="q-px-md q-py-xs bg-primary text-on-primary text-bold">{{ label }}</div>
        <div v-if="projectsCount != null" class="q-px-sm q-py-xs bg-on-surface-3 text-on-surface-1 text-bold">
            {{ projectsCount }}
        </div>
        <q-separator v-if="projectsCount != null && removable" vertical color="on-surface-2" />
        <div v-if="removable" class="flex flex-center bg-on-surface-3">
            <flat-btn
                class="q-mx-none q-px-sm q-pt-xs"
                dense
                icon="las la-times"
                text-color="secondary"
                active-color="primary"
                @click="onRemove"
                size="xs"
            />
        </div>
    </div>
</template>
