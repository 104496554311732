<script setup lang="ts">
import { useQuasar } from 'quasar';
import { useAppStore } from '~/modules/core/stores/app';
import { HOME_ROUTE } from '~/plugins/router/_constants';

import AppSearchBarVue from '~/modules/core/components/search/searchBar/AppSearchBar.vue';

const appStore = useAppStore();
const q = useQuasar();

const { setModalTextNoteOpen } = useTextNotes();
const { totalUncheckedCount, setModalChecklistOpen } = useChecklists();

const searchBarElRef = ref<InstanceType<typeof AppSearchBarVue>>();
const searchToggle = ref(false);

const canShowSearchBar = computed(() => q.screen.gt.sm || searchToggle.value);
const canShowSearchBarIcon = computed(() => q.screen.lt.md && !searchToggle.value);

const onSearchIconClick = () => {
    searchToggle.value = true;
    nextTick(() => {
        searchBarElRef.value?.focus();
    });
};
const onSearchBarBlur = () => {
    searchToggle.value = false;
};
</script>

<template>
    <q-header class="shadow-5 text-on-surface bg-surface">
        <q-toolbar class="q-pl-none q-pr-xs q-py-md">
            <div v-if="!searchToggle" class="row items-center no-wrap">
                <flat-btn
                    size="1.2em"
                    icon="las la-bars"
                    text-color="secondary"
                    active-color="primary"
                    @click="appStore.toggleLeftNavigation()"
                />
                <div class="column flex-center">
                    <flat-btn dense :to="{ name: HOME_ROUTE }">
                        <img src="/assets/images/logo.svg" height="38" class="q-ma-none" />
                    </flat-btn>
                    <div class="text-subtitle2 text-on-surface-1" style="line-height: 0.2rem">
                        {{ $t('app.service') }}
                    </div>
                </div>
            </div>
            <!-- 
                @plachtova: it must be 'v-show' or when we have 'v-if' the click on autocomplete menu item does not work if we have 'mobile' version of header
            -->
            <app-search-bar ref="searchBarElRef" v-show="canShowSearchBar" @blur="onSearchBarBlur" />
            <q-space />
            <div class="q-gutter-sm row items-center no-wrap">
                <flat-btn
                    v-if="$q.screen.gt.sm"
                    small-icons
                    size="1.2em"
                    icon="las la-sticky-note"
                    text-color="secondary"
                    active-color="primary"
                    :label="$t('modals.notes')"
                    @click="setModalTextNoteOpen(true)"
                />
                <flat-btn
                    v-if="$q.screen.gt.sm"
                    small-icons
                    size="1.2em"
                    icon="las la-tasks"
                    text-color="secondary"
                    active-color="primary"
                    :label="$t('modals.checklists')"
                    @click="setModalChecklistOpen(true)"
                >
                    <floating-badge
                        rounded
                        :label="totalUncheckedCount"
                        class="text-bold"
                        text-color="on-surface"
                        color="on-surface-3"
                        :offset="[5, 4]"
                    />
                </flat-btn>
                <flat-btn
                    v-if="canShowSearchBarIcon"
                    dense
                    size="1.2em"
                    icon="las la-search"
                    @click="onSearchIconClick"
                />
                <notification-status v-if="$q.screen.gt.sm" />
                <app-user-navigation />
            </div>
        </q-toolbar>
    </q-header>
</template>
