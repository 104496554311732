{
    "pages": {
        "inventory": "Skladové zásoby",
        "inventory-new": "Skladové zásoby - Nový projekt",
        "inventory-id": "Skladové zásoby - Přehled",
        "inventory-id-settings": "Skladové zásoby - Nastavení",
        "inventory-id-settings-section": "Skladové zásoby - Nastavení",
        "inventory-id-new-product": "Skladové zásoby - Nové produkty",
        "inventory-products-id": "Skladové zásoby - Detail produktu",
        "inventory-id-history": "Skladové zásoby - Přehled historie",
        "inventory-history-id": "Skladové zásoby - Detail historie",
        "inventory-id-history-new-income": "Skladové zásoby - Nová příjemka",
        "inventory-id-history-new-outcome": "Skladové zásoby - Nová výdejka"
    },
    "inventory": {
        "overview": {
            "actions": {
                "history": "Příjemky/Výdejky"
            },
            "products": {
                "actions": {
                    "detail": "detail",
                    "new-product": "Nový produkt"
                },
                "filter": {
                    "state": "Stav"
                }
            },
            "history": {
                "actions": {
                    "detail": "detail",
                    "history": "Všechny změny"
                },
                "columns": {
                    "info": "Poslední změny"
                },
                "items": "položka | položky | položek"
            }
        },
        "new": {
            "newProduct": "Nové produkty"
        },
        "detail": {
            "productDetail": "Detail produktu",
            "amountInfo": "Stav skladu",
            "actions": {
                "goBack": "Zpět na přehled",
                "edit": "Upravit produkt",
                "limits": "Nastavit limity"
            },
            "history": {
                "actions": {
                    "detail": "detail"
                },
                "columns": {
                    "info": "Poslední změny"
                }
            }
        },
        "history": {
            "type": {
                "Income": "Příjemka",
                "Outcome": "Výdejka"
            },
            "actions": {
                "goBackOverview": "Zpět na přehled",
                "goBackHistory": "Zpět do historie",
                "income": "Nová příjemka",
                "outcome": "Nová výdejka",
                "detail": "detail"
            },
            "table": {
                "columns": {
                    "type": "Typ",
                    "user": "Vystavil",
                    "itemsCount": "Počet položek",
                    "createdAt": "Vystaveno"
                }
            },
            "detail": {
                "title": {
                    "Income": "Informace o příjemce",
                    "Outcome": "Informace o výdejce"
                },
                "labels": {
                    "description": "Popis",
                    "items": "Položky",
                    "name": "Název",
                    "value": {
                        "Income": "Přijaté množství",
                        "Outcome": "Vydané množství"
                    },
                    "amount": "Množství na skladu"
                }
            },
            "new": {
                "title": {
                    "Income": "Nová příjemka",
                    "Outcome": "Nová výdejka"
                }
            }
        },
        "form": {
            "enums": {
                "title": {
                    "new": "Nový číselník",
                    "edit": "Upravit číselník"
                },
                "inputs": {
                    "Name": "Název"
                },
                "submit": {
                    "new": "Založit číselník",
                    "edit": "Upravit číselník"
                },
                "cancel": "Zrušit"
            },
            "enumItems": {
                "title": "Přidat položky",
                "inputs": {
                    "Values": "Nové položky",
                    "Value": "Položka"
                },
                "submit": {
                    "new": "Přidat položky",
                    "edit": "Upravit položku"
                },
                "cancel": "Zrušit"
            },
            "schema": {
                "inputs": {
                    "Name": "Název",
                    "Type": "Typ",
                    "EnumId": "Číselník",
                    "Precision": "Počet míst",
                    "Unit": "Jednotka",
                    "IsActive": "Aktivní",
                    "IsRequired": "Povinné",
                    "IsListable": "V seznamu"
                },
                "appendFields": {
                    "Unit": "ks, kg, l aj."
                },
                "submit": "Uložit nastavení",
                "cancel": "Zrušit"
            },
            "product": {
                "title": {
                    "edit": "Upravit produkt",
                    "limits": "Změna limitů"
                },
                "submit": {
                    "new": "Přidat produkty",
                    "edit": "Upravit produkt",
                    "limits": "Upravit limity"
                },
                "cancel": "Zrušit"
            },
            "history": {
                "inputs": {
                    "Note": "Poznámka",
                    "Product": "Produkt",
                    "Amount": "Dostupné množství",
                    "Value": {
                        "Income": "Přijaté množství",
                        "Outcome": "Vydané množství"
                    }
                },
                "submit": {
                    "Income": "Vytvořit příjemku",
                    "Outcome": "Vytvořit výdejku"
                },
                "cancel": "Zrušit",
                "prompts": {
                    "confirm": {
                        "title": "Překročení povolených limitů u produktu",
                        "message": {
                            "Income": "Přijaté množství u některých produktů přesahuje povolené limity. Opravdu chcete vytvořit novou příjemku?",
                            "Outcome": "Vydané množství u některých produktů přesahuje povolené limity. Opravdu chcete vytvořit novou výdejku?"
                        },
                        "confirm": "Přesto vytvořit",
                        "cancel": "Zrušit"
                    }
                }
            }
        },
        "settings": {
            "sections": {
                "enums": {
                    "title": "Nastavení číselníků",
                    "action": "Spravovat číselníky"
                },
                "schema": {
                    "title": "Nastavení dat",
                    "action": "Spravovat data tabulky"
                }
            },
            "enums": {
                "title": "Číselníky",
                "columns": {
                    "name": "Název",
                    "itemsCount": "Počet položek"
                },
                "actions": {
                    "create": "Nový číselník"
                },
                "prompts": {
                    "delete": {
                        "title": "Odstranit číselník",
                        "message": "Opravdu chcete odstranit tento číselník?",
                        "confirm": "Odstranit",
                        "cancel": "Zrušit"
                    }
                },
                "tooltips": {
                    "cannotDelete": "Nelze odstranit, protože stále existují hodnoty v číselníku, které jsou používané."
                }
            },
            "enumItems": {
                "title": "Číselník",
                "columns": {
                    "value": "Název",
                    "assigned": "Přiřazeno"
                },
                "actions": {
                    "create": "Přidat položky",
                    "search": "Vyhledat položky"
                },
                "prompts": {
                    "delete": {
                        "title": "Odstranit položku",
                        "message": "Opravdu chcete odstranit tuto položku z číselníku?",
                        "confirm": "Odstranit",
                        "cancel": "Zrušit"
                    }
                },
                "tooltips": {
                    "cannotDelete": "Nelze odstranit, protože tato položka je stále používaná."
                }
            },
            "schema": {
                "title": "Data tabulky",
                "columns": {
                    "predefined": {
                        "Name": "Název",
                        "Amount": "Množství",
                        "AmountUnit": "Jednotka",
                        "AmountMinimal": "Minimální množství",
                        "AmountOptimal": "Optimální množství",
                        "AmountMaximal": "Maximální množství",
                        "Icon": "Ikona"
                    }
                },
                "type": {
                    "items": {
                        "Numeric": "Číselné pole",
                        "Boolean": "Volba ano/ne",
                        "Date": "Datum",
                        "Datetime": "Datum a čas",
                        "Enum": "Výběr z číselníku",
                        "String": "Text"
                    }
                }
            },
            "notify": {
                "schema": {
                    "success": "Formulář byl úspěšně uložen"
                }
            }
        },
        "amountSummary": {
            "amount": "Aktuální množství",
            "amountMinimal": "Minimální množství",
            "amountOptimal": "Optimální množství",
            "amountMaximal": "Maximální množství"
        },
        "productState": {
            "Unknown": "Neznámý",
            "LessThanMinimal": "Méně než minimum",
            "LessThanOptimal": "Méně než optimum",
            "Normal": "Optimální",
            "MoreThanMaximal": "Více než maximum"
        }
    },
    "notifications": {
        "actions": {
            "IsklInventoryModelsInventoryProduct": {
                "actionTitle": "Zobrazit produkt"
            }
        }
    }
}
