export enum ModuleKey {
    HumanitarianCare = 'humanitarian-care',
    HumanResources = 'human-resources',
    Inventory = 'inventory',
    Dispatching = 'dispatching',
    Statistics = 'statistics',
    Reporting = 'reporting',
    Planning = 'planning',
}

export enum SystemFeatureKey {
    CrisisCommunication = 'crisiscommunication',
    CrisisDocuments = 'crisisdocuments',
}

export enum SettingsSectionKey {
    Subjects = 'subjects',
    Projects = 'projects',
    Users = 'users',
    Documents = 'documents',
    Enums = 'enums',
    Labels = 'labels',
    AppSettings = 'appSettings',
}

export enum ProjectSettingsSharedKey {
    Detail = 'detail',
    Users = 'users',
    Invitations = 'invitations',
}

export enum SubjectSettingsKey {
    Detail = 'detail',
    Users = 'users',
    Contacts = 'contacts',
    Invitations = 'invitations',
}

export enum FetchListType {
    My = 'my',
    Manage = 'manage',
}

export enum FetchListFilterOperator {
    Eq = 'eq',
    Neq = 'neq',
    Gt = 'gt',
    Gte = 'gte',
    Lt = 'lt',
    Lte = 'lte',
    Like = 'like',
}

export enum FileTypeGroup {
    Document = 'document',
    Sheet = 'sheet',
    Image = 'image',
    Archive = 'archive',
    Pdf = 'pdf',
    Other = 'other',
}

export enum CommunicationSendSource {
    Sms = 'Sms',
    Email = 'Email',
}

export enum CommunicationMessageType {
    Info = 'Info',
    Success = 'Success',
    Warning = 'Warning',
    Danger = 'Danger',
}

export enum NotificationModelSharedName {
    Project = 'AppModelsProject',
    Subject = 'AppModelsSubject',
}

export enum NotificationType {
    Info = 'Info',
    Success = 'Success',
    Warning = 'Warning',
    Error = 'Error',
}

export enum NotificationAction {
    Read = 'read',
    Unread = 'unread',
}

export enum ProjectSubjectBindType {
    Owner = 'Owner',
    Participant = 'Participant',
    Remote = 'Remote',
    Invited = 'Invited',
    Declined = 'Declined',
    Inactive = 'Inactive',
}

export enum UserStatus {
    Active = 'Active',
    Inactive = 'Inactive',
    Invited = 'Invited',
}

export enum UserRoleType {
    Global = 'Global',
    Subject = 'Subject',
    Project = 'Project',
}

export enum UserAuthAdapter {
    Local = 'Local',
    Ldap = 'Ldap',
}

export const isFetchListFilterOperator = (op?: string | null): op is FetchListFilterOperator => {
    if (op == null) return false;
    return Object.values(FetchListFilterOperator).find((x) => x.toString() === op) != null;
};
