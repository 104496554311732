<script lang="ts" setup>
import { TextNote } from '~/modules/core/api/textNotes/_types';
import { Checklist } from '~/modules/core/api/checklists/_types';
import { NoteTypes, isChecklist } from '~/modules/core/components/notes/_types';

import { deleteChecklist } from '~/modules/core/services/checklists';

const {
    checklists,
    totalChecklists,
    checklistFormOpen,
    activeChecklist,
    modalChecklistOpen,
    setChecklistFormOpen,
    setActiveChecklist,
    setModalChecklistOpen,
    clearChecklists,
} = useChecklists();

const { requestOptions, isLoading, request } = useLoadChecklists();

const canLoadMore = computed(() => {
    return totalChecklists.value > checklists.value.length;
});

const afterCloseModal = () => {
    clearChecklists();
    setActiveChecklist(null);
    setChecklistFormOpen(false);
};

const fncDeleteChecklist = async (item: Checklist | TextNote) => {
    if (!isChecklist(item)) {
        return;
    }

    const result = await deleteChecklist(item);

    if (result.isSuccess) {
        clearChecklists();
        setActiveChecklist(null);
        await fncLoadChecklists({ index: 1 });
    }
};

const fncLoadChecklists = async (data: { index: number; done?: (stop?: boolean) => void }) => {
    const opt = JSON.parse(JSON.stringify(requestOptions));

    opt.pagination.page = data.index;

    await request(opt);

    if (data.done) {
        data.done();
    }
};

const fncSetActiveChecklist = (item: Checklist | TextNote | null) => {
    if (item !== null && !isChecklist(item)) {
        return;
    }

    setActiveChecklist(item);
};

const submitFormSuccess = async (data: Checklist) => {
    setChecklistFormOpen(false);
    fncSetActiveChecklist(null);
    clearChecklists();

    await fncLoadChecklists({ index: 1 });

    fncSetActiveChecklist(data);
};

watch(modalChecklistOpen, async (newValue: boolean) => {
    if (newValue === false) {
        afterCloseModal();
    }
});
</script>

<template>
    <notes-modal
        :modal-open="modalChecklistOpen"
        :type="NoteTypes.checklists"
        :form-open="checklistFormOpen"
        :items="checklists"
        :active-item="activeChecklist"
        :disable-load="!canLoadMore"
        :is-loading="isLoading"
        :set-modal-open="setModalChecklistOpen"
        :set-form-open="setChecklistFormOpen"
        :load-items="fncLoadChecklists"
        :set-active-item="fncSetActiveChecklist"
        :delete-item="fncDeleteChecklist"
    >
        <template #itemOfList="Item">
            <checklists-list-item v-if="isChecklist(Item)" :item="Item" />
        </template>

        <template #itemDetail="Item">
            <checklists-detail v-if="isChecklist(Item)" :items="Item.Items" />
        </template>

        <template #itemForm>
            <checklists-form
                :active-item="activeChecklist"
                :set-active-item="fncSetActiveChecklist"
                :reset-items="clearChecklists"
                @submit-form-success="submitFormSuccess"
                @close-form="setChecklistFormOpen(false)"
            />
        </template>
    </notes-modal>
</template>
