import useTextNotesStore from '~/modules/core/stores/textNotes';

const useTextNotes = () => {
    const store = useTextNotesStore();
    const { textNotes, totalTextNotes, activeTextNote, textNoteFormOpen, modalTextNoteOpen } = storeToRefs(store);

    return {
        textNotes,
        totalTextNotes,
        activeTextNote,
        textNoteFormOpen,
        modalTextNoteOpen,
        setActiveTextNote: store.setActiveTextNote,
        setTextNoteFormOpen: store.setTextNoteFormOpen,
        setModalTextNoteOpen: store.setModalTextNoteOpen,
        clearTextNotes: store.clearTextNotes,
    };
};

export default useTextNotes;
