import usePermissionSubject from '~/modules/core/composables/permissions/usePermissionsSubject';
import { RoutePagePemissions } from '~/plugins/router/_types';

export const permissions = (): RoutePagePemissions => {
    const { canCreateAny, canManageAny } = usePermissionSubject();
    return {
        'settings-subjects': canManageAny,
        'settings-subjects-new': canCreateAny,
    };
};
