{
    "pages": {
        "reporting": "Reporting",
        "reporting-new": "Reporting - New Project",
        "reporting-id": "Reporting - Overview",
        "reporting-id-new-report": "New Report",
        "reporting-reports-id": "Reporting - detail",
        "reporting-reports-id-new-report": "New Report",
        "reporting-id-settings": "Reporting - Settings",
        "reporting-id-settings-section": "Reporting - Settings"
    },
    "reporting": {
        "model": {
            "reportingDataItem": {
                "boolean": {
                    "true": "Yes",
                    "false": "No"
                }
            }
        },
        "overview": {
            "table": {
                "columns": {
                    "name": "Report name",
                    "interventionAt": "Report date",
                    "creatorFullName": "Report author"
                },
                "actions": {
                    "detail": "detail"
                }
            },
            "noData": {
                "noReports": "No reports?",
                "howItHappened": "We cannot explain at all how this could happen!",
                "createReport": "Create report"
            }
        },
        "detail": {
            "title": "Report detail",
            "project": "Project",
            "backToOverview": "Back to overview",
            "exportPdf": "Export to PDF",
            "attachmentsTitle": "Attachments",
            "component": {
                "name": "Report name",
                "interventionAt": "Intervention at",
                "recipients": "Report recipients",
                "sender": "Recipient sender",
                "author": "Recipient author"
            }
        },
        "new": {
            "title": "New report",
            "form": {
                "inputs": {
                    "templateOfReport": "Template",
                    "recipientsOfReport": "Recipients",
                    "titleOfReport": "Title",
                    "interventionAt": "Intervention at"
                },
                "buttons": {
                    "sendReport": "Send Report",
                    "cancel": "Cancel"
                }
            }
        }
    }
}
