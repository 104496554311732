import useLabelsStore from '~/modules/core/stores/labels';
import { LabelInfo } from '~/modules/core/api/labels/_types';
import { fetchLabelsWithCount } from '~/modules/core/services/labels';

const useMyLabels = () => {
    const store = useLabelsStore();
    const { labels } = storeToRefs(store);

    const getLabelById = async (id: number): Promise<LabelInfo | null> => {
        if (labels.value.length === 0) {
            await fetchLabelsWithCount();
        }
        return labels.value.find((label) => label.Id === id) || null;
    };

    return {
        labels,
        getLabelById,
    };
};

export default useMyLabels;
