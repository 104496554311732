import {
    fetchChecklistsRequest,
    createChecklistRequest,
    updateChecklistRequest,
    deleteChecklistRequest,
} from '~/modules/core/api/checklists';
import useChecklistsStore from '~/modules/core/stores/checklists';

import { FormHandler, ApiErrors, FormHandlerWithArgs } from '~/plugins/apiClient/_types';
import { Checklist, ChecklistFormData } from '~/modules/core/api/checklists/_types';
import { FetchListOptions } from '~/modules/core/api/_types';

const createStoreChecklist = async (
    values: ChecklistFormData
): Promise<{
    isSuccess: boolean;
    isValidationError: boolean;
    errors: ApiErrors<ChecklistFormData>;
    data: Checklist | null;
}> => {
    const result = await createChecklistRequest({ values });

    if (result.isSuccess && result.data && result.data.TotalUncheckedCount) {
        const store = useChecklistsStore();
        store.updateUncheckedCount(result.data.TotalUncheckedCount);
    }

    return result;
};

const updateStoreChecklist = async (
    id: number,
    values: ChecklistFormData
): Promise<{
    isSuccess: boolean;
    isValidationError: boolean;
    errors: ApiErrors<ChecklistFormData>;
    data: Checklist | null;
}> => {
    const result = await updateChecklistRequest({ id, values });

    if (result.isSuccess && result.data && result.data.TotalUncheckedCount) {
        const store = useChecklistsStore();
        store.updateUncheckedCount(result.data.TotalUncheckedCount);
    }

    return result;
};

export const fetchChecklists = async (
    opt?: FetchListOptions
): Promise<{ isSuccess: boolean; data: Checklist[] | null }> => {
    const result = await fetchChecklistsRequest({ opt });

    if (result.isSuccess && result.data) {
        const { checklists, totalChecklists } = storeToRefs(useChecklistsStore());
        totalChecklists.value = result.meta.Total;
        checklists.value =
            opt?.pagination?.offset && opt.pagination.offset !== 0 ? checklists.value.concat(result.data) : result.data;
    }

    return result;
};

export const createChecklist: FormHandler<ChecklistFormData, { isSuccess: boolean; data: Checklist | null }> = async (
    values,
    { setErrors }
) => {
    const result = await createStoreChecklist(values);

    if (result.isValidationError) {
        setErrors(result.errors);
    }

    return result;
};

export const updateChecklist: FormHandlerWithArgs<
    number,
    ChecklistFormData,
    { isSuccess: boolean; data: Checklist | null }
> = async (id, values, { setErrors }) => {
    const result = await updateStoreChecklist(id, values);

    if (result.isValidationError) {
        setErrors(result.errors);
    }

    return result;
};

export const updateChecklistItem = async (
    id: number,
    values: ChecklistFormData
): Promise<{ isSuccess: boolean; data: Checklist | null }> => {
    const result = await updateStoreChecklist(id, values);
    return result;
};

export const deleteChecklist = async (data: Checklist): Promise<{ isSuccess: boolean }> => {
    const result = await deleteChecklistRequest({ id: data.Id });

    if (result.isSuccess && data) {
        const store = useChecklistsStore();
        store.deleteChecklistById(data.Id);

        if (result.data && result.data.TotalUncheckedCount) {
            store.updateUncheckedCount(result.data.TotalUncheckedCount);
        }
    }

    return result;
};
