<script lang="ts" setup>
import { FileChipProps } from './_types';

const props = defineProps({
  "file": null
});
const fileName = computed(() => props.file.DisplayName || props.file.FileName);
</script>

<template>
    <q-chip>
        <a
            class="ellipsis text-on-surface text-decoration-none non-selectable"
            style="max-width: 200px"
            target="_blank"
            :href="file.FileUrl"
        >
            {{ fileName }}
            <q-tooltip>
                {{ fileName }}
            </q-tooltip>
        </a>
    </q-chip>
</template>
