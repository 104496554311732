export const useFormRow = (rowTarget: string) => {
    const focusLastRowInput = () => {
        nextTick(() => {
            const elements = document.querySelectorAll(rowTarget);
            // @plachtova: correct typing (returns null if we get wrong index)
            const el = elements.item(elements.length - 1) as Element | null;
            (
                el?.querySelector(
                    "input:not([type='checkbox']:not([type='radio']:not([type='button']"
                ) as HTMLInputElement | null
            )?.focus();
        });
    };

    return {
        focusLastRowInput,
    };
};
