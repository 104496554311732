import { fetchTextNotes } from '~/modules/core/services/textNotes';
import { useApiCollectionData } from './useApiCollectionData';
import { FetchListOptions } from '~/modules/core/api/_types';

const requestOptions = {
    pagination: {
        sortBy: 'UpdatedAt',
        descending: true,
        page: 1,
        rowsPerPage: 7,
    },
};

const useLoadTextNotes = () => {
    const { data, pagination, isLoading, request } = useApiCollectionData(async (opt?: FetchListOptions) => {
        return await fetchTextNotes(opt);
    }, requestOptions.pagination);

    return { data, pagination, isLoading, requestOptions, request };
};

export default useLoadTextNotes;
