<script setup lang="ts">
import { logout } from '~/modules/core/services/auth';

const { user } = useUserAuth();
const { status, hasUnreadNotifications } = useNotifications();

const onLogout = async () => {
    await logout();
};
</script>
<template>
    <flat-btn-dropdown
        dropdown-icon="las la-ellipsis-v"
        dense
        class="q-mx-sm"
        no-icon-animation
        :menu-offset="[0, 18]"
        active-color="primary"
        text-color="secondary"
    >
        <template #label>
            <user-avatar v-if="user != null" :avatar="user.Avatar" :full-name="user.FullName" />
            <floating-badge v-if="hasUnreadNotifications" rounded color="primary" :offset="[37, 5]" />
        </template>
        <q-list>
            <q-item v-close-popup clickable :to="{ name: 'profile' }">
                <q-item-section avatar>
                    <q-icon name="las la-user-cog" />
                </q-item-section>
                <q-item-section>{{ $t('header.accountSettings') }}</q-item-section>
                <q-item-section side />
            </q-item>
            <q-item v-close-popup clickable :to="{ name: 'notifications' }">
                <q-item-section avatar>
                    <q-icon name="las la-bell" />
                    <floating-badge
                        v-if="hasUnreadNotifications"
                        rounded
                        :label="status?.Unread"
                        class="text-bold"
                        text-color="on-surface"
                        color="on-surface-3"
                        :offset="[180, 5]"
                    />
                </q-item-section>
                <q-item-section>{{ $t('header.notifications') }}</q-item-section>
                <q-item-section side />
            </q-item>
            <q-item clickable>
                <q-item-section avatar>
                    <q-icon name="las la-language"></q-icon>
                </q-item-section>
                <q-item-section>{{ $t('header.changeLanguage') }}</q-item-section>
                <q-item-section side>
                    <q-icon name="las la-angle-right" size="sm"></q-icon>
                </q-item-section>
                <q-menu auto-close anchor="top start" self="top end">
                    <app-locale-switch-options />
                </q-menu>
            </q-item>
            <q-item v-close-popup clickable @click="onLogout">
                <q-item-section avatar>
                    <q-icon name="las la-sign-out-alt"></q-icon>
                </q-item-section>
                <q-item-section>{{ $t('header.logout') }}</q-item-section>
                <q-item-section side />
            </q-item>
        </q-list>
    </flat-btn-dropdown>
</template>
