<script lang="ts" setup>
import { getCssVar, QBtnDropdownSlots } from 'quasar';
import { FlatBtnDropdownProps } from './_types';

const props = defineProps({
  "dropdownIcon": { default: 'las la-angle-down' },
  "noIconAnimation": null,
  "menuAnchor": null,
  "menuOffset": null,
  "menuSelf": null,
  "transitionDuration": null,
  "transitionHide": null,
  "transitionShow": null,
  "autoClose": null,
  "hideDropdownIcon": { type: Boolean,  },
  "rounded": null,
  "label": null,
  "textColor": { default: 'primary' },
  "dense": null,
  "align": null,
  "stretch": null,
  "disable": null,
  "padding": null,
  "size": null,
  "type": null,
  "to": null,
  "loading": null,
  "round": null,
  "onClick": null,
  "icon": null,
  "iconRight": null,
  "smallIcons": { type: Boolean,  },
  "activeColor": { default: 'secondary' },
  "noWrap": { type: Boolean,  }
});
const activeColor = computed(() => getCssVar(props.activeColor || '') || props.activeColor);
const textColor = computed(() => getCssVar(props.textColor || '') || props.textColor);
</script>
<template>
    <q-btn-dropdown
        v-bind="props"
        class="app-btn app-btn-dropdown app-btn-flat app-btn--no-q-hover text-bold"
        :class="{ 'app-btn--small-icon': smallIcons, 'hide-dropdown-icon': hideDropdownIcon }"
        flat
        :text-color="textColor"
        :style="{ '--app-btn-flat--text-color': textColor, '--app-btn-flat--active-color': activeColor }"
        no-caps
        :ripple="false"
    >
        <template v-for="(_, slotName) in ($slots as Readonly<QBtnDropdownSlots>)" #[slotName]="slotProps">
            <slot :name="slotName" v-bind="((slotProps || {}) as any)" />
        </template>
    </q-btn-dropdown>
</template>
